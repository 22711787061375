// src/components/Modal.js
import React from "react";
import "../css/Modal.css";

const Modal = ({ show, onClose, onConfirm }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h3>Are you sure you want to delete this?</h3>
        <div className="modal-actions">
          <button className="btn btn-confirm" onClick={onConfirm}>Yes</button>
          <button className="btn btn-cancel" onClick={onClose}>No</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
