import React, { useEffect, useState } from "react";
import axios from "axios";
import "../css/SubscriptionDetails.css";
import { showNotification } from "../utils/notification";

function SubscriptionDetails() {
  const [subscription, setSubscription] = useState(null);
  const [planId, setPlanId] = useState(null);
  const [loading, setLoading] = useState(true);
  const user_id = localStorage.getItem("user_id");
  const token = localStorage.getItem("token");

  useEffect(() => {
    let isMounted = true;

    const fetchActivePlanId = async () => {
      try {
        const response = await axios.post(
          `${import.meta.env.VITE_BASE_URL}/get-active-plan-id`,
          { user_id },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { plan_id } = response.data;
        if (plan_id) {
          setPlanId(plan_id);
          fetchSubscriptionDetails(plan_id);
        } else {
          throw new Error("No active plan found");
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Handle 404 error specifically (no active subscription for new user)
          setLoading(false);
        } else {
          console.error("Error fetching active plan ID", error);
          showNotification("error", error.message || "Failed to fetch active plan ID.");
          setLoading(false);
        }
      }
    };
    
      // Fetch subscription details using the active plan ID
      const fetchSubscriptionDetails = async (plan_id) => {
        try {
          const response = await axios.post(
            `${import.meta.env.VITE_BASE_URL}/api/payment/subscription/plan-details`,
            { userId: user_id, planId: plan_id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          if (response.data.success) {
            console.log("Fetched subscription data:", response.data.data); // Debug log to inspect data
            if (isMounted) {
              setSubscription(response.data.data); // Store subscription data
            }
          } else {
            throw new Error(response.data.error || "Failed to fetch subscription details");
          }
        } catch (error) {
          console.error("Error fetching subscription details", error);
          showNotification("error", error.message || "Failed to fetch subscription details.");
        } finally {
          setLoading(false);
        }
      };

    if (user_id && token) {
      fetchActivePlanId();
    } else {
      console.error("Missing user_id or token.");
      setLoading(false);
    }
    return () => {
      isMounted = false;
  };
  }, []);

  // Fetch the active plan ID


  // Function to handle advance payment
  const handleAdvancePayment = async () => {
    console.log("Subscription data before payment:", subscription);
    console.log("Plan ID:", planId); // Log the planId to ensure it is set

    const { amount, planType } = subscription; // Plan ID is stored separately in planId

    if (!planId || !amount || !planType) {
      console.error("Missing required fields: plan_id, amount, or plan_type");
      showNotification("error", "Missing required fields for advance payment.");
      return;
    }

    // Ensure planType is in the correct format: "Monthly" or "Yearly"
    const formattedPlanType = planType === "monthly" ? "Monthly" : "Yearly"; // Adjust casing

    try {
      console.log("Sending advance payment request with:", {
        user_id,
        plan_id: planId,
        amount,
        plan_type: formattedPlanType,
      });

      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/payment/advance-payment`,
        { user_id, plan_id: planId, amount, plan_type: formattedPlanType }, // Use planId here
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("response.data", response.data);

      if (response.data.orderId) {
        const { orderId, payment_id, subscription_id, old_payment_id, new_plan_validity } = response.data;

        // Open Razorpay payment window
        const options = {
          key: import.meta.env.VITE_RAZORPAY_KEY_ID,
          amount: amount * 100,
          currency: "INR",
          name: "Advance Payment",
          order_id: orderId,
          handler: async function (response) {
            try {
              // Pass old_payment_id and new_plan_validity when calling verifyAdvancePayment
              await verifyAdvancePayment(response, subscription_id, payment_id, old_payment_id, new_plan_validity);
            } catch (error) {
              showNotification("error", "Payment verification failed.");
            }
          },
          prefill: {
            name: "User Name",
            email: "user@example.com",
            contact: "9999999999",
          },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();

        rzp.on("payment.failed", function (response) {
          console.error("Advance payment failed", response);
          showNotification("error", "Payment failed. Please try again.");
        });
      } else {
        throw new Error("Failed to initiate advance payment.");
      }
    } catch (error) {
      console.error("Error during advance payment", error);
      showNotification("error", error.message || "Advance payment failed.");
    }
  };

  // Verify advance payment
  const verifyAdvancePayment = async (response, subscription_id, payment_id, old_payment_id, new_plan_validity) => {
    try {
      // Log to ensure all required fields are available
      console.log("Verifying advance payment with:", {
        subscription_id,
        razorpay_order_id: response.razorpay_order_id,
        razorpay_payment_id: response.razorpay_payment_id,
        razorpay_signature: response.razorpay_signature,
        old_payment_id,
        new_plan_validity,
        payment_id,
      });

      const verifyResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/payment/verify-advance-payment`,
        {
          subscription_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_signature: response.razorpay_signature,
          old_payment_id, // Use old_payment_id from advance payment response
          new_plan_validity, // Use new_plan_validity from advance payment response
          payment_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (verifyResponse.data.success) {
        showNotification("success", "Advance Payment successful!");
      } else {
        throw new Error(verifyResponse.data.message || "Payment verification failed.");
      }
    } catch (error) {
      console.error("Error verifying advance payment", error);
      showNotification("error", "Failed to verify advance payment.");
    }
  };

  // Implement cancel subscription
  const handleCancelSubscription = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/payment/cancel-subscription-manually`,
        { user_id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        showNotification("success", "Subscription cancelled successfully.");
      } else {
        throw new Error(response.data.error || "Failed to cancel subscription.");
      }
    } catch (error) {
      console.error("Error cancelling subscription", error);
      showNotification("error", error.message || "Failed to cancel subscription.");
    }
  };

  if (loading) {
    return <p>Loading subscription details...</p>;
  }

  if (!subscription) {
    return <p></p>;
  }

 return (
  <div className="subscription-details">
    <h2>Your Subscription</h2>
    <div className="subscription-info">
      <div>
        <span className="label">Plan Name:</span>
        <span className="value">{subscription.planName}</span>
      </div>
      {subscription.planType ? (
        <>
          <div>
            <span className="label">Plan Type:</span>
            <span className="value">{subscription.planType}</span>
          </div>
          <div>
            <span className="label">Start Date:</span>
            <span className="value">{new Date(subscription.startDate).toLocaleDateString()}</span>
          </div>
          <div>
            <span className="label">Expiry Date:</span>
            <span className="value">{new Date(subscription.expiryDate).toLocaleDateString()}</span>
          </div>
          <div>
            <span className="label">Days Left:</span>
            <span className="value">{subscription.remainingDays} days</span>
          </div>
        </>
      ) : (
        <p>This is a free plan, so no start or expiry dates.</p>
      )}
      <div>
        <span className="label">Total Tests:</span>
        <span className="value">{subscription.maxTests}</span>
      </div>
      <div>
        <span className="label">Used Tests:</span>
        <span className="value">{subscription.usedTests}</span>
      </div>
      <div>
        <span className="label">Remaining Tests:</span>
        <span className="value">{subscription.remainingTests}</span>
      </div>
      <div>
        <span className="label">Total Participants:</span>
        <span className="value">{subscription.maxParticipants}</span>
      </div>
    </div>
    <div className="subscription-buttons">
  {subscription.planName !== "Free" && (
    <>
      <button className="advance-payment-btn" onClick={handleAdvancePayment}>
        Advance Payment
      </button>
      <button className="cancel-subscription-btn" onClick={handleCancelSubscription}>
        Cancel Subscription
      </button>
    </>
  )}
</div>
  </div>
);
}

export default SubscriptionDetails;
