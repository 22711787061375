import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../css/Achievements.css';

const Achievements = () => {
    const [achievements, setAchievements] = useState([]);

    useEffect(() => {
        const fetchAchievements = async () => {
            // Mock API call
            const data = [
                { icon: 'fas fa-trophy', title: 'Top Coder', description: 'Achieved the top coder badge', date: '2024-01-15' },
                { icon: 'fas fa-medal', title: 'JavaScript Expert', description: 'Completed all JavaScript challenges', date: '2024-02-20' },
                { icon: 'fas fa-code', title: 'Python Guru', description: 'Mastered Python basics', date: '2024-03-10' },
            ];
            setAchievements(data);
        };

        fetchAchievements();
    }, []);

    return (
        <div className="achievements">
            <h2>Your Achievements</h2>
            <div className="achievements-grid">
                {achievements.map((achievement, index) => (
                    <div key={index} className="achievement-card">
                        <div className="achievement-icon">
                            <i className={achievement.icon}></i>
                        </div>
                        <div className="achievement-info">
                            <h3>{achievement.title}</h3>
                            <p>{achievement.description}</p>
                            <span className="achievement-date">
                                Achieved on: {new Date(achievement.date).toLocaleDateString()}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Achievements;
