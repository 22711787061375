import { useEffect, useState } from "react";

const FullScreenPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [isF11Pressed, setIsF11Pressed] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "F11") {
        event.preventDefault();
        setIsF11Pressed(true);
        if (document.fullscreenElement) {
          document.exitFullscreen();
        } else {
          requestFullscreen();
        }
      }
    };

    const handleFullscreenChange = () => {
      console.log(document.fullscreenElement)
      if (!document.fullscreenElement) {
        setShowPrompt(true);
        setIsF11Pressed(false);
      }
    };

    const requestFullscreen = () => {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        // Firefox
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        // IE/Edge
        document.documentElement.msRequestFullscreen();
      }
    };

    setShowPrompt(true);

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleOkClick = () => {
    if (isF11Pressed) {
      setShowPrompt(false);
    }
  };

  return (
    showPrompt && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <p>
            Press F11 for full screen mode to attempt test then click OK to
            continue.
          </p>
          <button
            onClick={handleOkClick}
            disabled={!isF11Pressed}
            className={`mt-4 px-4 py-2 bg-blue-500 text-white rounded ${
              !isF11Pressed ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            OK
          </button>
        </div>
      </div>
    )
  );
};

export default FullScreenPrompt;
