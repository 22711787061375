import React from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from '../Navbar'; // Make sure this path is correct
import '../../css/Guides.css';

const guidesData = [
  {
    id: 1,
    title: 'Mastering React Hooks',
    description: 'An in-depth guide to understanding and using React Hooks effectively in modern applications.',
    image: '/Guides/react-hook.png',
  },
  {
    id: 2,
    title: 'Advanced JavaScript Patterns',
    description: 'Explore the world of JavaScript patterns to improve your code structure and maintainability.',
    image: '/Guides/js-pattern.png',
  },
  {
    id: 3,
    title: 'CSS Grid Layouts: A Comprehensive Guide',
    description: 'Master CSS Grid layouts and build responsive, complex web designs with ease.',
    image: '/Guides/css-grid.png',
  },
  {
    id: 4,
    title: 'Node.js Performance Optimization',
    description: 'Tips and techniques for optimizing performance in Node.js applications.',
    image: '/Guides/rest.png',
  }
];

const Guides = () => {
  const navigate = useNavigate();

  const handleGuideClick = (id) => {
    navigate(`/guides/${id}`);
  };

  const [featuredGuide, ...otherGuides] = guidesData;

  return (
    <>
      <Navbar />
      <div className="guides-container">
        <div className="featured-guide" onClick={() => handleGuideClick(featuredGuide.id)}>
          <img src={featuredGuide.image} alt={featuredGuide.title} className="guide-image" />
          <div className="guide-content">
            <h3>{featuredGuide.title}</h3>
            <p>{featuredGuide.description}</p>
            <a href="#" className="view-more">View more →</a>
          </div>
        </div>
        <div className="guides-grid">
          {guidesData.map((guide) => (
            <div key={guide.id} className="guide-card" onClick={() => handleGuideClick(guide.id)}>
              <img src={guide.image} alt={guide.title} className="guide-image" />
              <div className="guide-content">
                <h3>{guide.title}</h3>
                <p>{guide.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Guides;