import React, { useState } from 'react';
import { DocNavigation } from './DocNavigation';
import { DocContentDisplay } from './DocContentDisplay';
import Navbar from '../Navbar';
import '../../css/Documentation.css';

const Documentation = () => {
  const [selectedDoc, setSelectedDoc] = useState('overall-guide');

  const docs = [
    { id: 'overall-guide', title: 'CirrusCypher - Overall Product Guide' },
    { id: 'org-dashboard', title: 'CirrusCypher - Org Dashboard' },
    // { id: 'admin-dashboard', title: 'CirrusCypher - Admin Dashboard' },
  ];

  const overallGuideContent = {
    introduction: `Welcome to CirrusCypher, the all-in-one coding test and assessment platform designed to streamline the exam process for organizations, admins, and candidates. Whether you're an organization conducting assessments, an admin overseeing platform operations, or a candidate preparing for coding challenges, CirrusCypher offers a comprehensive solution that empowers all users to achieve their objectives seamlessly.`,
    entities: [
      {
        title: "Organizers",
        description: "The exam conductors, responsible for creating and managing tests, participants, and results."
      },
      {
        title: "Admins",
        description: "The platform managers who maintain the health of the platform, overseeing organizations, and setting up public practice tests."
      },
      {
        title: "Candidates",
        description: "The individuals participating in tests, either through organizational invites or public practice tests."
      }
    ],
    keyFeatures: {
      organizers: [
        "Test Creation & Management",
        "Participant Management",
        "Score Review & Analysis"
      ],
      admins: [
        "Organization Management",
        "Public Test Creation"
      ],
      candidates: [
        "Invited Test Participation",
        "Public Test Participation"
      ]
    },
    benefits: {
      organizations: [
        "Streamlined Operations",
        "Data-Driven Insights"
      ],
      admins: [
        "Complete Control",
        "Public Engagement"
      ],
      candidates: [
        "Real-World Preparation",
        "Accessible Learning"
      ]
    },
    gettingStarted: {
      organizations: "Sign up for an account and begin creating tests immediately. Use the Organization Dashboard to add participants and monitor performance.",
      admins: "Set up platform configurations, manage organizations, and create public practice tests.",
      candidates: "Sign up directly or join through an organization invitation and start improving your coding skills with practice tests or formal assessments."
    }
  };

  return (
    <div className="cc-documentation-page">
    <Navbar/> {/* Add the Navbar component here */}
    <div className="cc-documentation-container">
      <DocNavigation docs={docs} selectedDoc={selectedDoc} setSelectedDoc={setSelectedDoc} />
      <DocContentDisplay selectedDoc={selectedDoc} content={overallGuideContent} />
    </div>
  </div>
  );
};

export default Documentation;