import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import "../../css/CounterSection.css";
import axios from "axios";

const CounterSection = ({ isDarkTheme }) => {
  const [startCount, setStartCount] = useState(false);

  const [data, setData] = useState({
    users_count: 0,
    tests_count: 0,
    paricipants_count: 0,
  });

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5, // Trigger when 50% of the component is visible
  });

  useEffect(() => {
    if (inView) {
      setStartCount(true);
    }
  }, [inView]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/get-homepage-data`
      );
      setData(response.data.data);
    } catch (error) {
      console.error("error getting data", error);
    }
  };

  return (
    <section
      ref={ref}
      className={`counter-section ${isDarkTheme ? "dark-theme" : ""}`}
    >
      {/* <h2 className="counter-title">Why choose us?</h2>
      <p className="counter-subtitle">
        Optimizing your test has never been so easy!
      </p> */}
      <div className="counter-grid">
        <div className="counter-card">
          <h3 className="counter-number">
            {startCount && <CountUp end={data.users_count} duration={2} />}+
          </h3>
          <p className="counter-description">Organizations</p>
        </div>
        <div className="counter-card">
          <h3 className="counter-number">
            {startCount && <CountUp end={data.tests_count} duration={2} />}+
          </h3>
          <p className="counter-description">Tests Created</p>
        </div>
        <div className="counter-card">
          <h3 className="counter-number">
            {startCount && (
              <CountUp end={data.paricipants_count} duration={2} />
            )}
            +
          </h3>
          <p className="counter-description">Candidates</p>
        </div>
      </div>
    </section>
  );
};

export default CounterSection;
