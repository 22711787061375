import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import screenfull from 'screenfull';

const MCQSecurity = ({ children, onViolation }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showFullScreenPrompt, setShowFullScreenPrompt] = useState(false);

  useEffect(() => {
    const enterFullScreen = () => {
      if (screenfull.isEnabled) {
        screenfull.request().then(() => {
          setIsFullScreen(true);
          setShowFullScreenPrompt(false);
        }).catch(() => {
          setShowFullScreenPrompt(true);
        });
      }
    };

    const exitHandler = () => {
      if (!screenfull.isFullscreen) {
        handleViolation('Exited fullscreen');
        enterFullScreen(); // Immediately try to re-enter fullscreen
      }
    };

    const handleKeyDown = (e) => {
      // Prevent all keyboard events
      if (e.key !== undefined) {
        e.preventDefault();
        e.stopPropagation();
        if (e.key === 'Escape') {
          e.preventDefault(); // Try to prevent Escape from exiting fullscreen
          enterFullScreen(); // Immediately try to re-enter fullscreen
        }
        handleViolation('Keyboard use detected');
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        handleViolation('Tab change detected');
      }
    };

    const handleContextMenu = (e) => {
      e.preventDefault();
      handleViolation('Right-click detected');
    };

    enterFullScreen();
    document.addEventListener('fullscreenchange', exitHandler);
    document.addEventListener('keydown', handleKeyDown, true);
    document.addEventListener('visibilitychange', handleVisibilityChange);
    document.addEventListener('contextmenu', handleContextMenu);

    // Try to hide fullscreen message
    const style = document.createElement('style');
    style.textContent = `
      *:fullscreen::backdrop {
        content: ""; 
        position: fixed;
        top: 0; left: 0; right: 0; bottom: 0;
        background: white;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.removeEventListener('fullscreenchange', exitHandler);
      document.removeEventListener('keydown', handleKeyDown, true);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      document.removeEventListener('contextmenu', handleContextMenu);
      document.head.removeChild(style);
    };
  }, []);

  const handleViolation = (message) => {
    toast.error(`Warning: ${message}. Further violations may result in test submission.`);
    onViolation(message);
  };

  const promptFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.request().then(() => {
        setIsFullScreen(true);
        setShowFullScreenPrompt(false);
      }).catch(() => {
        toast.error("Failed to enter full screen. Please try manually entering full screen mode.");
      });
    }
  };

  return (
    <>
      {children}
      {showFullScreenPrompt && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-md">
            <h2 className="text-xl font-bold mb-4">Full Screen Required</h2>
            <p className="mb-4">This test requires full screen mode. Please click the button below to enter full screen.</p>
            <button
              onClick={promptFullScreen}
              className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Enter Full Screen
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default MCQSecurity;