import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Avatar, 
  Grid, 
  Switch, 
  FormControlLabel,
  Divider,
  Alert
} from '@mui/material';
import { Save as SaveIcon, CameraAlt as CameraIcon } from '@mui/icons-material';

import "../../css/ProfileSettings.css";

const ProfileSettings = ({ setSelected, setShowProfileSettings }) => {
    
    const [profileData, setProfileData] = useState({
      username: "",
      email: "",
      fullName: "",
      bio: "",
      company: "",
      location: "",
      website: "",
      receiveNotifications: true,
      profileVisibility: "public"
    });
  
    const [avatar, setAvatar] = useState("/Vyapar.png");
    const [saveStatus, setSaveStatus] = useState(null);
  
    useEffect(() => {
      // Simulating fetching data from backend
      // Replace this with actual API call
      setTimeout(() => {
        setProfileData(prevData => ({
          ...prevData,
          username: "tanmay",
          email: "tanmay@example.com"
        }));
      }, 1000);
    }, []);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setProfileData(prevData => ({
        ...prevData,
        [name]: value
      }));
    };
  
    const handleSwitchChange = (e) => {
      const { name, checked } = e.target;
      setProfileData(prevData => ({
        ...prevData,
        [name]: checked
      }));
    };
  
    const handleAvatarChange = (e) => {
      if (e.target.files && e.target.files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          setAvatar(e.target.result);
        };
        reader.readAsDataURL(e.target.files[0]);
      }
    };
  
    const handleSubmit = (e) => {
        e.preventDefault();
        // Simulating an API call
        setTimeout(() => {
          setSaveStatus('success');
          setTimeout(() => {
            setSaveStatus(null);
            setSelected('overview');
            setShowProfileSettings(false);
          }, 1000);
        }, 1000);
      };
  
  
  return (
    <Box className="profile-settings-container">
      <Paper elevation={3} className="profile-settings-paper">
        <Typography variant="h4" gutterBottom sx={{ p: 2 }}>
          Profile Settings
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} sx={{ p: 2 }}>
            <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Avatar
                src={avatar}
                sx={{ width: 120, height: 120, mb: 2 }}
              />
              <input
                accept="image/*"
                id="avatar-upload"
                type="file"
                onChange={handleAvatarChange}
                style={{ display: 'none' }}
              />
              <label htmlFor="avatar-upload">
                <Button
                  variant="outlined"
                  component="span"
                  startIcon={<CameraIcon />}
                >
                  Change Avatar
                </Button>
              </label>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Username"
                    name="username"
                    value={profileData.username}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={profileData.email}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Full Name"
                    name="fullName"
                    value={profileData.fullName}
                    onChange={handleInputChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Bio"
                    name="bio"
                    multiline
                    rows={3}
                    value={profileData.bio}
                    onChange={handleInputChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Additional Information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Company"
                name="company"
                value={profileData.company}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Location"
                name="location"
                value={profileData.location}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Website"
                name="website"
                value={profileData.website}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6" gutterBottom>
                Preferences
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={profileData.receiveNotifications}
                    onChange={handleSwitchChange}
                    name="receiveNotifications"
                  />
                }
                label="Receive email notifications"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={profileData.profileVisibility === "public"}
                    onChange={(e) => setProfileData(prevData => ({
                      ...prevData,
                      profileVisibility: e.target.checked ? "public" : "private"
                    }))}
                    name="profileVisibility"
                  />
                }
                label="Make profile public"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                size="large"
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </form>
        {saveStatus === 'success' && (
          <Alert severity="success" sx={{ mt: 2 }}>
            Profile settings saved successfully!
          </Alert>
        )}
      </Paper>
    </Box>
  );
};

export default ProfileSettings;
