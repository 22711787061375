import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Grid, Typography, Box } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import blogs from './AllBlogs'; // Assuming this is where the blogs are imported
import Navbar from '../Navbar';
import "../../css/BlogsRendering.css";

const BlogsRendering = () => {
  const { title } = useParams();
  const decodedTitle = decodeURIComponent(title).replace(/-/g, ' ');
  const blog = blogs.find(b => b.title === decodedTitle);
  const otherBlogs = blogs.filter(b => b.title !== decodedTitle).slice(0, 5);

  // Log the related blogs to check if they're loading properly
  useEffect(() => {
    console.log("Related blogs:", otherBlogs);
  }, [otherBlogs]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  if (!blog) {
    return (
      <>
        <Navbar />
        <Container>
          <p className="blog-not-found">Blog not found.</p>
        </Container>
      </>
    );
  }

  return (
    <>
      <Navbar />
      <div className="blog-page-layout">
        <Container maxWidth="md" className="blog-rendering-container">
          <Typography variant="h3" component="h1" gutterBottom>
            {blog.title}
          </Typography>
          
          {/* Display first image with fixed size right after the title */}
          {blog.images.length > 0 && (
            <Box mt={2} mb={4} className="blog-featured-image">
              <img src={blog.images[0]} alt="Featured image" />
            </Box>
          )}

          <Box display="flex" alignItems="center" mb={2}>
            <Typography variant="body2" color="textSecondary">
              {blog.date} • {Math.ceil(blog.content.length / 1700)} min read
            </Typography>
          </Box>
          
          <Box display="flex" mb={4} className="blog-share-icons">
            <Typography variant="body2" mr={1}>SHARE</Typography>
            <LinkedInIcon fontSize="small" />
            <TwitterIcon fontSize="small" />
            <FacebookIcon fontSize="small" />
          </Box>

          {/* Render blog content */}
          <div className="blog-content">
            <div dangerouslySetInnerHTML={{ __html: blog.content }} />
            {/* REMOVE OR COMMENT OUT THE CODE BELOW TO PREVENT RENDERING REMAINING IMAGES */}
            {/* 
            <div className="blog-images">
              {blog.images.slice(1).map((src, index) => (
                <img key={index} src={src} alt={`Blog image ${index + 1}`} />
              ))}
            </div>
            */}
          </div>
        </Container>

        {/* Sidebar with Related Blogs */}
        {otherBlogs.length > 0 && (
          <Box className="blog-sidebar" mt={4}>
            <Typography variant="h6" gutterBottom>
              Related Blogs
            </Typography>
            {otherBlogs.map((otherBlog, index) => (
              <Box key={index} mb={2}>
                <Link to={`/blog/${encodeURIComponent(otherBlog.title.replace(/ /g, '-'))}`}>
                  <Typography variant="subtitle1" color="primary">
                    {otherBlog.title}
                  </Typography>
                </Link>
              </Box>
            ))}
          </Box>
        )}
      </div>
    </>
  );
};

export default BlogsRendering;
