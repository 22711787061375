import React, { useState } from "react";
import Navbar from "../components/Navbar";
import HomeHeroSection from "../components/Home/HomeHeroSection";
import FeatureSection from "../components/Home/FeatureSection";
import HowItWorksSection from "../components/Home/HowItWorksSection";
import FooterHome from "../components/FooterHome";
import HomeLandingPage from "../components/Home/HomeLandingPage";
import Languages from "../components/Home/Languages";
import CounterSection from "../components/Home/CounterSection";

import SubFooter from "../components/Home/SubFooter";
import Testimonials from "../components/Home/Testimonials";

const HomePage = () => {
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  return (
    <div>
      <Navbar toggleTheme={toggleTheme} />
      <HomeHeroSection isDarkTheme={isDarkTheme} />
      <FeatureSection isDarkTheme={isDarkTheme} />
      <div id="how-it-works">
                <HowItWorksSection />
            </div>
      <HomeLandingPage />
     <Languages/>
     <CounterSection/>
      <Testimonials/>
      <SubFooter/>
      {/* Add other sections/components here */}
    </div>
  );
};

export default HomePage;
