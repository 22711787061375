// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import "./EditTest.css";
// import { useParams } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";

// const EditTest = () => {
//   const navigate = useNavigate();
//   const { testId } = useParams();
//   const [testDetails, setTestDetails] = useState({
//     test_title: "",
//     test_description: "",
//     duration: "",
//     test_date: new Date(),
//     start_time: new Date(),
//     end_time: new Date(),
//   });
//   const [questions, setQuestions] = useState([]);
//   const [allQuestions, setAllQuestions] = useState([]);
//   const [languages, setLanguages] = useState([]);

//   useEffect(() => {
//     fetchTestDetails();
//     fetchAllQuestions();
//     fetchTestQuestions();
//     fetchLanguages();
//   }, [testId]);

//   const fetchLanguages = async () => {
//     try {
//       const user_id = localStorage.getItem("user_id");
//       const response = await axios.post(`${BASE_URL}/get-plan-wise-languages`, {
//         user_id: user_id,
//       });
//       setLanguages(response.data.queryResult);
//     } catch (error) {
//       console.error("Error fetching languages:", error);
//     }
//   };

//   const fetchTestDetails = async () => {
//     try {
//       const response = await axios.get(`${BASE_URL}/tests/${testId}`, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });
//       const testData = response.data;

//       // Convert the times to Date objects, preserving the time part
//       const [startHours, startMinutes] = testData.start_time
//         .split(":")
//         .map(Number);
//       const [endHours, endMinutes] = testData.end_time.split(":").map(Number);

//       setTestDetails({
//         test_title: testData.test_title,
//         test_description: testData.test_description,
//         duration: testData.duration,
//         test_date: new Date(testData.test_date),
//         start_time: new Date(1970, 0, 1, startHours, startMinutes),
//         end_time: new Date(1970, 0, 1, endHours, endMinutes),
//       });
//     } catch (error) {
//       console.error("Error fetching test details:", error);
//     }
//   };

//   const fetchAllQuestions = async () => {
//     try {
//       const response = await axios.get(`${BASE_URL}/api/questions`, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });
//       setAllQuestions(response.data);
//     } catch (error) {
//       console.error("Error fetching all questions:", error);
//     }
//   };

//   const fetchTestQuestions = async () => {
//     try {
//       const response = await axios.get(
//         `${BASE_URL}/tests/${testId}/questions`,
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       setQuestions(response.data);
//     } catch (error) {
//       console.error("Error fetching test questions:", error);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setTestDetails((prevDetails) => ({
//       ...prevDetails,
//       [name]: value,
//     }));
//   };

//   const handleDateChange = (date, field) => {
//     setTestDetails((prevDetails) => ({
//       ...prevDetails,
//       [field]: date,
//     }));
//   };

//   const handleDeleteQuestion = async (questionId) => {
//     try {
//       await axios.delete(
//         `${BASE_URL}/tests/${testId}/questions/${questionId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       setQuestions(
//         questions.filter((question) => question.question_id !== questionId)
//       );
//       alert("Question deleted successfully");
//     } catch (error) {
//       console.error("Error deleting question:", error);
//     }
//   };

//   const handleAddQuestion = async (questionId) => {
//     try {
//       await axios.post(
//         `${BASE_URL}/tests/${testId}/questions/${questionId}`,
//         null,
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       const addedQuestion = allQuestions.find(
//         (question) => question.question_id === questionId
//       );
//       setQuestions((prevQuestions) => [...prevQuestions, addedQuestion]);
//       setAllQuestions((prevAllQuestions) =>
//         prevAllQuestions.filter(
//           (question) => question.question_id !== questionId
//         )
//       );
//       alert("Question added successfully");
//     } catch (error) {
//       console.error("Error adding question to test:", error);
//     }
//   };

//   const handleSaveChanges = async (e) => {
//     e.preventDefault();
//     try {
//       const formattedTestDate = new Date(
//         testDetails.test_date.getTime() -
//           testDetails.test_date.getTimezoneOffset() * 60000
//       )
//         .toISOString()
//         .split("T")[0];
//       const formattedStartTime = testDetails.start_time
//         .toTimeString()
//         .split(" ")[0];
//       const formattedEndTime = testDetails.end_time
//         .toTimeString()
//         .split(" ")[0];

//       console.log(
//         "formattedTestDate",
//         formattedTestDate,
//         "formattedStartTime",
//         formattedStartTime,
//         "formattedEndTime",
//         formattedEndTime
//       );

//       await axios.put(
//         `${BASE_URL}/tests/${testId}`,
//         {
//           ...testDetails,
//           test_date: formattedTestDate,
//           start_time: formattedStartTime,
//           end_time: formattedEndTime,
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         }
//       );
//       navigate(`/user/dashboard`);
//     } catch (error) {
//       console.error("Error updating test details:", error);
//     }
//   };

//   return (
//     <>
//       <button
//         className="unique-button"
//         onClick={() => navigate(`/user/dashboard`)}
//       >
//         Close
//       </button>

//       <div className="edit-test-container">
//         <h1>Edit Test</h1>
//         <form onSubmit={handleSaveChanges}>
//           <div>
//             <label htmlFor="test_title">Title:</label>
//             <input
//               type="text"
//               id="test_title"
//               name="test_title"
//               value={testDetails.test_title}
//               onChange={handleChange}
//             />
//           </div>
//           <div>
//             <label htmlFor="test_description">Description:</label>
//             <textarea
//               id="test_description"
//               name="test_description"
//               value={testDetails.test_description}
//               onChange={handleChange}
//             />
//           </div>
//           <div>
//             <label htmlFor="duration">Test Duration (in minutes)</label>
//             <input
//               type="number"
//               id="duration"
//               name="duration"
//               value={testDetails.duration}
//               onChange={handleChange}
//             />
//           </div>
//           <div>
//             <label htmlFor="test_date">Test Date:</label>
//             <DatePicker
//               selected={testDetails.test_date}
//               onChange={(date) => handleDateChange(date, "test_date")}
//               dateFormat="yyyy-MM-dd"
//             />
//           </div>
//           <div>
//             <label htmlFor="start_time">Start Time:</label>
//             <DatePicker
//               selected={testDetails.start_time}
//               onChange={(date) => handleDateChange(date, "start_time")}
//               showTimeSelect
//               showTimeSelectOnly
//               timeIntervals={15}
//               timeCaption="Start Time"
//               dateFormat="h:mm aa"
//             />
//           </div>
//           <div>
//             <label htmlFor="end_time">End Time:</label>
//             <DatePicker
//               selected={testDetails.end_time}
//               onChange={(date) => handleDateChange(date, "end_time")}
//               showTimeSelect
//               showTimeSelectOnly
//               timeIntervals={15}
//               timeCaption="End Time"
//               dateFormat="h:mm aa"
//             />
//           </div>
//           <button type="submit">Save Changes</button>
//         </form>

//         <div className="questions-container">
//           <div className="current-questions">
//             <h1>Current Questions</h1>
//             <table>
//               <thead>
//                 <tr>
//                   <th>Question</th>
//                   <th>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {questions.map((question) => (
//                   <tr key={question.question_id}>
//                     <td
//                       dangerouslySetInnerHTML={{
//                         __html: question.question_content,
//                       }}
//                     ></td>
//                     <td>
//                       <button
//                         className="delete-button"
//                         onClick={() =>
//                           handleDeleteQuestion(question.question_id)
//                         }
//                       >
//                         Delete
//                       </button>
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>

//           <div className="available-questions">
//             <h1>Available Questions</h1>
//             <table>
//               <thead>
//                 <tr>
//                   <th>Question</th>
//                   <th>Select Language</th>
//                   <th>Action</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {allQuestions.map((question) => (
//                   <tr key={question.question_id}>
//                     <td>{question.question_text}</td>
//                     <td>
//                       {questions.some(
//                         (q) => q.question_id === question.question_id
//                       ) ? (
//                         <span>Added</span>
//                       ) : (
//                         <button
//                           className="add-button"
//                           onClick={() =>
//                             handleAddQuestion(question.question_id)
//                           }
//                         >
//                           Add
//                         </button>
//                       )}
//                     </td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default EditTest;
// //======================================================
// //changes for dropdown select language
import React, { useState, useEffect, useRef  } from "react";
import axios from "axios";
import "../../css/EditTest.css";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { showNotification } from "../../utils/notification";

const EditTest = ({ testId, onClose, onUpdate }) => {
  const navigate = useNavigate();
  
  const formRef = useRef(null); 
  const [testDetails, setTestDetails] = useState({
    test_title: "",
    test_description: "",
    duration: "",
    test_date: new Date(),
    test_end_date: new Date(), // Add this line
    start_time: new Date(),
    end_time: new Date(),
  });
  const [questions, setQuestions] = useState([]);
  const [allQuestions, setAllQuestions] = useState([]);
  const [languages, setLanguages] = useState([]);
  


  useEffect(() => {
    fetchTestDetails();
    fetchAllQuestions();
    fetchTestQuestions();
    fetchLanguages();
  }, [testId]);
  
  
  const fetchLanguages = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-plan-wise-languages`,
        {
          user_id: user_id,
        }
      );
      setLanguages(response.data.queryResult);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  const fetchTestDetails = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env. VITE_BASE_URL}/tests/${testId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const testData = response.data;
  
      const [startHours, startMinutes] = testData.start_time
        .split(":")
        .map(Number);
      const [endHours, endMinutes] = testData.end_time.split(":").map(Number);
  
      setTestDetails({
        test_title: testData.test_title,
        test_description: testData.test_description,
        duration: testData.duration,
        test_date: new Date(testData.test_date),
        test_end_date: new Date(testData.test_end_date), // Add this line
        start_time: new Date(1970, 0, 1, startHours, startMinutes),
        end_time: new Date(1970, 0, 1, endHours, endMinutes),
      });
    } catch (error) {
      console.error("Error fetching test details:", error);
    }
  };

  const fetchAllQuestions = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/questions`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const questionsWithLanguage = response.data.map((question) => ({
        ...question,
        selectedLanguage: "", // Add a new field for the selected language
      }));
      setAllQuestions(questionsWithLanguage);
    } catch (error) {
      console.error("Error fetching all questions:", error);
    }
  };

  const fetchTestQuestions = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/tests/${testId}/questions`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching test questions:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTestDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleDateChange = (date, field) => {
    setTestDetails((prevDetails) => ({
      ...prevDetails,
      [field]: date,
    }));
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      await axios.delete(
        `${import.meta.env.VITE_BASE_URL}/tests/${testId}/questions/${questionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setQuestions(
        questions.filter((question) => question.question_id !== questionId)
      );
      showNotification("success", "Question deleted successfully"); 
    } catch (error) {
      console.error("Error deleting question:", error);
      showNotification("error", "Failed to delete question. Please try again."); // Add error notification
    }
  };
  

  const handleAddQuestion = async (questionId) => {
    const selectedQuestion = allQuestions.find(
      (question) => question.question_id === questionId
    );
    if (!selectedQuestion.selectedLanguage) {
      
      showNotification("error", "Please select a language before adding the question.");
      return;
    }
    try {
      const selectedLanguage = selectedQuestion.selectedLanguage;

      await axios.post(
        `${import.meta.env.VITE_BASE_URL}/tests/${testId}/questions/${questionId}`,
        { language: selectedLanguage }, // Send language name instead of language_id
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const addedQuestion = allQuestions.find(
        (question) => question.question_id === questionId
      );
      setQuestions((prevQuestions) => [...prevQuestions, addedQuestion]);
      setAllQuestions((prevAllQuestions) =>
        prevAllQuestions.filter(
          (question) => question.question_id !== questionId
        )
      );
      showNotification("success", "Question added successfully");
    } catch (error) {
      console.error("Error adding question to test:", error);
      showNotification("error", "Failed to add question to test. Please try again.");
    }
  };

  const handleLanguageChange = (questionId, languageName) => {
    setAllQuestions((prevAllQuestions) =>
      prevAllQuestions.map((question) =>
        question.question_id === questionId
          ? { ...question, selectedLanguage: languageName }
          : question
      )
    );
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    try {
      const formattedTestDate = new Date(
        testDetails.test_date.getTime() -
          testDetails.test_date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
        
      const formattedTestEndDate = new Date(
        testDetails.test_end_date.getTime() -
          testDetails.test_end_date.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
  
      const formattedStartTime = testDetails.start_time
        .toTimeString()
        .split(" ")[0];
      const formattedEndTime = testDetails.end_time
        .toTimeString()
        .split(" ")[0];
  
      const updateResponse = await axios.put(
        `${import.meta.env.VITE_BASE_URL}/tests/${testId}`,
        {
          ...testDetails,
          test_date: formattedTestDate,
          test_end_date: formattedTestEndDate, // Add this line
          start_time: formattedStartTime,
          end_time: formattedEndTime,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
  
      if (updateResponse.data.message !== 'Test details updated successfully') {
        throw new Error('Test update failed.');
      }
  
      // Fetch the updated test data
      const fetchTestResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/tests/${testId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
  
      const updatedTest = fetchTestResponse.data;
  
      console.log("Updated Test:", updatedTest);
  
      // Pass the updated test data to the parent component
      if (onUpdate) {
        onUpdate(updatedTest);
      } else {
        console.warn("onUpdate is not defined.");
      }
  
      // Close the EditTest component
      if (onClose) {
        onClose();
      } else {
        console.warn("onClose is not defined.");
      }
  
    } catch (error) {
      console.error("Error updating or fetching test:", error);
      showNotification("error", "Failed to save changes. Please try again.");
    }
  };
  
  
  
  
 
  return (
    <div className="edit-test-container">
      {/* Left Panel - Test Details */}
      <div className="edit-test-left">
        <h1>Edit Test</h1>
        <form onSubmit={handleSaveChanges}>
          <div className="test-info">
            <div className="test-info-field">
              <label className="test-info-label">Title:</label>
              <input
                type="text"
                className="test-info-input"
                name="test_title"
                value={testDetails.test_title}
                onChange={handleChange}
              />
            </div>
            <div className="test-info-field">
              <label className="test-info-label">Description:</label>
              <textarea
                className="test-info-input"
                name="test_description"
                value={testDetails.test_description}
                onChange={handleChange}
                rows={4}
              />
            </div>
          </div>

          <div className="test-timing">
            <div className="test-info-field">
              <label className="test-info-label">Test Date:</label>
              <DatePicker
                selected={testDetails.test_date}
                onChange={(date) => handleDateChange(date, "test_date")}
                dateFormat="yyyy-MM-dd"
                className="test-info-input"
              />
            </div>
            <div className="test-info-field">
              <label className="test-info-label">Test End Date:</label>
              <DatePicker
                selected={testDetails.test_end_date}
                onChange={(date) => handleDateChange(date, "test_end_date")}
                dateFormat="yyyy-MM-dd"
                minDate={testDetails.test_date}
                className="test-info-input"
              />
            </div>
            <div className="test-info-field">
              <label className="test-info-label">Start Time:</label>
              <DatePicker
                selected={testDetails.start_time}
                onChange={(date) => handleDateChange(date, "start_time")}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Start Time"
                dateFormat="h:mm aa"
                className="test-info-input"
              />
            </div>
            <div className="test-info-field">
              <label className="test-info-label">End Time:</label>
              <DatePicker
                selected={testDetails.end_time}
                onChange={(date) => handleDateChange(date, "end_time")}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="End Time"
                dateFormat="h:mm aa"
                className="test-info-input"
              />
            </div>
          </div>

          <div className="save-button-container">
            <button type="submit" className="save-test-btn">
              Save Changes
            </button>
          </div>
        </form>
      </div>

      {/* Right Panel - Questions Management */}
      <div className="edit-test-right">
        {/* Current Questions Section */}
        <div className="questions-section">
          <h2>Current Questions</h2>
          <div className="questions-table-container">
            <table className="questions-table">
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {questions.map((question) => (
                  <tr key={question.question_id}>
                    <td className="question-content-cell">
                      <div dangerouslySetInnerHTML={{ __html: question.question_content }}></div>
                    </td>
                    <td className="action-cell">
                      <button
                        className="question-action-btn delete-question-btn"
                        onClick={() => handleDeleteQuestion(question.question_id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Available Questions Section */}
        <div className="questions-section">
          <h2>Available Questions</h2>
          <div className="questions-table-container">
            <table className="questions-table">
              <thead>
                <tr>
                  <th>Question</th>
                  <th>Select Language</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {allQuestions.map((question) => {
                  const isAdded = questions.some(
                    (q) => q.question_id === question.question_id
                  );
                  return (
                    <tr key={question.question_id}>
                      <td className="question-content-cell">
                        {question.question_text}
                      </td>
                      <td className="language-select-cell">
                        {isAdded ? (
                          <span className="status-text">Question added</span>
                        ) : (
                          <select
                            className="question-language-select"
                            value={question.selectedLanguage}
                            onChange={(e) =>
                              handleLanguageChange(
                                question.question_id,
                                e.target.value
                              )
                            }
                            disabled={isAdded}
                          >
                            <option value="">Select Language</option>
                            {languages.map((language) => (
                              <option
                                key={language.language_id}
                                value={language.language}
                              >
                                {language.language}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                      <td className="action-cell">
                        {isAdded ? (
                          <span className="status-text">Added</span>
                        ) : (
                          <button
                            className="question-action-btn add-question-btn"
                            onClick={() => handleAddQuestion(question.question_id)}
                            disabled={!question.selectedLanguage}
                          >
                            Add
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTest;
