import React from "react";
import { Link } from "react-router-dom";
import "../css/RightContent.css";

const RightContent = () => {
  return (
    <div className="right-content-container">
      <h2 className="right-container-trial-title">Start with Free Plan</h2>
      <p className="right-container-trial-info">
        Explore all features and capabilities. No credit card required.
      </p>
      <div className="right-container-features">
        <div className="right-container-feature">
          <img src="/assets/globe-icon.png" alt="Languages" className="right-container-feature-icon" />
          <p className="right-container-feature-text">Over 30 Languages Supported</p>
        </div>
        <div className="right-container-feature">
          <img src="/assets/task-icon.png"  alt="Tasks" className="right-container-feature-icon" />
          <p className="right-container-feature-text">Unlimited Tests</p>
        </div>
        <div className="right-container-feature">
          <img src="/assets/hourglass-icon.png"  alt="Time" className="right-container-feature-icon" />
          <p className="right-container-feature-text">Time Limited Tests</p>
        </div>
        <div className="right-container-feature">
          <img src="/assets/paintbrush-icon.png"  alt="Themes" className="right-container-feature-icon" />
          <p className="right-container-feature-text">Customizable Themes</p>
        </div>
      </div>
      <h2 className="right-container-pack-title">Check Out the plans:</h2>
      <div className="right-container-packs">
        <div className="right-container-pack">
          <h3 className="right-container-pack-name">Free</h3>
          {/* <p className="right-container-pack-price">Try with free</p> */}
        </div>
        <div className="right-container-pack">
          <h3 className="right-container-pack-name">Basic</h3>
          {/* <p className="right-container-pack-price">Rs.99/mo</p> */}
        </div>
        <div className="right-container-pack">
          <h3 className="right-container-pack-name">Premium</h3>
          {/* <p className="right-container-pack-price">Rs.599/mo</p> */}
        </div>
        <div className="right-container-pack">
          <h3 className="right-container-pack-name">Enterprise</h3>
          {/* <p className="right-container-pack-price">Contact Us</p> */}
        </div>
        
      </div>
      <Link to="/subscription" className="right-landing-button">Learn more</Link>
    </div>
  );
};

export default RightContent;
