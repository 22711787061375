// src/components/Preparation/ProgressModal.js

import React from 'react';
import { CheckCircle2, AlertCircle } from 'lucide-react';
import PropTypes from 'prop-types';

export default function ProgressModal({
  isOpen,
  onClose,
  progress,
}) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 p-6 relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl font-semibold"
          aria-label="Close Modal"
        >
          &times;
        </button>

        {/* Modal Title */}
        <h2 className="text-2xl font-semibold mb-4">Your Progress</h2>

        {/* Progress Details */}
        <div className="space-y-6">
          {/* Overall Progress */}
          <div>
            <h3 className="font-semibold mb-2">Overall Progress</h3>
            <div className="flex justify-between items-center">
              <span className="text-lg font-medium">
                {progress.solved} / {progress.total} Questions Solved
              </span>
              <div className="w-48 h-4 bg-gray-200 rounded-full">
                <div
                  className="h-4 bg-blue-500 rounded-full"
                  style={{
                    width: `${progress.total > 0 ? (progress.solved / progress.total) * 100 : 0}%`,
                  }}
                ></div>
              </div>
            </div>
          </div>

          {/* Difficulty Breakdown */}
          <div>
            <h3 className="font-semibold mb-2">Difficulty Breakdown</h3>
            <ul className="space-y-3">
              {/* Easy */}
              <li>
                <div className="flex justify-between items-center">
                  <span className="text-green-600 font-medium">Easy: {progress.easySolved} / {progress.easy}</span>
                  <div className="w-40 h-3 bg-green-200 rounded-full">
                    <div
                      className="h-3 bg-green-500 rounded-full"
                      style={{
                        width: `${progress.easy > 0 ? (progress.easySolved / progress.easy) * 100 : 0}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </li>
              {/* Medium */}
              <li>
                <div className="flex justify-between items-center">
                  <span className="text-yellow-600 font-medium">Medium: {progress.mediumSolved} / {progress.medium}</span>
                  <div className="w-40 h-3 bg-yellow-200 rounded-full">
                    <div
                      className="h-3 bg-yellow-500 rounded-full"
                      style={{
                        width: `${progress.medium > 0 ? (progress.mediumSolved / progress.medium) * 100 : 0}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </li>
              {/* Hard */}
              <li>
                <div className="flex justify-between items-center">
                  <span className="text-red-600 font-medium">Hard: {progress.hardSolved} / {progress.hard}</span>
                  <div className="w-40 h-3 bg-red-200 rounded-full">
                    <div
                      className="h-3 bg-red-500 rounded-full"
                      style={{
                        width: `${progress.hard > 0 ? (progress.hardSolved / progress.hard) * 100 : 0}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          {/* Additional Metrics (Optional) */}
          {/* You can add more metrics here as needed */}
        </div>
      </div>
    </div>
  );
}

// PropTypes for type checking
ProgressModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  progress: PropTypes.shape({
    total: PropTypes.number.isRequired,
    solved: PropTypes.number.isRequired,
    easy: PropTypes.number.isRequired,
    medium: PropTypes.number.isRequired,
    hard: PropTypes.number.isRequired,
    easySolved: PropTypes.number.isRequired,
    mediumSolved: PropTypes.number.isRequired,
    hardSolved: PropTypes.number.isRequired,
  }).isRequired,
};
