// ScorecardModal.js
import React from 'react';
import { 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  IconButton 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ScorecardModal = ({ isOpen, onClose, children }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        Test Scorecard
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default ScorecardModal;