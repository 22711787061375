import React, { useState, useRef, useEffect } from 'react';
import { motion, useAnimation, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Code, Users, Shield, Terminal, Award, BarChart2, Zap, GitBranch, ChevronRight, Play, Pause, RotateCcw, Check, X, ChevronDown, ChevronUp } from 'lucide-react';

import Navbar from './Navbar';

const FeatureIcon = ({ icon: Icon }) => (
  <div className="absolute -left-6 top-0 bg-indigo-600 rounded-full p-2 sm:p-3 shadow-lg">
    <Icon className="w-4 h-4 sm:w-6 sm:h-6 text-white" />
  </div>
);

const Feature = ({ icon, title, description }) => (
  <motion.div 
    className="relative pl-8 sm:pl-10 mb-6 sm:mb-8"
    initial={{ opacity: 0, x: -50 }}
    animate={{ opacity: 1, x: 0 }}
    transition={{ duration: 0.5 }}
  >
    <FeatureIcon icon={icon} />
    <h3 className="text-lg sm:text-xl font-semibold mb-1 sm:mb-2 text-gray-800 dark:text-gray-200">{title}</h3>
    <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400">{description}</p>
  </motion.div>
);

const CodeSnippet = ({ language, code }) => (
  <pre className="bg-gray-800 rounded-lg p-2 sm:p-4 overflow-x-auto text-xs sm:text-sm">
    <code className={`language-${language}`}>{code}</code>
  </pre>
);

const DSAExamEnvironment = () => {
  const [code, setCode] = useState(`function twoSum(nums, target) {
  // Your solution here
}`);
  const [output, setOutput] = useState('');
  const [testCases, setTestCases] = useState([
    { input: '[2, 7, 11, 15], 9', expected: '[0, 1]', passed: false },
    { input: '[3, 2, 4], 6', expected: '[1, 2]', passed: false },
    { input: '[3, 3], 6', expected: '[0, 1]', passed: false },
  ]);
  const [isRunning, setIsRunning] = useState(false);
  const [showTestCases, setShowTestCases] = useState(false);

  const problem = {
    title: "Two Sum",
    description: `Given an array of integers nums and an integer target, return indices of the two numbers such that they add up to target. You may assume that each input would have exactly one solution, and you may not use the same element twice. You can return the answer in any order.`,
    example: {
      input: "nums = [2,7,11,15], target = 9",
      output: "[0,1]",
      explanation: "Because nums[0] + nums[1] == 9, we return [0, 1]."
    }
  };

  const runCode = () => {
    setIsRunning(true);
    setOutput('');
    setShowTestCases(true);
    
    // Simulating code execution and test cases
    setTimeout(() => {
      const newTestCases = testCases.map(testCase => ({
        ...testCase,
        passed: Math.random() > 0.5 // Randomly pass or fail for demonstration
      }));
      setTestCases(newTestCases);
      setOutput('Execution complete. Check test results below.');
      setIsRunning(false);
    }, 2000);
  };

  const resetCode = () => {
    setCode(`function twoSum(nums, target) {
  // Your solution here
}`);
    setOutput('');
    setTestCases(testCases.map(tc => ({ ...tc, passed: false })));
    setShowTestCases(false);
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow-xl rounded-lg overflow-hidden">
      <div className="bg-gray-100 dark:bg-gray-700 px-4 py-2 flex items-center justify-between">
        <div className="flex items-center">
          <Code className="w-5 h-5 text-indigo-600 mr-2" />
          <span className="font-semibold text-gray-700 dark:text-gray-300">{problem.title}</span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={runCode}
            disabled={isRunning}
            className={`p-2 rounded-full ${
              isRunning ? 'bg-gray-300 text-gray-500' : 'bg-green-500 text-white hover:bg-green-600'
            }`}
          >
            <Play className="w-4 h-4" />
          </button>
          <button
            onClick={resetCode}
            className="p-2 rounded-full bg-blue-500 text-white hover:bg-blue-600"
          >
            <RotateCcw className="w-4 h-4" />
          </button>
        </div>
      </div>
      <div className="p-4">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="w-full md:w-1/2">
            <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-200">Problem Description</h3>
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">{problem.description}</p>
            <div className="mb-4 bg-gray-50 dark:bg-gray-900 p-4 rounded-lg">
              <h4 className="text-md font-semibold mb-2 text-gray-800 dark:text-gray-200">Example:</h4>
              <p className="text-sm text-gray-600 dark:text-gray-400">Input: {problem.example.input}</p>
              <p className="text-sm text-gray-600 dark:text-gray-400">Output: {problem.example.output}</p>
              <p className="text-sm text-gray-500 dark:text-gray-500 mt-1">{problem.example.explanation}</p>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <textarea
              value={code}
              onChange={(e) => setCode(e.target.value)}
              className="w-full h-64 p-2 text-sm font-mono bg-gray-50 dark:bg-gray-900 text-gray-800 dark:text-gray-200 border border-gray-300 dark:border-gray-700 rounded-lg"
            />
            {output && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="mt-4 p-2 bg-gray-100 dark:bg-gray-700 rounded-lg"
              >
                <p className="text-sm font-mono text-gray-800 dark:text-gray-200">{output}</p>
              </motion.div>
            )}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {showTestCases && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            className="p-4 bg-gray-50 dark:bg-gray-900"
          >
            <h3 className="text-lg font-semibold mb-2 text-gray-800 dark:text-gray-200">Test Cases</h3>
            <div className="space-y-2">
              {testCases.map((testCase, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="flex items-center justify-between p-2 bg-white dark:bg-gray-800 rounded-lg shadow"
                >
                  <div>
                    <p className="text-sm text-gray-600 dark:text-gray-400">Input: {testCase.input}</p>
                    <p className="text-sm text-gray-600 dark:text-gray-400">Expected: {testCase.expected}</p>
                  </div>
                  {testCase.passed ? (
                    <Check className="w-5 h-5 text-green-500" />
                  ) : (
                    <X className="w-5 h-5 text-red-500" />
                  )}
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};


const WhyCodeEditor = () => {
  const [activeSection, setActiveSection] = useState('features');
  const [isIntersecting, setIsIntersecting] = useState(false);
  const sectionRef = useRef(null);
  const controls = useAnimation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isIntersecting) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [isIntersecting, controls]);

  const features = [
    {
      icon: Code,
      title: "Multi-Language Support",
      description: "Write and test code in over 30 programming languages, catering to diverse development needs."
    },
    {
      icon: Shield,
      title: "Secure Assessment Environment",
      description: "Ensure the integrity of your coding tests with our advanced proctoring and anti-cheating measures."
    },
    {
      icon: BarChart2,
      title: "Comprehensive Analytics",
      description: "Gain deep insights into candidate performance with detailed reports and data visualization tools."
    },
    {
      icon: Zap,
      title: "Real-time Collaboration",
      description: "Enable seamless cooperation between team members with live coding sessions and instant feedback."
    }
  ];
  

  const sampleCode = `
function fibonacci(n) {
  if (n <= 1) return n;
  return fibonacci(n - 1) + fibonacci(n - 2);
}

console.log(fibonacci(10));
  `.trim();

  

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-indigo-100 dark:from-gray-900 dark:to-indigo-900">
      <Navbar />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 sm:py-16 lg:py-24">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-8 sm:mb-12 lg:mb-16"
        >
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-gray-900 dark:text-white mb-3 sm:mb-4 leading-tight">
            Elevate Your Coding Experience with <span className="text-indigo-600 dark:text-indigo-400">CirrusCypher</span>
          </h1>
          <p className="text-base sm:text-lg lg:text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto mb-6 sm:mb-8 px-4">
            Unlock the power of seamless code editing, testing, and collaboration in one intuitive platform.
          </p>
          <Link 
            to="/get-started" 
            className="inline-flex items-center px-6 sm:px-8 py-3 sm:py-4 bg-indigo-600 text-white rounded-full font-semibold hover:bg-indigo-700 transition-colors duration-300 shadow-lg group text-sm sm:text-base"
          >
            Start Coding Now
            <ChevronRight className="w-4 h-4 sm:w-5 sm:h-5 ml-2 group-hover:translate-x-1 transition-transform" />
          </Link>
        </motion.div>

        <div className="flex flex-col lg:flex-row gap-8 lg:gap-12 mb-16 sm:mb-20 lg:mb-24">
          <div className="lg:w-1/2 px-2 sm:px-0">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white mb-4 sm:mb-6">
              Why Choose CirrusCypher?
            </h2>
            <div className="space-y-4">
              {features.map((feature, index) => (
                <Feature key={index} {...feature} />
              ))}
            </div>
          </div>
          <div className="lg:w-1/2">
            <DSAExamEnvironment />
          </div>
        </div>

        <motion.div
          ref={sectionRef}
          initial={{ opacity: 0, y: 50 }}
          animate={controls}
          transition={{ duration: 0.5 }}
          className="text-center px-4 sm:px-6"
        >
          <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white mb-3 sm:mb-4">
            Ready to Transform Your Coding Workflow?
          </h2>
          <p className="text-base sm:text-lg lg:text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto mb-6 sm:mb-8">
            Join thousands of developers and organizations who have elevated their coding experience with CirrusCypher.
          </p>
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <Link 
              to="/feature" 
              className="w-full sm:w-auto inline-flex items-center justify-center px-6 sm:px-8 py-3 sm:py-4 bg-indigo-600 text-white rounded-full font-semibold hover:bg-indigo-700 transition-colors duration-300 shadow-lg text-sm sm:text-base"
            >
              Explore Features
            </Link>
            <Link 
              to="/subscription" 
              className="w-full sm:w-auto inline-flex items-center justify-center px-6 sm:px-8 py-3 sm:py-4 bg-white text-indigo-600 rounded-full font-semibold hover:bg-gray-100 transition-colors duration-300 shadow-lg border border-indigo-600 text-sm sm:text-base"
            >
              View Pricing
            </Link>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default WhyCodeEditor;