import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedTestRoute = ({ Component }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const testId = localStorage.getItem("testId");

    if (!testId) {
      // If no testId found, redirect to the attempt test page
      navigate("/attempt-test-page");
    }
  }, [navigate]);

  return (
    <>
      {/* Only render the component if testId exists */}
      {localStorage.getItem("testId") && <Component />}
    </>
  );
};

export default ProtectedTestRoute;
