import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../../css/TestView.css";

const TestView = ({ testId, onClose }) => {
  const [questions, setQuestions] = useState([]);
  

  useEffect(() => {
    fetchQuestions();
  }, [testId]);

  const navigate = useNavigate();

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/tests/${testId}/questions`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleGoToDashboard = () => {
    onClose();  // Calls the function passed from the parent to set the view to "test"
  };

  return (
    <>
     
      <div className="TestView-container">
        <h1 className="TestView-title">Test Questions</h1>
        <table className="TestView-table">
          <thead>
            <tr>
              <th>Question ID</th>
              <th>Question Content</th>
            </tr>
          </thead>
          <tbody>
            {questions.map((question) => (
              <tr key={question.question_id}>
                <td>{question.question_id}</td>
                <td>{question.question_content}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <button onClick={handleGoToDashboard} className="dashboard-button">
        Go To Dashboard
      </button>
      
      </div>
    </>
  );
};

export default TestView;
