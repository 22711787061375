import React from 'react';
import { List, ListItem, ListItemText } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import PublicIcon from '@mui/icons-material/Public';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import '../../css/SidebarNavigation.css';

const SidebarNavigation = ({ selected, setSelected }) => {
    const menuItems = [
        { id: 'overview', label: 'Overview', icon: <HomeIcon /> },
        { id: 'test-history', label: 'Test History', icon: <HistoryIcon /> },
        { id: 'public-tests', label: 'Public Tests', icon: <PublicIcon /> },
        { id: 'achievements', label: 'Achievements', icon: <EmojiEventsIcon /> },
    ];

    return (
        <nav className="sidebar-navigation">
            <List>
                {menuItems.map(item => (
                    <ListItem
                        button
                        key={item.id}
                        className={selected === item.id ? 'active' : ''}
                        onClick={() => setSelected(item.id)}
                    >
                        <span className="sidebar-icon">{item.icon}</span>
                        <ListItemText primary={item.label} />
                    </ListItem>
                ))}
            </List>
        </nav>
    );
};

export default SidebarNavigation;
