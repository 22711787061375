import React from "react";
import DOMPurify from 'dompurify';
import "../css/QuestionSection.css";

const QuestionSection = ({ questionTitle, questionContent }) => {
  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const sanitizeAndRenderContent = (content) => {
    if (!content) return null;
    const decodedContent = decodeHtml(content);
    const sanitizedContent = DOMPurify.sanitize(decodedContent, {
      ADD_TAGS: ['img'],
      ADD_ATTR: ['src'],
    });
    return sanitizedContent;
  };

  return (
    <div className="question-container">
      {questionContent ? (
        <>
          <h2 className="question-title">{decodeHtml(questionTitle)}</h2>
          <div
            className="question-content"
            dangerouslySetInnerHTML={{
              __html: sanitizeAndRenderContent(questionContent),
            }}
          />
        </>
      ) : (
        <p>No question selected</p>
      )}
    </div>
  );
};
export default QuestionSection;
