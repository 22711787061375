// import React, { useState, useEffect } from "react";
// import Select from "react-select";
// import { customStyles } from "../constants/customStyles";
// import { languageOptions } from "../constants/languageOptions";

// const LanguagesDropdown = ({ onSelectChange, language, questionId }) => {
//   const [filteredOptions, setFilteredOptions] = useState([]);
//   const question_id = questionId;

//   useEffect(() => {
//     if (language) {
//       const filtered = languageOptions.filter(
//         (option) => option.name === language
//       );
//       setFilteredOptions(filtered);
//     } else {
//       setFilteredOptions([]);
//     }
//   }, [language]);

//   return (
//     <Select
//       placeholder="Select language"
//       options={filteredOptions}
//       styles={customStyles}
//       onChange={(selectedOption) => onSelectChange(selectedOption)}
//     />
//   );
// };

// export default LanguagesDropdown;
// //=================================================
import React, { useState, useEffect } from "react";
import Select from "react-select";
import { customStyles } from "../../constants/customStyles";
import { languageOptions } from "../../constants/languageOptions";

const LanguagesDropdown = ({ onSelectChange, language, questionId }) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  useEffect(() => {
    // Filter options based on selected language
    if (language) {
      const filtered = languageOptions.filter(
        (option) => option.name === language.name
      );
      setFilteredOptions(filtered);
      console.log(language)
      console.log(filtered)
      setSelectedLanguage(filtered[0]);
      onSelectChange(filtered[0]);
      console.log(filteredOptions)
      console.log(selectedLanguage)
      console.log(languageOptions)

    } else {
      setFilteredOptions([]);
    }
  }, [language]);

  return (
    <Select
      placeholder="Select language"
      options={filteredOptions}
      styles={customStyles}
      value={selectedLanguage}
      onChange={(selectedOption) => {
        console.log(selectedOption);
        setSelectedLanguage(selectedOption);
        onSelectChange(selectedOption);
      }}
    />
  );
};

export default LanguagesDropdown;
