import "../css/ContactQueryForm.css";

const ContactQueryForm = () => {
  return (
    <div className="contact-page-query-container">
      <div className="row">
        <div className="contact-query-form-container" style={{ width: "100%" }}>
          <form
            action="https://formkeep.com/f/a4ff462c2dd3"
            acceptCharset="UTF-8"
            encType="multipart/form-data"
            method="POST"
          >
            <fieldset className="contact-query-field-container">
              <center>
                <h2 className="contact-query-title">
                  Submit Your<span> Enquiry</span>
                </h2>
              </center>
              <br />
              <div className="row">
                <div
                  className="form-group col-xs-12 contact-query-form-group"
                  id="Name__div"
                >
                  <label
                    title="required"
                    htmlFor="Name"
                    className="contact-query-label"
                  >
                    Your Name *
                  </label>
                  <input
                    type="text"
                    name="Name"
                    id="Name"
                    required
                    autoFocus
                    className="form-control contact-query-input"
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className="form-group col-xs-12 contact-query-form-group"
                  id="Email__div"
                >
                  <label
                    title="required"
                    htmlFor="Email"
                    className="contact-query-label"
                  >
                    Your Email *
                  </label>
                  <input
                    type="email"
                    name="Email"
                    id="Email"
                    required
                    className="form-control contact-query-input"
                  />
                </div>
              </div>
              <div className="row">
                <div
                  className="form-group col-xs-12 contact-query-form-group"
                  id="Your_Query__div"
                >
                  <label
                    title="required"
                    htmlFor="Your_Query"
                    className="contact-query-label"
                  >
                    Your Message: *
                  </label>
                  <textarea
                    name="Your Query:"
                    id="Your_Query"
                    required
                    rows={6}
                    className="form-control contact-query-textarea"
                  />
                </div>
              </div>
              <br />
              <div
                style={{
                  opacity: 0,
                  position: "absolute",
                  top: 0,
                  left: "-5000px",
                  height: 0,
                  width: 0,
                }}
              >
                <label htmlFor="subscribe_d835f9941131_44665"></label>
                <input
                  name="subscribe_d835f9941131_44665"
                  value=""
                  tabIndex={-1}
                  autoComplete="off"
                  type="email"
                  id="email_subscribe_d835f9941131_44665"
                  placeholder="Your email here"
                />
              </div>
              <div className="row">
              <div className="contact-query-submit-container">
  <input
    type="submit"
    value="Submit"
    className="contact-query-custom-button" // Changed class name here
    data-disable-with="Submit"
  />
</div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactQueryForm;
