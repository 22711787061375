import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Timer, User, BookOpen, Fingerprint } from "lucide-react";
import { toast } from "react-toastify";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import DOMPurify from "dompurify";
import MCQSecurity from "./MCQSecurity";

const renderHTML = (html) => {
  return { __html: DOMPurify.sanitize(html) };
};

// Reuse existing ConfirmDialog component
const ConfirmDialog = ({
  isOpen,
  onClose,
  onConfirm,
  answeredCount,
  totalQuestions,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <h2 className="text-xl font-bold mb-4">Confirm Submission</h2>
        <p className="text-gray-600 mb-6">
          You have answered {answeredCount} out of {totalQuestions} questions.
          Are you sure you want to submit? This action cannot be undone.
        </p>
        <div className="flex justify-end gap-4">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};



// Updated Question Navigation component
const QuestionNav = ({
  questions,
  currentQuestion,
  answers,
  onQuestionSelect,
}) => {
  return (
    <div className="grid grid-cols-5 gap-2 p-4 bg-white rounded-lg shadow overflow-y-auto max-h-[600px]">
      {questions.map((q, idx) => (
        <button
          key={q.mcq_question_id}
          onClick={() => onQuestionSelect(idx)}
          className={`
                        h-12 w-12 rounded-lg font-medium border-2 transition-all
                        ${currentQuestion === idx
              ? "border-blue-600 bg-blue-50"
              : ""
            }
                        ${answers[idx] && answers[idx].length > 0
              ? "bg-green-100 border-green-600"
              : "bg-gray-50 border-gray-200"
            }
                        hover:border-blue-400
                    `}
        >
          {idx + 1}
        </button>
      ))}
    </div>
  );
};

// Main MCQTestScreen component
const MCQTestScreen = () => {
  const [violationCount, setViolationCount] = useState(0);
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [testInfo, setTestInfo] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [answers, setAnswers] = useState({});
  const [isLoadingAnswers, setIsLoadingAnswers] = useState(true);

  const checkSession = async () => {
    try {
      const testId = localStorage.getItem("testId");
      const response = await axios.get(`${import.meta.env.VITE_BASE_URL}/api/check-test-session/${testId}`);
      if (!response.data.success) {
        navigate('/test-expired');
      }
    } catch (error) {
      navigate('/test-expired');
    }
  };

  // Timer component 
  const TestTimer = ({ duration, onTimeUpdate }) => {
    const [timeLeft, setTimeLeft] = useState(duration);
    const [isLastTenMinutes, setIsLastTenMinutes] = useState(false);

    useEffect(() => {
      if (timeLeft <= 0) {
        handleSubmit();
        return;
      }

      const timer = setInterval(() => {
        setTimeLeft((prev) => {
          const newTime = Math.max(0, prev - 1);
          onTimeUpdate(newTime);
          if (newTime === 600 && !isLastTenMinutes) {
            setIsLastTenMinutes(true);
            toast.warning("10 minutes left!");
          }
          return newTime;
        });
      }, 1000);

      return () => clearInterval(timer);
    }, [timeLeft, isLastTenMinutes]);

    const formatTime = (seconds) => {
      const hrs = Math.floor(seconds / 3600);
      const mins = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
      return `${hrs.toString().padStart(2, "0")}:${mins
        .toString()
        .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    };

    return (
      <div
        className={`flex items-center gap-2 text-2xl font-bold bg-white p-4 rounded-lg shadow ${isLastTenMinutes ? "text-red-600" : ""
          }`}
      >
        <Timer className={isLastTenMinutes ? "text-red-600" : "text-blue-600"} />
        <span>{formatTime(timeLeft)}</span>
      </div>
    );
  };

  // Load remaining time from localStorage on component mount
  useEffect(() => {
    const storedTime = localStorage.getItem('remainingTime');
    if (storedTime) {
      setRemainingTime(parseInt(storedTime));
    }
  }, []);

  // Save remaining time to localStorage whenever it changes
  useEffect(() => {
    if (remainingTime !== null) {
      localStorage.setItem('remainingTime', remainingTime.toString());
    }
  }, [remainingTime]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const testId = localStorage.getItem("testId");
        const token = localStorage.getItem("token");

        const response = await axios.get(
          `${import.meta.env.VITE_BASE_URL}/api/mcq-test/execution/${testId}/questions`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data.success) {
          setQuestions(response.data.data);
          // After questions are loaded, fetch previous answers
          await fetchPreviousAnswers(response.data.data);
        } else {
          toast.error("Failed to fetch questions");
        }
      } catch (error) {
        console.error("Error fetching questions:", error);
        toast.error(error.response?.data?.error || "Failed to fetch questions");
      } finally {
        setIsLoading(false);
      }
    };

    const loadTestInfo = () => {
      const storedTestInfo = localStorage.getItem("testInfo");
      if (storedTestInfo) {
        const testData = JSON.parse(storedTestInfo);
        setTestInfo(testData);

        // Only set remaining time if it's not already in localStorage
        if (!localStorage.getItem('remainingTime')) {
          const now = new Date();
          const endTime = new Date(testData.testEndDateTime);
          const durationInMinutes = testData.duration;
          const maxDurationMs = durationInMinutes * 60 * 1000;
          const timeUntilEnd = endTime - now;
          const remainingMs = Math.min(maxDurationMs, timeUntilEnd);
          const remainingSeconds = Math.floor(remainingMs / 1000);

          setRemainingTime(remainingSeconds);
        }
      }
    };

    fetchQuestions();
    loadTestInfo();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  const fetchPreviousAnswers = async (questionsList) => {
    setIsLoadingAnswers(true);
    try {
      const email = localStorage.getItem("email");
      const testId = localStorage.getItem("testId");

      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/participant/attempt-mcq-test/fetch-options/${email}/${testId}`
      );

      if (response.data.success) {
        const previousAnswers = {};
        response.data.data.forEach((item) => {
          // Find the question index by matching question_id
          const questionIndex = questionsList.findIndex(
            q => q.mcq_question_id === item.question_id
          );
          if (questionIndex !== -1) {
            // Parse the selected options if it's a string, otherwise use as is
            const selectedOptions = typeof item.selected_options === 'string'
              ? JSON.parse(item.selected_options)
              : item.selected_options;
            previousAnswers[questionIndex] = selectedOptions;
          }
        });

        setAnswers(previousAnswers);

        // Find the last answered question
        const answeredQuestions = Object.keys(previousAnswers).map(Number);
        const maxAnswered = Math.max(...answeredQuestions, -1);

        // Set current question to the next unanswered question
        const nextQuestion = maxAnswered + 1;
        setCurrentQuestion(nextQuestion < questionsList.length ? nextQuestion : 0);

        toast.info("Your previous answers have been restored");
      }
    } catch (error) {
      console.error("Error fetching previous answers:", error);
      if (error.response?.status !== 404) {
        // toast.error("Error fetching previous answers");
      }
    } finally {
      setIsLoadingAnswers(false);
    }
  };

  const handleOptionSelect = (optionId) => {
    setAnswers((prev) => {
      const currentAnswers = prev[currentQuestion] || [];
      if (currentQ.question_type === 0) {
        // Single choice
        return { ...prev, [currentQuestion]: [optionId] };
      } else {
        // Multiple choice
        if (currentAnswers.includes(optionId)) {
          return {
            ...prev,
            [currentQuestion]: currentAnswers.filter((id) => id !== optionId),
          };
        } else {
          return { ...prev, [currentQuestion]: [...currentAnswers, optionId] };
        }
      }
    });
  };

  const handleSaveAndNext = async () => {
    const selectedOptions = answers[currentQuestion] || [];
    if (selectedOptions.length === 0) {
      toast.warning("Please select at least one option before saving.");
      return;
    }

    try {
      // Retrieve testInfo from localStorage
      const testInfoString = localStorage.getItem("testInfo");
      if (!testInfoString) {
        throw new Error("Test information not found");
      }

      const testInfo = JSON.parse(testInfoString);
      const participantId = testInfo.participantId;

      if (!participantId) {
        throw new Error("Participant ID not found");
      }

      // Call API to create execution entry
      const createExecutionResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/mcq-test/execution/create-execution-entry`,
        {
          participantId: participantId,
          testId: localStorage.getItem("testId"),
          questionId: currentQ.mcq_question_id,
          selectedOptions: selectedOptions,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!createExecutionResponse.data.success) {
        throw new Error("Failed to save answer");
      }

      const mcqExecutionId = createExecutionResponse.data.data.mcqExecutionId;

      // Call API to validate and update points
      const validatePointsResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/mcq-test/execution/validate-and-update-points`,
        {
          mcqExecutionId: mcqExecutionId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (!validatePointsResponse.data.success) {
        throw new Error("Failed to validate and update points");
      }

      // Update answers state
      setAnswers((prev) => ({
        ...prev,
        [currentQuestion]: selectedOptions,
      }));

      // Move to next question
      if (currentQuestion < questions.length - 1) {
        setCurrentQuestion((prev) => prev + 1);
      } else {
        toast.info("You've reached the last question.");
      }

      toast.success("Answer saved successfully");
    } catch (error) {
      console.error("Error saving answer:", error);
      toast.error(error.message || "Failed to save answer");
    }
  };

  const handleViolation = (message) => {
    setViolationCount((prev) => {
      const newCount = prev + 1;
      if (newCount >= 15) {
        handleSubmit();
        navigate("/mcq-test-submitted");
      }
      return newCount;
    });
  };

  // Modify handleSubmit to navigate to the submitted page
  const handleSubmit = async () => {
    try {
      const email = localStorage.getItem("email");
      const testId = localStorage.getItem("testId");

      // Call the /submit-test API
      const submitResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/submit-test`,
        { email, testId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (submitResponse.data.message === "Test Submitted.") {
        // Clean up all test-related data
        cleanupTestData();

        setIsDialogOpen(false);
        toast.success("Test submitted successfully");
        navigate("/mcq-test-submitted");
      } else {
        throw new Error("Unexpected response from server");
      }
    } catch (error) {
      console.error("Error submitting test:", error);
      toast.error(error.response?.data?.error || "Failed to submit test");
    }
  };

  const cleanupTestData = () => {
    // Remove all test-related items from localStorage
    localStorage.removeItem('email');
    localStorage.removeItem('testId');
    localStorage.removeItem('testInfo');
    localStorage.removeItem('remainingTime');
    // If you have other test-related items, remove them here
  };

  if (isLoading || isLoadingAnswers) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-xl font-semibold">
          {isLoading ? "Loading questions..." : "Restoring previous answers..."}
        </div>
      </div>
    );
  }

  const currentQ = questions[currentQuestion];

  return (
    <MCQSecurity onViolation={handleViolation}>
      <div className="min-h-screen bg-gray-100 p-6">
        {/* Header section */}
        <div className="flex justify-between mb-6">
          <div className="bg-white p-4 rounded-lg shadow flex-grow mr-4">
            <div className="flex items-center gap-2 mb-2">
              <BookOpen className="text-blue-600" />
              <h1 className="text-2xl font-bold">
                {testInfo?.test_title || "Loading..."}
              </h1>
            </div>
            <div className="flex items-center gap-2 text-gray-600 mt-2">
              <User className="text-blue-600" />
              <p>Candidate: {localStorage.getItem("email")}</p>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <Fingerprint className="text-blue-600" />
              <p>Test ID: {localStorage.getItem("testId")}</p>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <TestTimer
              duration={remainingTime}
              onTimeUpdate={(newTime) => setRemainingTime(newTime)}
            />
            <button
              onClick={() => setIsDialogOpen(true)}
              className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
            >
              Submit Test
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex gap-6">
          {/* Question Area */}
          <div className="w-[70%] bg-white p-6 rounded-lg shadow">
            <div className="mb-8">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">
                  Question {currentQuestion + 1} of {questions.length}
                </h2>
                <span className="text-sm text-gray-500">
                  Points: {currentQ?.points || 0} | Type:{" "}
                  {currentQ?.question_type === 0
                    ? "Single Choice"
                    : "Multiple Choice"}
                </span>
              </div>

              <div className="space-y-4 mb-6">
                <div
                  dangerouslySetInnerHTML={renderHTML(currentQ?.question_text)}
                />
                {currentQ?.question_description && (
                  <div
                    dangerouslySetInnerHTML={renderHTML(
                      currentQ.question_description
                    )}
                  />
                )}
              </div>

              <div className="space-y-4">
                {currentQ?.options.map((option) => (
                  <label
                    key={option.option_id}
                    className="flex items-center p-4 border-2 rounded-lg cursor-pointer hover:bg-gray-50 transition-colors"
                  >
                    <input
                      type={currentQ.question_type === 0 ? "radio" : "checkbox"}
                      checked={(answers[currentQuestion] || []).includes(
                        option.option_id
                      )}
                      onChange={() => handleOptionSelect(option.option_id)}
                      className="mr-4 h-4 w-4"
                    />
                    <div
                      dangerouslySetInnerHTML={renderHTML(option.option_text)}
                    />
                  </label>
                ))}
              </div>
            </div>

            <div className="flex justify-between mt-6">
              <button
                onClick={() =>
                  setCurrentQuestion((prev) => Math.max(0, prev - 1))
                }
                disabled={currentQuestion === 0}
                className="px-6 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors disabled:opacity-50"
              >
                Previous
              </button>
              <button
                onClick={handleSaveAndNext}
                className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors"
              >
                Save and Next
              </button>
            </div>
          </div>

          {/* Question Navigation */}
          <div className="w-[30%] flex flex-col gap-4">
            <QuestionNav
              questions={questions}
              currentQuestion={currentQuestion}
              answers={answers}
              onQuestionSelect={setCurrentQuestion}
            />
          </div>
        </div>

        {/* Confirmation Dialog */}
        <ConfirmDialog
          isOpen={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          onConfirm={handleSubmit}
          answeredCount={
            Object.values(answers).filter((a) => a.length > 0).length
          }
          totalQuestions={questions.length}
        />
      </div>
    </MCQSecurity>
  );
};

export default MCQTestScreen;
