import React from "react";
import { Link } from "react-router-dom";
import "../../css/HowItWorksSection.css";

const HowItWorksSection = () => {
  return (
    <section className="how-it-works-section">
      <div className="how-it-works-container">
        <h2 className="how-it-works-title">How It Works</h2>
        <div className="how-it-works-steps">
          <Link to="/signup" className="how-it-works-step">
            <img
              src="/assets/step1.svg"
              alt="Step 1"
              className="step-image"
            />
            <h3 className="step-title">Create Tests</h3>
            <ul className="step-list">
              <li>Arrange questions</li>
              <li>Set test duration and difficulty level</li>
              <li>Provide answers and solutions</li>
            </ul>
          </Link>
          <Link to="/signup" className="how-it-works-step">
            <img
              src="/assets/step2.svg"
              alt="Step 2"
              className="step-image"
            />
            <h3 className="step-title">Take Tests</h3>
            <ul className="step-list">
              <li>Sign up or login</li>
              <li>Select desired test</li>
              <li>Complete the test within the given time</li>
            </ul>
          </Link>
          <Link to="/signup" className="how-it-works-step">
            <img
              src="/assets/step3.svg"
              alt="Step 3"
              className="step-image"
            />
            <h3 className="step-title">Review & Improve</h3>
            <ul className="step-list">
              <li>Check results and solutions</li>
              <li>Review incorrect answers</li>
              <li>Learn from explanations and feedback</li>
            </ul>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;
