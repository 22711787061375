// src/components/Preparation/BookmarkModal.js

import React from 'react';
import { AlertCircle } from 'lucide-react';
import PropTypes from 'prop-types';

export default function BookmarkModal({
  isOpen,
  onClose,
  bookmarkedQuestions,
  toggleBookmark,
  bookmarkSearch,
  setBookmarkSearch,
  companies,
}) {
  if (!isOpen) return null;

  // Aggregate all bookmarked questions across all companies
  const allBookmarkedQuestions = companies.flatMap((company) =>
    company.content
      ? Object.values(company.content).flat().filter((question) =>
          bookmarkedQuestions.has(question.title)
        ).map(question => ({
          ...question,
          companyName: company.name,
        }))
      : []
  );

  // Filter bookmarked questions based on search query
  const filteredBookmarks = allBookmarkedQuestions.filter((question) =>
    question.title.toLowerCase().includes(bookmarkSearch.toLowerCase())
  );

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 p-6 relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl font-semibold"
          aria-label="Close Modal"
        >
          &times;
        </button>

        {/* Modal Title */}
        <h2 className="text-2xl font-semibold mb-4">Bookmarked Questions</h2>

        {/* Search Input */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search bookmarks..."
            className="w-full p-2 border rounded-md"
            value={bookmarkSearch}
            onChange={(e) => setBookmarkSearch(e.target.value)}
          />
        </div>

        {/* Bookmarked Questions List */}
        {filteredBookmarks.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-40">
            <AlertCircle className="h-10 w-10 text-gray-400 mb-2" />
            <p className="text-gray-600">No bookmarks found.</p>
          </div>
        ) : (
          <ul className="space-y-4 max-h-80 overflow-y-auto">
            {filteredBookmarks.map((question) => (
              <li key={question.title} className="border-b pb-2">
                <div className="flex justify-between items-center">
                  <div>
                    <h3 className="text-lg font-semibold">{question.title}</h3>
                    <p className="text-sm text-gray-500">{question.companyName}</p>
                  </div>
                  <button
                    onClick={() => toggleBookmark(question.title)}
                    className="text-red-500 hover:text-red-700"
                    aria-label={`Remove bookmark for ${question.title}`}
                  >
                    Remove
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

// PropTypes for type checking
BookmarkModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  bookmarkedQuestions: PropTypes.instanceOf(Set).isRequired,
  toggleBookmark: PropTypes.func.isRequired,
  bookmarkSearch: PropTypes.string.isRequired,
  setBookmarkSearch: PropTypes.func.isRequired,
  companies: PropTypes.array.isRequired,
};
