import React, { useState } from "react";
import ProfileHeader from "./ProfileHeader";
import SidebarNavigation from "./SidebarNavigation";
import MainContent from "./MainContent";
import ProfileSettings from "./ProfileSettings";
import "../../css/ProfilePage.css";

const ProfilePage = ({ username }) => {
  const [selected, setSelected] = useState("overview");
  const [showProfileSettings, setShowProfileSettings] = useState(false);

  const handleProfileSettingsClick = () => {
    setShowProfileSettings(true);
    setSelected("profile-settings");
  };

  return (
    <div className="profile-page">
      <ProfileHeader username={username} onProfileSettingsClick={handleProfileSettingsClick} />
      <SidebarNavigation selected={selected} setSelected={setSelected} />
      {showProfileSettings ? (
        <ProfileSettings 
          setSelected={setSelected} 
          setShowProfileSettings={setShowProfileSettings} 
        />
      ) : (
        <MainContent selected={selected} />
      )}
    </div>
  );
};

export default ProfilePage;