import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Tooltip,
    Modal,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import CreateMCQQuestion from './CreateMCQQuestion';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import MCQBulkQuestion from '../OrgDashboard/MCQBulkQuestion';

const MCQQuestions = ({ onBack }) => {
    const [showCreateQuestion, setShowCreateQuestion] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [isViewModalOpen, setIsViewModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const [questionToDelete, setQuestionToDelete] = useState(null);
    const [editedQuestion, setEditedQuestion] = useState(null);
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("user_id");
    const BASE_URL = import.meta.env.VITE_BASE_URL;
    const [showBulkCreate, setShowBulkCreate] = useState(false);

    useEffect(() => {
        fetchMCQQuestions();
    }, []);

    const fetchMCQQuestions = async () => {
        try {
            const response = await axios.get(
                `${BASE_URL}/api/org/question/fetch-questions/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.data.questions && response.data.data.questions.length > 0) {
                setQuestions(response.data.data.questions);
            } else {
                setQuestions([]); // Set to empty array if no questions
                toast.info("No questions available.");
            }
        } catch (error) {
            console.error("Error fetching MCQ questions:", error);
            setQuestions([]); // Set to empty array in case of error
            if (error.response && error.response.status === 404) {
                toast.info("No questions available.");
            } else {
                toast.error("Failed to fetch questions");
            }
        }
    };

    const handleBulkCreateClick = () => {
        setShowBulkCreate(true);
    };

    const handleBulkCreateBack = () => {
        setShowBulkCreate(false);
        fetchMCQQuestions(); // Refresh the question list after bulk creation
    };

    if (showBulkCreate) {
        return <MCQBulkQuestion onBack={handleBulkCreateBack} />;
    }

    const handleCreateClick = () => {
        setShowCreateQuestion(true);
    };

    const handleBackToList = () => {
        setShowCreateQuestion(false);
        fetchMCQQuestions();
    };

    const handleViewQuestion = (question) => {
        setSelectedQuestion(question);
        setIsViewModalOpen(true);
    };

    const handleEditQuestion = async (questionId) => {
        try {
            const response = await axios.get(
                `${BASE_URL}/api/org/question/get-mcq-question-data/${questionId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            const questionData = response.data.data;

            // Helper function to ensure HTML format for description and options
            const ensureHtmlFormat = (text) => {
                if (!text) return '';
                return text.trim().startsWith('<') ? text : `<p>${text}</p>`;
            };

            // Helper function to strip HTML tags for question text
            const stripHtml = (html) => {
                if (!html) return '';
                const tmp = document.createElement('DIV');
                tmp.innerHTML = html;
                return tmp.textContent || tmp.innerText || '';
            };

            const formattedQuestion = {
                mcqQuestionId: questionId,
                questionType: questionData.questionType === 1 || questionData.questionType === true,
                questionText: stripHtml(questionData.questionText), // Strip HTML from question text
                questionDescription: ensureHtmlFormat(questionData.questionDescription),
                difficulty: questionData.difficulty,
                points: questionData.points,
                options: questionData.options.map(opt => ({
                    optionId: opt.option_id,
                    optionText: ensureHtmlFormat(opt.option_text),
                    isCorrect: Boolean(opt.is_correct)
                }))
            };

            setEditedQuestion(formattedQuestion);
            setIsEditModalOpen(true);
        } catch (error) {
            console.error("Error fetching question data for edit:", error);
            toast.error("Failed to fetch question data for editing");
        }
    };

    const handleEditSubmit = async (event) => {
        event.preventDefault();
        try {
            await axios.post(
                `${BASE_URL}/api/org/question/update-mcq-question-data`,
                editedQuestion,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success("Question updated successfully");
            setIsEditModalOpen(false);
            fetchMCQQuestions();
        } catch (error) {
            console.error("Error updating question:", error);
            toast.error("Failed to update question");
        }
    };

    const handleDeleteClick = (question) => {
        setQuestionToDelete(question);
        setIsDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            await axios.delete(`${BASE_URL}/api/org/question/delete-mcq-question/${questionToDelete.mcq_question_id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            // Remove the deleted question from the local state
            setQuestions(prevQuestions => prevQuestions.filter(q => q.mcq_question_id !== questionToDelete.mcq_question_id));

            toast.success("Question deleted successfully");

            // Check if this was the last question
            if (questions.length === 1) {
                toast.info("All questions have been deleted.");
            }
        } catch (error) {
            console.error("Error deleting question:", error);
            toast.error("Failed to delete question");
        }
        setIsDeleteDialogOpen(false);
        setQuestionToDelete(null);
    };

    if (showCreateQuestion) {
        return <CreateMCQQuestion onBack={handleBackToList} />;
    }

    return (
        <Box sx={{ p: 3, bgcolor: 'background.paper' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Button
                    startIcon={<ArrowBackIcon />}
                    onClick={onBack}
                    sx={{ color: 'primary.main' }}
                >
                    Back to Question Types
                </Button>
            </Box>
            <Typography variant="h4" gutterBottom>MCQ Questions</Typography>

            <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleCreateClick}
                sx={{ mb: 3, mt: 2, mr: 2, bgcolor: 'success.main', '&:hover': { bgcolor: 'success.dark' } }}
            >
                Create Question
            </Button>
            <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleBulkCreateClick}
                sx={{ mb: 3, mt: 2, bgcolor: 'primary.main', '&:hover': { bgcolor: 'primary.dark' } }}
            >
                Bulk Create Questions
            </Button>

            {questions.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Question ID</TableCell>
                                <TableCell>Question Text</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Difficulty</TableCell>
                                <TableCell>Points</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {questions.map((question) => (
                                <TableRow key={question.mcq_question_id}>
                                    <TableCell>
                                        <Tooltip title={question.mcq_question_id}>
                                            <span>{question.mcq_question_id.substring(0, 8)}...</span>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ maxWidth: 300 }}> {/* Added maxWidth for better layout */}
                                        <div
                                            dangerouslySetInnerHTML={{ __html: question.question_text }}
                                            style={{
                                                wordBreak: 'break-word',
                                                maxHeight: '100px',
                                                overflowY: 'auto'
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ maxWidth: 300 }}> {/* Added maxWidth for better layout */}
                                        <div
                                            dangerouslySetInnerHTML={{ __html: question.question_description }}
                                            style={{
                                                wordBreak: 'break-word',
                                                maxHeight: '100px',
                                                overflowY: 'auto'
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell>{question.difficulty}</TableCell>
                                    <TableCell>{question.points}</TableCell>
                                    <TableCell>
                                        <Tooltip title="View">
                                            <IconButton onClick={() => handleViewQuestion(question)} color="primary">
                                                <VisibilityIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Edit">
                                            <IconButton onClick={() => handleEditQuestion(question.mcq_question_id)} color="primary">
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Delete">
                                            <IconButton onClick={() => handleDeleteClick(question)} color="error">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography variant="h6" sx={{ mt: 4, textAlign: 'center' }}>
                    No questions available. Click 'Create Question' to add a new question.
                </Typography>
            )}

            {/* View Modal */}
            <Modal
                open={isViewModalOpen}
                onClose={() => setIsViewModalOpen(false)}
                aria-labelledby="view-question-modal"
                aria-describedby="view-question-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <IconButton
                        aria-label="close"
                        onClick={() => setIsViewModalOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="view-question-modal" variant="h5" component="h2" gutterBottom>
                        Question Details
                    </Typography>
                    {selectedQuestion && (
                        <Box>
                            <Typography variant="subtitle1" fontWeight="bold">Question Type:</Typography>
                            <Typography>
                                {selectedQuestion.question_type === 0 ? "Single Correct Option" : "Multiple Correct Options"}
                            </Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="subtitle1" fontWeight="bold">Question Text:</Typography>
                            <div dangerouslySetInnerHTML={{ __html: selectedQuestion.question_text }} />
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="subtitle1" fontWeight="bold">Description:</Typography>
                            <div dangerouslySetInnerHTML={{ __html: selectedQuestion.question_description }} />
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="subtitle1" fontWeight="bold">Difficulty: {selectedQuestion.difficulty}</Typography>
                            <Typography variant="subtitle1" fontWeight="bold">Points: {selectedQuestion.points}</Typography>
                            <Divider sx={{ my: 2 }} />
                            <Typography variant="subtitle1" fontWeight="bold">Options:</Typography>
                            {selectedQuestion.options.map((option, index) => (
                                <Box
                                    key={option.optionId}
                                    sx={{
                                        mb: 1,
                                        p: 1,
                                        bgcolor: option.isCorrect ? 'success.light' : 'background.default',
                                        borderRadius: 1
                                    }}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: option.optionText }} />
                                    <Typography variant="body2" color={option.isCorrect ? "success.main" : "text.secondary"}>
                                        {option.isCorrect ? "Correct" : "Incorrect"}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
            </Modal>

            {/* Edit Modal */}
            <Modal
                open={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                aria-labelledby="edit-question-modal"
                aria-describedby="edit-question-form"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    maxHeight: '90vh',
                    overflowY: 'auto',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                }}>
                    <IconButton
                        aria-label="close"
                        onClick={() => setIsEditModalOpen(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography id="edit-question-modal" variant="h5" component="h2" gutterBottom>
                        Edit Question
                    </Typography>
                    {editedQuestion && (
                        <form onSubmit={handleEditSubmit}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Question Type</InputLabel>
                                <Select
                                    value={editedQuestion.questionType}
                                    onChange={(e) => setEditedQuestion({ ...editedQuestion, questionType: e.target.value })}
                                >
                                    <MenuItem value={false}>Single Correct Option</MenuItem>
                                    <MenuItem value={true}>Multiple Correct Options</MenuItem>
                                </Select>
                            </FormControl>

                            {/* Regular TextField for Question Text */}
                            <TextField
                                fullWidth
                                multiline
                                rows={1}
                                margin="normal"
                                label="Question Text"
                                value={editedQuestion.questionText || ''}
                                onChange={(e) => setEditedQuestion({
                                    ...editedQuestion,
                                    questionText: e.target.value
                                })}
                                required
                            />

                            <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Question Description</Typography>
                            <ReactQuill
                                value={editedQuestion.questionDescription || ''}
                                onChange={(content) => {
                                    if (content !== editedQuestion.questionDescription) {
                                        setEditedQuestion(prev => ({
                                            ...prev,
                                            questionDescription: content
                                        }));
                                    }
                                }}
                                modules={{
                                    toolbar: [
                                        ['bold', 'italic', 'underline'],
                                        ['link', 'image'],
                                    ],
                                }}
                            />

                            <FormControl fullWidth margin="normal">
                                <InputLabel>Difficulty</InputLabel>
                                <Select
                                    value={editedQuestion.difficulty}
                                    onChange={(e) => setEditedQuestion({ ...editedQuestion, difficulty: e.target.value })}
                                >
                                    <MenuItem value="easy">Easy</MenuItem>
                                    <MenuItem value="medium">Medium</MenuItem>
                                    <MenuItem value="hard">Hard</MenuItem>
                                </Select>
                            </FormControl>

                            <TextField
                                fullWidth
                                margin="normal"
                                label="Points"
                                type="number"
                                value={editedQuestion.points}
                                onChange={(e) => setEditedQuestion({ ...editedQuestion, points: Number(e.target.value) })}
                            />

                            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>Options</Typography>
                            {editedQuestion.options.map((option, index) => (
                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    {editedQuestion.questionType ? (
                                        <Checkbox
                                            checked={option.isCorrect}
                                            onChange={(e) => {
                                                const newOptions = [...editedQuestion.options];
                                                newOptions[index].isCorrect = e.target.checked;
                                                setEditedQuestion({ ...editedQuestion, options: newOptions });
                                            }}
                                        />
                                    ) : (
                                        <Radio
                                            checked={option.isCorrect}
                                            onChange={() => {
                                                const newOptions = editedQuestion.options.map((opt, i) => ({
                                                    ...opt,
                                                    isCorrect: i === index
                                                }));
                                                setEditedQuestion({ ...editedQuestion, options: newOptions });
                                            }}
                                        />
                                    )}
                                    <Box sx={{ flexGrow: 1, ml: 2 }}>
                                        <ReactQuill
                                            value={option.optionText || ''}
                                            onChange={(content) => {
                                                if (content !== option.optionText) {
                                                    const newOptions = [...editedQuestion.options];
                                                    newOptions[index].optionText = content;
                                                    setEditedQuestion(prev => ({
                                                        ...prev,
                                                        options: newOptions
                                                    }));
                                                }
                                            }}
                                            modules={{
                                                toolbar: [
                                                    ['bold', 'italic', 'underline'],
                                                    ['link', 'image'],
                                                ],
                                            }}
                                        />
                                    </Box>
                                </Box>
                            ))}

                            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => setIsEditModalOpen(false)} sx={{ mr: 1 }}>
                                    Cancel
                                </Button>
                                <Button type="submit" variant="contained" color="primary">
                                    Save Changes
                                </Button>
                            </Box>
                        </form>
                    )}
                </Box>
            </Modal>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={isDeleteDialogOpen}
                onClose={() => setIsDeleteDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Confirm Deletion"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this question? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleDeleteConfirm} color="error" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default MCQQuestions;