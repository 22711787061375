import React from 'react';
import { FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import "../../css/UserDashboardHeader.css";

const UserDashboardHeader = ({ organizationName, userName }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/login');
  };

  return (
    <header className="user-dashboard-header">
      <div className="header-left">
        <h1>{organizationName} | TechnoCirrus Solutions</h1>
      </div>
      <div className="header-right">
        <div className="user-info">
          <div className="user-profile">
            {/* <FaUserCircle className="user-icon" /> */}
            {/* <span>{userName}</span> */}
          </div>
          <button className="logout-button" onClick={handleLogout}>
            <FaSignOutAlt /> Logout
          </button>
        </div>
      </div>
    </header>
  );
};

export default UserDashboardHeader;
