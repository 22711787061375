import React from 'react';

const renderOrgDashboard = () => (
  <div className="cc-doc-content">
    <h1 className="cc-doc-content__title">Introduction</h1>
    <p>The Organization Dashboard in CirrusCypher is a sophisticated and user-friendly interface designed to empower organizations with the tools they need to efficiently create, manage, and monitor coding exams. This dashboard serves as the central hub for all activities related to exam administration, providing a seamless experience for organizations to oversee their testing processes.</p>

    <p>Organizations can effortlessly create new coding tests through the dashboard. This involves defining the test parameters such as the title, description, and duration. Organizations can also set the exam date and time to ensure that participants are aware of when the test will take place. The test creation process is intuitive, allowing organizations to quickly set up exams tailored to their specific needs.</p>

    <p>The dashboard provides robust tools for adding and managing test questions. Organizations can create a variety of question types, including coding challenges. Each question can be accompanied by specific test cases for coding challenges, enabling automated result-oriented test case checking.</p>

    <p>Managing participants is straightforward with the Organization Dashboard. Organizations can import participant lists, manually add participants, or remove them as necessary. The dashboard allows for bulk actions to efficiently handle large groups of participants. Once participants are added, organizations can send email invitations directly from the dashboard. These invitations include the test link, test ID, and detailed instructions to help participants prepare for the exam.</p>

    <p>The Organization Dashboard centralizes all essential functions, providing a streamlined and efficient way for organizations to manage their coding exams. It enhances the overall testing experience by offering powerful tools for test creation, participant management, real-time monitoring, and detailed result analysis. Customization options further allow organizations to tailor the platform to their specific needs, ensuring a professional and cohesive appearance.</p>

    

    <h1 className="cc-doc-content__title">Getting Started</h1>
    <h2 className="cc-doc-content__subtitle">Login instructions</h2>
    <h3 className="cc-doc-content__subsubtitle">How to access the Organization Dashboard</h3>
    <ol className="cc-doc-content__list">
      <li>Select the <strong>Signup</strong> from the navigation bar</li>
      
      <li>An Entity Selecting screen will appear once you click on <strong>Signup</strong>.</li>
      <img src="/Documentation/get-started.png" alt="Entity selection screen" className="cc-doc-content__image" />
      <li>Signup page with Fields to fill of E-mail, password, Organization Name and User Name. With Providing these information it will be storing in database of platform and you can use these credentials while Login. As organizations don't have to Signup each time.</li>
      <img src="/Documentation/Signup page.png" alt="Signup page" className="cc-doc-content__image" />
      <li>After filling all the information and clicking on "Sign Up" on sign up page, It will be redirecting to "Login Page"</li>
      <img src="/Documentation/Login Page.png" alt="Login page" className="cc-doc-content__image" />
      <li>Here is the Organization Dashboard</li>
      <img src="/Documentation/Organization-dashboard.png" alt="Organization Dashboard" className="cc-doc-content__image" />
    </ol>

    <h3 className="cc-doc-content__subsubtitle">Recommended security practices for Organizations</h3>
    <h4 className="cc-doc-content__subsubsubtitle">Regular Password Updates</h4>
    <p>Encourage or enforce regular password changes among administrators, ideally every 3 to 6 months. Passwords should be complex, combining letters, numbers, and symbols.</p>

    <h4 className="cc-doc-content__subsubsubtitle">Secure Connections</h4>
    <p>Always access the Admin Dashboard via a secure, encrypted connection. Avoid using public Wi-Fi networks when managing sensitive data. If remote access is necessary, use a VPN to secure the connection.</p>

    <h4 className="cc-doc-content__subsubsubtitle">Education and Awareness</h4>
    <p>Regularly train administrators on the latest security practices and potential phishing scams. Keeping everyone informed about the latest security risks is critical for maintaining a secure platform.</p>

    <h1 className="cc-doc-content__title">Section 1: Dashboard</h1>
    <h2 className="cc-doc-content__subtitle">Organization statistics</h2>
    <p>Upon logging into the <strong>CirrusCypher</strong> platform, organizations will be directed to the <strong>Organization Dashboard</strong>. This dashboard provides a comprehensive overview of your organization's activity, including tests, participants, and subscription details. Below is a breakdown of the main elements visible on the dashboard.</p>

    <h4 className="cc-doc-content__subsubsubtitle">1. Plan Purchased</h4>
    <ul className="cc-doc-content__list">
      <li><strong>Description</strong>: Displays the current subscription plan the organization is using. In this example, the organization is on a <strong>Free Plan</strong>.</li>
      <li><strong>Purpose</strong>: Helps the organization monitor their current plan to determine if they need to upgrade for more features or additional test capacity.</li>
    </ul>

    <h4 className="cc-doc-content__subsubsubtitle">2. Plan Validity</h4>
    <ul className="cc-doc-content__list">
      <li><strong>Description</strong>: Shows the validity or expiration of the current plan. In the case of the free plan, it displays "No Validity", indicating it doesn't expire.</li>
      <li><strong>Purpose</strong>: Ensures the organization is aware of when their plan might need renewal, depending on the type of subscription they hold.</li>
    </ul>

    <h4 className="cc-doc-content__subsubsubtitle">3. Total Number of Participants</h4>
    <ul className="cc-doc-content__list">
      <li><strong>Description</strong>: Displays the total number of candidates/participants registered under the organization for the tests.</li>
      <li><strong>Purpose</strong>: Keeps track of the number of participants taking part in your tests, giving an immediate overview of participation levels.</li>
    </ul>

    <h4 className="cc-doc-content__subsubsubtitle">4. Total Number of Tests</h4>
    <ul className="cc-doc-content__list">
      <li><strong>Description</strong>: Indicates the total number of tests created and managed by the organization.</li>
      <li><strong>Purpose</strong>: Helps the organization monitor the scale of test creation and test activity.</li>
    </ul>

    <h4 className="cc-doc-content__subsubsubtitle">5. Upcoming Tests</h4>
    <ul className="cc-doc-content__list">
      <li><strong>Description</strong>: Displays how many tests are scheduled for the future.</li>
      <li><strong>Purpose</strong>: Provides a quick view of upcoming tests, allowing organizations to stay prepared for upcoming assessments.</li>
    </ul>

    <h4 className="cc-doc-content__subsubsubtitle">6. Current Tests</h4>
    <ul className="cc-doc-content__list">
      <li><strong>Description</strong>: Displays how many tests are currently active.</li>
      <li><strong>Purpose</strong>: Helps organizations keep track of live assessments and monitor ongoing tests.</li>
    </ul>

    <h4 className="cc-doc-content__subsubsubtitle">7. Upgrade Plan</h4>
    <ul className="cc-doc-content__list">
      <li><strong>Description</strong>: A prominently displayed button that allows organizations to upgrade their current subscription plan to access more advanced features or a larger number of participants and tests.</li>
      <li><strong>Purpose</strong>: Encourages organizations to consider upgrading their plan if they require more functionality or resources for conducting tests.</li>
    </ul>

    <h1 className="cc-doc-content__title">SECTION 2: Managing tests</h1>
    <p>The <strong>Tests</strong> section in the <strong>Organization Dashboard</strong> provides organizations with complete control over creating, managing, and organizing coding assessments. This section enables you to create new tests, edit existing ones, manage participants, view test details, and delete tests if necessary. Below is a detailed guide to help you navigate and utilize the features within the <strong>Tests</strong> section.</p>

    <img src="/Documentation/Test.png" alt="Tests Section" className="cc-doc-content__image" />

    <h3 className="cc-doc-content__subsubtitle">Accessing the Tests Section</h3>
    <p>To access the <strong>Tests</strong> section:</p>
    <ol className="cc-doc-content__list">
      <li>From the dashboard, click on the <strong>"Tests"</strong> option in the left-side navigation panel.</li>
      <li>You will see a list of tests along with key information such as:
        <ul>
          <li><strong>Test ID</strong>: A unique identifier for each test.</li>
          <li><strong>Title</strong>: The name of the test.</li>
          <li><strong>Description</strong>: A brief summary of the test's content or purpose.</li>
        </ul>
      </li>
    </ol>
    <p>There are also <strong>action icons</strong> associated with each test, providing functionalities like editing, managing participants, viewing, and deleting tests.</p>

    <h3 className="cc-doc-content__subsubtitle">1. Creating a New Test</h3>
    <p>To create a new test:</p>
    <ol className="cc-doc-content__list">
      <li>Click on the <strong>"+ Create New Test"</strong> button located at the top of the page.</li>
      <img src="/Documentation/create-test.png" alt="Create New Test" className="cc-doc-content__image" />
      <li>You will be prompted to enter the following details:
        <ul>
          <li><strong>Test Title</strong>: A descriptive title for the test.</li>
          <li><strong>Test Description</strong>: A brief summary of the test, outlining its objectives or focus.</li>
          <li><strong>Duration</strong>: Specify the time limit for the test.</li>
        </ul>
      </li>
      <li>After providing the necessary details, click <strong>Create Test</strong> to create the test.</li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">2. Editing an Existing Test</h3>
    
    <p>If you need to make changes to an existing test:</p>
    <ol className="cc-doc-content__list">
      <li>Locate the test you want to edit from the test list.</li>
      <li>Click on the <strong>Edit Icon</strong> (✏️) under the <strong>Actions</strong> column next to the test.</li>
      <li>You can update the test's title, description, duration, and any other configurations.</li>
      <li>Save the changes to update the test in real-time.</li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">3. Managing Participants for a Test</h3>
    
    <p>To add or manage participants for a particular test:</p>
    <ol className="cc-doc-content__list">
      <li>Click on the <strong>Manage Participants Icon</strong> (👥) in the <strong>Actions</strong> column for the desired test.</li>
      <li>You will be able to:
        <ul>
          <li><strong>Add Participants</strong>: Add individual participants manually or upload a bulk list via CSV.</li>
        </ul>
      </li>
      <li>Once added, participants will receive invitations or access to take the test.</li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">4. Viewing Test Details</h3>
    <p>To preview or review the details of a test:</p>
    <ol className="cc-doc-content__list">
      <li>Click on the <strong>View Icon</strong> (👁️) under the <strong>Actions</strong> column next to the test you wish to view.</li>
      <li>You will be able to see the test structure, including the questions and configurations.</li>
      <li>This feature is helpful for reviewing the test before making it live for candidates.</li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">5. Deleting a Test</h3>
    <p>If you no longer need a test and wish to remove it:</p>
    <ol className="cc-doc-content__list">
      <li>Click on the <strong>Delete Icon</strong> (🗑️) in the <strong>Actions</strong> column next to the test.</li>
    </ol>

    <h1 className="cc-doc-content__title">SECTION 3: MANAGING QUESTIONS</h1>
    <p>The <strong>Questions</strong> section in the <strong>Organization Dashboard</strong> allows organizations to create, manage, and organize the questions that will be included in tests. Organizations have the flexibility to create individual questions, add bulk questions through CSV upload, and fully manage test cases and points for each question. Below is a guide on how to use the <strong>Questions</strong> section effectively.</p>

    <img src="/Documentation/Questions.png" alt="Questions Section" className="cc-doc-content__image" />

    <h3 className="cc-doc-content__subsubtitle">Accessing the Questions Section</h3>
    <p>From the dashboard, click on the <strong>"Questions"</strong> option in the left-side navigation panel.</p>
    <p>The page displays the following features:</p>
    <ul className="cc-doc-content__list">
      <li><strong>Create Question</strong>: For adding a single question manually.</li>
      <li><strong>Create Bulk Questions</strong>: For uploading multiple questions using a CSV file.</li>
    </ul>
    <p>Additionally, you'll see a list of existing questions with the following columns:</p>
    <ul className="cc-doc-content__list">
      <li><strong>Title</strong>: The question title.</li>
      <li><strong>Description</strong>: A brief description of the question.</li>
      <li><strong>Actions</strong>: Icons to <strong>view</strong>, <strong>edit</strong>, and <strong>delete</strong> each question.</li>
    </ul>

    <h3 className="cc-doc-content__subsubtitle">1. Creating a Single Question</h3>
    <p>To create an individual question:</p>
    <ol className="cc-doc-content__list">
      <li>Click on the <strong>"Create Question"</strong> button at the top of the page.</li>
      <li>You will be prompted to enter:
        <ul>
          <li><strong>Question Title</strong>: A concise and descriptive title for the question.</li>
          <li><strong>Question Description</strong>: A detailed explanation or problem statement for the candidate to understand the task.</li>
        </ul>
      </li>
      <li>After entering the basic question details, you'll be directed to the <strong>Test Cases</strong> section:
        <ul>
          <li><strong>Input Test Case</strong>: Provide the input for the test case that the candidate's code will receive.</li>
          <li><strong>Output Test Case</strong>: Define the expected output for the corresponding input.</li>
          <li>You can add multiple test cases to ensure thorough testing of candidate solutions.</li>
        </ul>
      </li>
      <li>Finally, assign <strong>Points</strong> to the question. These points will determine the score candidates receive when they solve the question correctly.</li>
      <li>Once all fields are filled, save the question, and it will be added to your question bank.</li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">2. Creating Bulk Questions</h3>
    <p>If you need to add a large number of questions quickly:</p>
    <ol className="cc-doc-content__list">
      <li>Click on the <strong>"Create Bulk Questions"</strong> button.</li>
      <li>You will be asked to upload a <strong>CSV file</strong> containing the details of multiple questions.
        <ul>
          <li>The CSV file should have specific columns for each question's title, description, input test cases, output test cases, and points.</li>
        </ul>
      </li>
      <li>After uploading, the questions will automatically be added to the platform and can be managed just like individual questions.</li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">3. Viewing a Question</h3>
    <p>To view the details and test cases for a specific question:</p>
    <ol className="cc-doc-content__list">
      <li>Click the <strong>View Icon</strong> (👁️) under the <strong>Actions</strong> column next to the question.</li>
      <li>This will display all the question details, including the test cases and scoring information.</li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">4. Editing a Question and Test Cases</h3>
    <p>If you need to modify a question:</p>
    <ol className="cc-doc-content__list">
      <li>Click the <strong>Edit Icon</strong> (✏️) under the <strong>Actions</strong> column for the desired question.</li>
      <li>You can update the question title, description, test cases, and points as needed.</li>
      <li>After making changes, save the updated question to apply the modifications.</li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">5. Deleting a Question</h3>
    <p>To remove a question from the system:</p>
    <ol className="cc-doc-content__list">
      <li>Click the <strong>Delete Icon</strong> (🗑️) in the <strong>Actions</strong> column next to the question.</li>
      <li>Confirm the deletion when prompted.</li>
      <li>Once deleted, the question will be permanently removed and will no longer be available for tests.</li>
    </ol>

    <h1 className="cc-doc-content__title">SECTION 4: MANAGING PARTICIPANTS</h1>
    <p>The <strong>Participants</strong> section in the <strong>Organization Dashboard</strong> enables organizations to manage the candidates who will take the tests. Organizations can manually add participants, bulk upload participants via CSV, view details of participants associated with tests, and communicate with participants via email. Here's a detailed guide on how to utilize the <strong>Participants</strong> section.</p>

    <img src="/Documentation/participants.png" alt="Participants Section" className="cc-doc-content__image" />

    <h3 className="cc-doc-content__subsubtitle">Accessing the Participants Section</h3>
    <ol className="cc-doc-content__list">
      <li>From the dashboard, click on the <strong>"Participants"</strong> option in the left-side navigation panel.</li>
      <li>The page displays key participant management options and test-related participant information:
        <ul>
          <li><strong>Add Participant</strong>: For manually adding an individual participant.</li>
          <li><strong>Bulk Add Participants</strong>: For adding multiple participants using a CSV file.</li>
          <li><strong>Bulk Delete Participants</strong>: For removing multiple participants via CSV.</li>
          <li>The table displays the following columns:
            <ul>
              <li><strong>Test ID</strong>: A unique identifier for the test.</li>
              <li><strong>Title</strong>: The name or title of the test.</li>
              <li><strong>No. of Participants</strong>: The total number of participants assigned to the test.</li>
              <li><strong>Show Participants</strong>: Allows you to view the list of participants for a specific test.</li>
              <li><strong>Send Email</strong>: Enables you to send an email with test information and the test link to participants.</li>
            </ul>
          </li>
        </ul>
      </li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">1. Adding a Single Participant</h3>
    <p>To add an individual participant:</p>
    <ol className="cc-doc-content__list">
      <li>Click on the <strong>"Add Participant"</strong> button at the top of the page.</li>
      <li>A form will appear where you need to enter the following details:
        <ul>
          <li><strong>First Name</strong>: The first name of the participant.</li>
          <li><strong>Last Name</strong>: The last name of the participant.</li>
          <li><strong>Email</strong>: The email address where the participant will receive test notifications and links.</li>
        </ul>
      </li>
      <li>After filling in the required fields, save the participant. They will be added to the organization's list of participants.</li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">2. Bulk Adding Participants</h3>
    <p>To add multiple participants in one go:</p>
    <ol className="cc-doc-content__list">
      <li>Click on the <strong>"Bulk Add Participants"</strong> button.</li>
      <li>You will be prompted to upload a <strong>CSV file</strong> containing participant details.
        <ul>
          <li>The CSV file should include columns for first name, last name, and email address.</li>
        </ul>
      </li>
      <li>After uploading, the participants will be added to the system and associated with relevant tests.</li>
      <li>Once uploaded, these participants will be available for selection and management like any other manually added participant.</li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">3. Bulk Deleting Participants</h3>
    <p>If you need to remove several participants simultaneously:</p>
    <ol className="cc-doc-content__list">
      <li>Click on the <strong>"Bulk Delete Participants"</strong> button.</li>
      <li>You will be asked to upload a <strong>CSV file</strong> containing the email addresses of participants to be removed.</li>
      <li>Once the CSV is processed, the participants listed in the file will be deleted from the system and will no longer be associated with any tests.</li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">4. Viewing Participants for a Test</h3>
    <p>To view the list of participants assigned to a specific test:</p>
    <ol className="cc-doc-content__list">
      <li>In the <strong>Participants Table</strong>, locate the test for which you want to view participants.</li>
      <li>Click the <strong>"Show Participants"</strong> button under the <strong>Show Participants</strong> column.</li>
      <li>A modal will appear displaying all the participants associated with that test, including their names and email addresses.</li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">5. Sending Emails to Participants</h3>
    <p>To notify participants of test details or send a test link:</p>
    <ol className="cc-doc-content__list">
      <li>In the <strong>Participants Table</strong>, find the test you want to notify participants about.</li>
      <li>Click the <strong>"Send Email"</strong> button under the <strong>Send Email</strong> column.</li>
      <li>This action will send an email to all participants assigned to that test, containing:
        <ul>
          <li>Information about the test.</li>
          <li>A link to the test platform so they can access the test directly.</li>
        </ul>
      </li>
    </ol>

    <h1 className="cc-doc-content__title">Section 5: MANAGING SCORECARD</h1>
    <p>The <strong>Scorecard</strong> feature in the <strong>Organization Dashboard</strong> is designed to provide an easy way to track and view the performance of participants across different tests. It allows organizations to access the results and scores of participants for each test conducted. Here's a detailed breakdown of how to use the <strong>Scorecard</strong> section.</p>

    <img src="/Documentation/scorecard.png" alt="Scorecard Section" className="cc-doc-content__image" />

    <h3 className="cc-doc-content__subsubtitle">Accessing the Scorecard Section</h3>
    <ol className="cc-doc-content__list">
      <li>From the dashboard, click on the <strong>"Scorecard"</strong> option in the left-side navigation panel.</li>
      <li>You will be taken to a page displaying a table with the following columns:
        <ul>
          <li><strong>Test ID</strong>: The unique identifier assigned to the test.</li>
          <li><strong>Title</strong>: The name of the test.</li>
          <li><strong>No. of Participants</strong>: The total number of participants who took the test.</li>
          <li><strong>View Score</strong>: A button to view the detailed scores of participants for that specific test.</li>
        </ul>
      </li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">1. Viewing Scores for a Test</h3>
    <p>To view the detailed results of participants:</p>
    <ol className="cc-doc-content__list">
      <li>In the <strong>Scorecard Table</strong>, locate the test for which you want to view participant scores.</li>
      <li>Click the <strong>"View Score"</strong> button under the <strong>View Score</strong> column.</li>
      <li>Upon clicking, you will be redirected to a new page or modal that shows the actual marks of each participant who took the test.
        <ul>
          <li>This view will include the participant's name, email, and the total score they received for the test.</li>
          <li>You may also see detailed breakdowns of performance based on the points awarded for each question, depending on the test setup.</li>
        </ul>
      </li>
    </ol>

    <h3 className="cc-doc-content__subsubtitle">2. Score Insights and Test Analysis</h3>
    <p>The <strong>Scorecard</strong> provides valuable insights for analyzing the performance of participants:</p>
    <ul className="cc-doc-content__list">
      <li><strong>Participant Overview</strong>: Easily view the total number of participants who have completed each test, giving a quick snapshot of test engagement.</li>
      <li><strong>Performance Evaluation</strong>: Organizations can track the performance of participants in individual tests, helping to identify strengths and areas for improvement.</li>
      <li><strong>Easy Access</strong>: All test results are centrally available, making it convenient for organizations to evaluate participant performance and make data-driven decisions based on test scores.</li>
    </ul>

    <h1 className="cc-doc-content__title">Conclusion</h1>
    <p>The Organization Dashboard in CirrusCypher offers a comprehensive suite of features to efficiently manage coding tests, participants, and performance results. It enables organizations to create, edit, and manage tests and questions, with options for bulk actions to streamline operations. The dashboard also allows adding and managing participants, tracking test progress, and reviewing detailed scorecards for performance evaluation. With user-friendly navigation and a centralized overview of key metrics, organizations can optimize their testing processes and gain valuable insights into participant performance, ensuring an efficient and effective assessment experience.</p>
  </div>
);

export const OrgDashboard = () => {
    return renderOrgDashboard();
  };
