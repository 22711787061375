// utils/notification.js
import { toast } from 'react-toastify'; // Example: react-toastify

export const showNotification = (type, message) => {
    switch(type) {
        case 'success':
            toast.success(message);
            break;
        case 'error':
            toast.error(message);
            break;
        case 'info':
            toast.info(message);
            break;
        case 'warning':
            toast.warn(message);
            break;
        default:
            toast(message); // Default toast
    }
};
