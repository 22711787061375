import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Container,
  Pagination,
  Box
} from '@mui/material';
import blogs from './AllBlogs';
import Navbar from '../Navbar';
import "../../css/BlogsMain.css";

const BlogsMain = () => {
  const [page, setPage] = useState(1);
  const blogsPerPage = 9;
  const totalPages = Math.ceil(blogs.length / blogsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const mostRecentBlog = blogs[0];

  const displayedBlogs = blogs.slice(1).slice((page - 1) * blogsPerPage, page * blogsPerPage);

  return (
    <>
      <Navbar />
      <Container className="blogs-container">
        {/* Hero Section for Most Recent Blog */}
        <Box className="blog-hero-section" mb={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" component="h1" gutterBottom>
                {mostRecentBlog.title}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                {mostRecentBlog.date}
              </Typography>
              <Typography variant="body1" paragraph>
                {mostRecentBlog.summary}
              </Typography>
              <Button
                component={Link}
                to={`/blog/${encodeURIComponent(mostRecentBlog.title.replace(/ /g, '-'))}`}
                variant="contained"
                color="primary"
              >
                Read Full Article
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <CardMedia
                component="img"
                height="300"
                image={mostRecentBlog.images[0]}
                alt={mostRecentBlog.title}
                sx={{ objectFit: 'cover', borderRadius: '8px' }}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Regular Blog Card Structure */}
        <Typography variant="h5" component="h2" gutterBottom>
          All Blogs
        </Typography>
        <Grid container spacing={4}>
          {displayedBlogs.map((blog, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card className="blog-card">
                <CardMedia
                  component="img"
                  height="200"
                  image={blog.images[0]}
                  alt={blog.title}
                  className="blog-image"
                />
                <CardContent>
                  <Typography variant="h5" component="div" className="blog-title">
                    {blog.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" className="blog-date">
                    {blog.date}
                  </Typography>
                  <Typography variant="body2" className="blog-summary">
                    {blog.summary}
                  </Typography>
                  <Button
                    component={Link}
                    to={`/blog/${encodeURIComponent(blog.title.replace(/ /g, '-'))}`}
                    variant="outlined"
                    className="read-more-btn"
                  >
                    Read More
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          className="pagination"
        />
      </Container>
    </>
  );
}

export default BlogsMain;
