import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const BackBtnModal = () => {
  const navigate = useNavigate();

  const [showBackBtnModal, setShowBackBtnModal] = useState(false);

  useEffect(() => {
    const handleBackButtonEvent = (event) => {
      event.preventDefault();
      setShowBackBtnModal(true);
      window.history.pushState(null, "", window.location.pathname); // Keep the current state in the history stack
    };

    window.history.pushState(null, "", window.location.pathname); // Initial state
    window.addEventListener("popstate", handleBackButtonEvent);

    return () => {
      window.removeEventListener("popstate", handleBackButtonEvent);
    };
  }, []);

  const handleConfirmation = () => {
    setShowBackBtnModal(false);
    navigate("/thank-you");
  };

  const cancelClick = () => {
    setShowBackBtnModal(false);
  };

  return (
    showBackBtnModal && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
        <div className="bg-white p-6 rounded shadow-lg">
          <h2 className="text-xl font-semibold mb-4">
            If you go back your test will auto submit
          </h2>

          <button
            onClick={handleConfirmation}
            className="bg-green-500 text-white py-2 px-4 rounded mr-2"
          >
            Yes
          </button>
          <button
            onClick={cancelClick}
            className="bg-red-500 text-white py-2 px-4 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    )
  );
};

export default BackBtnModal;
