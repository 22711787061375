import React from "react";
import Navbar from "../components/Navbar";

import Feature from "../components/Feature";


const LoginPage = () => {
    return (
        <div style={{height:'100vh'}}>
      <Navbar />
     <Feature/>
      
   
      {/* Add other sections/components here */}
    </div>
  );
};

export default LoginPage;