import React, { useState, useEffect } from "react";
import "../../css/Testimonials.css";

const testimonialsData = [
  {
    id: 1,
    message:
      "CirrusCypher has revolutionized our recruitment process. The ability to create customized coding tests and manage participants effortlessly through the organization dashboard is a game-changer.",
    company: "TechHire Solutions",
    image: "man-1.jpg",
  },
  {
    id: 2,
    message:
      "The diversity of coding languages and themes for the exam landing page makes CirrusCypher stand out. It has provided our candidates with an engaging and seamless test-taking experience.",
    company: "InnoTech Corp",
    image: "woman-1.jpg",
  },
  {
    id: 3,
    message:
      "Our company experienced a significant improvement in evaluating candidates with CirrusCypher’s efficient exam timer and result-oriented test case checking. The support and expertise from the team were exceptional.",
    company: "FutureGen Enterprises",
    image: "woman-2.jpg",
  },
  {
    id: 4,
    message:
      "CirrusCypher’s platform is incredibly user-friendly. The admin dashboard allows us to monitor organization activities, subscription plans, and participant data effortlessly.",
    company: "HireRight Inc.",
    image: "man-2.jpg",
  },
  {
    id: 5,
    message:
      "As a participant, I found CirrusCypher’s test interface very intuitive. The clear instructions and easy navigation made the test-taking process stress-free.",
    company: "CodeQuest Solutions",
    image: "woman-3.jpg",
  },
  {
    id: 6,
    message:
      "CirrusCypher has been a crucial tool in our hiring process. The detailed insights and test management features provided through the organization user dashboard are invaluable.",
    company: "TalentHub",
    image: "man-3.jpg",
  },
  {
    id: 7,
    message:
      "Thanks to CirrusCypher, our organization can now conduct multiple coding tests efficiently. The platform’s affordability and range of features make it an excellent choice for any company.",
    company: "BrightFuture Tech",
    image: "woman-4.jpg",
  },
  {
    id: 8,
    message:
      "The admin functionality of CirrusCypher has been very effective. It allows us to manage multiple organizations and track their activities easily, enhancing our overall operational efficiency.",
    company: "GlobalRecruit",
    image: "man-4.jpg",
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonialsData.length);
    }, 5000); // Change testimonial every 5 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="testimonials-container">
      <div className="slider">
          <div
            key={testimonialsData[currentIndex].id}
            className={`testimonial`}
          >
            <img
              src={testimonialsData[currentIndex].image} // Use image path from data
              alt={`Client ${testimonialsData[currentIndex].id}`}
              className="client-image"
            />
            <p className="message">"{testimonialsData[currentIndex].message}"</p>
            <p className="name"> {testimonialsData[currentIndex].company}</p>
          </div>
      </div>
    </div>
  );
};

export default Testimonials;
