// src/components/Preparation/ComprehensivePreparationDashboard.js

import React, { useState, useEffect } from 'react';
import Navbar from '../Navbar';
import {
  Search,
  BookMarked,
  TrendingUp,
  Clock,
  AlertCircle,
  CheckCircle2,
  Filter,
} from 'lucide-react';
import BookmarkModal from './BookmarkModal'; // Adjust the path as necessary
import ProgressModal from './ProgressModal'; // Adjust the path as necessary
import SolutionModal from './SolutionModal'; // Import the SolutionModal component

const companies = [
  {
    id: 1,
    name: 'Amazon',
    logo: '/logos/amazon.png',
    type: 'Product Based',
    categories: ['DSA', 'System Design', 'Aptitude', 'Core CS', 'Machine Coding'],
    content: {
      DSA: [
        {
          title: 'Two Sum',
          difficulty: 'Easy',
          frequency: 'Very High',
          description:
            'Given an array of integers nums and an integer target, return indices of the two numbers that add up to target.',
          approach: 'Use HashMap to store complements. One-pass solution possible.',
          timeComplexity: 'O(n)',
          spaceComplexity: 'O(n)',
          sampleInput: 'nums = [2,7,11,15], target = 9',
          sampleOutput: '[0,1]',
          followUp: 'Can you do it in one pass?',
          solution: 'Yes, using a single pass with a HashMap to store the complement of each number.',
        },
        {
          title: 'LRU Cache',
          difficulty: 'Medium',
          frequency: 'High',
          description:
            'Design a data structure that follows the constraints of a Least Recently Used (LRU) cache.',
          approach: 'Use HashMap + Doubly Linked List for O(1) operations.',
          timeComplexity: 'O(1) for both get and put.',
          spaceComplexity: 'O(capacity)',
          additionalNotes: 'Popular system design component',
          solution: 'Implement using a combination of HashMap for fast access and a Doubly Linked List to track usage order.',
        },
        // Add more DSA questions here...
      ],
      'System Design': [
        {
          title: 'Design Amazon Prime Video',
          difficulty: 'Hard',
          frequency: 'High',
          description: 'Design a video streaming service like Amazon Prime Video.',
          keyPoints: [
            'Video transcoding pipeline',
            'CDN implementation',
            'Payment processing',
            'Recommendation system',
          ],
          systemComponents: [
            'Load Balancers',
            'Cache layers',
            'Database sharding',
            'Microservices architecture',
          ],
          solution:
            'Break down the system into microservices, utilize CDN for content delivery, implement efficient caching, and ensure scalability through database sharding.',
        },
        // Add more system design questions here...
      ],
      Aptitude: [
        {
          title: 'Time and Work',
          type: 'Quantitative',
          frequency: 'High',
          sampleQuestions: [
            {
              question:
                'If 8 machines can complete a work in 10 days, how many machines will be needed to complete the same work in 5 days?',
              solution: 'Using inverse proportion: (8 × 10) ÷ 5 = 16 machines',
            },
          ],
        },
        {
          title: 'Profit and Loss',
          type: 'Quantitative',
          frequency: 'Medium',
          sampleQuestions: [
            {
              question:
                'A trader buys an item for $50 and sells it for $60. What is the percentage profit?',
              solution: 'Profit = (Selling Price - Cost Price) = $10, Profit % = (10/50) * 100 = 20%',
            },
          ],
        },
        // Add more aptitude questions here...
      ],
      'Core CS': [
        {
          title: 'Operating System Concepts',
          frequency: 'Medium',
          questions: [
            {
              question: 'Explain the difference between a process and a thread.',
              answer:
                'A process is an independent program with its own memory space, while a thread is a smaller execution unit within a process that shares the same memory.',
            },
            {
              question: 'What is deadlock? Describe necessary conditions for a deadlock to occur.',
              answer:
                'Deadlock is a situation where two or more processes are unable to proceed because each is waiting for the other to release resources. The necessary conditions are mutual exclusion, hold and wait, no preemption, and circular wait.',
            },
          ],
        },
        {
          title: 'Database Management System (DBMS)',
          frequency: 'High',
          questions: [
            {
              question: 'What is normalization in databases?',
              answer:
                'Normalization is the process of organizing data in a database to reduce redundancy and improve data integrity. It involves dividing tables into smaller tables and defining relationships between them.',
            },
            {
              question: 'Explain ACID properties in the context of DBMS.',
              answer:
                'ACID stands for Atomicity, Consistency, Isolation, Durability. These are properties of database transactions that ensure data reliability.',
            },
          ],
        },
        // Add more Core CS questions here...
      ],
      'Machine Coding': [
        {
          title: 'Design a Parking Lot System',
          frequency: 'Medium',
          description:
            'Design a parking lot system with different types of vehicles and parking spots.',
          approach: 'Use Object-Oriented Design principles to create classes for ParkingLot, Spot, Vehicle, etc.',
          sampleInput: 'Park a car in the available spot.',
          sampleOutput: 'Car parked successfully with Spot ID: S1',
          solution:
            'Create classes for ParkingLot, Spot, Vehicle with attributes and methods to manage parking logic, vehicle types, and spot allocation efficiently.',
        },
        // Add more machine coding questions here...
      ],
    },
  },
  
  {
    id: 2,
    name: 'TCS',
    logo: '/logos/TCS.png',
    type: 'Service Based',
    categories: ['DSA', 'Aptitude', 'Core CS', 'HR Questions'],
    content: {
      DSA: [
        {
          title: 'Reverse Linked List',
          difficulty: 'Medium',
          frequency: 'High',
          description:
            'Reverse a singly linked list.',
          approach: 'Iterative approach by adjusting pointers.',
          timeComplexity: 'O(n)',
          spaceComplexity: 'O(1)',
          sampleInput: '1 -> 2 -> 3 -> 4 -> 5',
          sampleOutput: '5 -> 4 -> 3 -> 2 -> 1',
          followUp: 'Can you reverse it recursively?',
          solution:
            'Yes, use a recursive approach where you reverse the rest of the list and adjust the pointers accordingly.',
        },
        {
          title: 'Merge Intervals',
          difficulty: 'Medium',
          frequency: 'High',
          description:
            'Given a collection of intervals, merge all overlapping intervals.',
          approach: 'Sort intervals based on start time and merge overlapping ones.',
          timeComplexity: 'O(n log n)',
          spaceComplexity: 'O(n)',
          sampleInput: '[[1,3],[2,6],[8,10],[15,18]]',
          sampleOutput: '[[1,6],[8,10],[15,18]]',
          solution:
            'Sort the intervals by start time, then iterate through them, merging overlapping intervals by comparing the current interval’s end with the next interval’s start.',
        },
        // Add more DSA questions
      ],
      Aptitude: [
        {
          title: 'Probability',
          type: 'Quantitative',
          frequency: 'High',
          sampleQuestions: [
            {
              question:
                'A bag contains 5 red balls and 3 blue balls. What is the probability of drawing two red balls without replacement?',
              solution:
                'First draw: 5/8. Second draw: 4/7. Probability = (5/8) * (4/7) = 20/56 = 5/14.',
            },
          ],
        },
        // Add more aptitude questions
      ],
      'Core CS': [
        {
          title: 'Database Normalization',
          frequency: 'Medium',
          questions: [
            {
              question:
                'What are the different normal forms in database normalization?',
              answer:
                'First Normal Form (1NF), Second Normal Form (2NF), Third Normal Form (3NF), Boyce-Codd Normal Form (BCNF), Fourth Normal Form (4NF), and Fifth Normal Form (5NF).',
            },
            {
              question:
                'Explain the concept of ACID properties in databases.',
              answer:
                'ACID stands for Atomicity, Consistency, Isolation, Durability. They ensure reliable processing of database transactions.',
            },
          ],
        },
        // Add more Core CS questions
      ],
      'HR Questions': [
        {
          title: 'Tell me about yourself.',
          frequency: 'High',
          tips: 'Provide a brief summary of your background, skills, and why you are a good fit for the role.',
        },
        {
          title: 'Why do you want to work for TCS?',
          frequency: 'High',
          tips:
            'Mention specific reasons like company reputation, growth opportunities, alignment with your career goals, etc.',
        },
        // Add more HR questions
      ],
    },
  },
  {
    id: 3,
    name: 'Infosys',
    logo: '/logos/infosys.jpg',
    type: 'Service Based',
    categories: ['DSA', 'Aptitude', 'Core CS', 'HR Questions', 'Programming MCQs'],
    content: {
      DSA: [
        {
          title: 'Binary Tree Inorder Traversal',
          difficulty: 'Easy',
          frequency: 'High',
          description:
            'Given the root of a binary tree, return the inorder traversal of its nodes’ values.',
          approach: 'Use recursion or iterative approach with a stack.',
          timeComplexity: 'O(n)',
          spaceComplexity: 'O(n)',
          sampleInput: 'Root of the binary tree',
          sampleOutput: '[1,2,3]',
          followUp: 'Can you implement it without recursion?',
          solution:
            'Yes, use an iterative approach with a stack to traverse the tree without recursion.',
        },
        {
          title: 'Longest Substring Without Repeating Characters',
          difficulty: 'Medium',
          frequency: 'High',
          description:
            'Given a string, find the length of the longest substring without repeating characters.',
          approach: 'Use sliding window with a HashSet to track unique characters.',
          timeComplexity: 'O(n)',
          spaceComplexity: 'O(min(n, m))',
          sampleInput: '"abcabcbb"',
          sampleOutput: '3 ("abc")',
          solution:
            'Use two pointers to define the sliding window and a HashSet to store unique characters, updating the maximum length as you traverse the string.',
        },
        // Add more DSA questions
      ],
      Aptitude: [
        {
          title: 'Permutation and Combination',
          type: 'Quantitative',
          frequency: 'High',
          sampleQuestions: [
            {
              question:
                'How many ways can you arrange the letters in the word "INFOSYS"?',
              solution:
                'The word "INFOSYS" has 7 letters with S repeated twice. Total arrangements = 7! / 2! = 2520.',
            },
          ],
        },
        // Add more aptitude questions
      ],
      'Core CS': [
        {
          title: 'Object-Oriented Programming Concepts',
          frequency: 'Medium',
          questions: [
            {
              question:
                'What are the four pillars of Object-Oriented Programming?',
              answer:
                'Encapsulation, Abstraction, Inheritance, and Polymorphism.',
            },
            {
              question:
                'Explain the difference between interface and abstract class.',
              answer:
                'An interface can only declare methods without implementations, while an abstract class can have both abstract and implemented methods. A class can implement multiple interfaces but can inherit from only one abstract class.',
            },
          ],
        },
        // Add more Core CS questions
      ],
      'HR Questions': [
        {
          title: 'Describe a challenging project you worked on.',
          frequency: 'High',
          tips:
            'Focus on the challenges faced, how you overcame them, and what you learned from the experience.',
        },
        {
          title: 'How do you handle tight deadlines?',
          frequency: 'High',
          tips:
            'Explain your time management skills, prioritization techniques, and ability to stay calm under pressure.',
        },
        // Add more HR questions
      ],
      'Programming MCQs': [
        {
          topic: 'Java Fundamentals',
          questions: [
            {
              question:
                'What is the output of the following code?\n\n```java\nSystem.out.println(10 + 20 + "30" + 40);\n```',
              options: ['303040', '10203040', '3030', '303040'],
              answer: '303040',
              explanation:
                'Java evaluates left to right. 10 + 20 = 30, then "30" + 40 = "3040". So the output is "303040".',
            },
            {
              question:
                'Which of the following is not a primitive data type in Java?',
              options: ['int', 'Float', 'char', 'boolean'],
              answer: 'Float',
              explanation:
                'In Java, the primitive data type is "float" (lowercase), while "Float" is a wrapper class.',
            },
          ],
        },
        // Add more programming MCQs
      ],
    },
  },
  {
    id: 4,
    name: "Wipro Limited",
    logo: "/logos/wipro.png",
    type: "Service Based",
    categories: ["DSA", "Aptitude", "Core CS", "HR Questions", "Programming MCQs"],
    content: {
      DSA: [
        {
          title: "Find the Duplicate Number",
          difficulty: "Medium",
          frequency: "High",
          description:
            "Given an array nums containing n + 1 integers where each integer is between 1 and n inclusive. There is only one duplicate number, find it without modifying the array.",
          approach:
            "Use Floyd’s Tortoise and Hare (Cycle Detection) algorithm for O(n) time and O(1) space.",
          timeComplexity: "O(n)",
          spaceComplexity: "O(1)",
          sampleInput: "[1,3,4,2,2]",
          sampleOutput: "2",
          followUp: "Can you solve it using binary search?",
          solution: "Yes, by using binary search to search for the duplicate number with O(log n) complexity."
        },
        {
          title: "Word Ladder",
          difficulty: "Hard",
          frequency: "Medium",
          description:
            "Given two words (beginWord and endWord), and a dictionary’s word list, find the length of the shortest transformation sequence from beginWord to endWord.",
          approach:
            "Use BFS to traverse the word ladder graph, transforming one letter at a time.",
          timeComplexity: "O(n * m^2)",
          spaceComplexity: "O(n * m)",
          sampleInput:
            'beginWord = "hit", endWord = "cog", wordList = ["hot","dot","dog","lot","log","cog"]',
          sampleOutput: "5",
          solution:
            "Yes, using BFS traversal to build the transformation sequence, ensuring we only change one letter at a time and checking for valid words in the dictionary."
        }
        // Add more DSA questions with solutions...
      ],
      Aptitude: [
        {
          title: "Data Interpretation",
          type: "Quantitative",
          frequency: "High",
          sampleQuestions: [
            {
              question:
                "A company has sales data for 4 quarters as follows: Q1: $200k, Q2: $250k, Q3: $300k, Q4: $350k. What is the average sales per quarter?",
              solution:
                "Total sales = 200 + 250 + 300 + 350 = 1100k. Average = 1100 / 4 = 275k per quarter."
            }
          ]
        }
        // Add more aptitude questions...
      ],
      "Core CS": [
        {
          title: "Networking Basics",
          frequency: "Medium",
          questions: [
            {
              question: "What is the difference between TCP and UDP protocols?",
              answer:
                "TCP is connection-oriented, provides error checking and guarantees delivery, while UDP is connectionless, faster, and does not guarantee delivery."
            },
            {
              question: "Explain the OSI model layers.",
              answer:
                "The OSI model has seven layers: Application, Presentation, Session, Transport, Network, Data Link, and Physical."
            }
          ]
        }
        // Add more Core CS questions...
      ],
      "HR Questions": [
        {
          title: "Where do you see yourself in 5 years?",
          frequency: "High",
          tips:
            "Discuss your career aspirations, growth within the company, and how you plan to contribute to the organization."
        },
        {
          title: "Describe a time you faced a conflict while working on a team.",
          frequency: "High",
          tips:
            "Explain the situation, how you handled it, and the resolution, emphasizing your teamwork and conflict resolution skills."
        }
        // Add more HR questions...
      ],
      "Programming MCQs": [
        {
          topic: "C++ Fundamentals",
          questions: [
            {
              question:
                "What is the output of the following code?\n\n```cpp\n#include <iostream>\nusing namespace std;\nint main() {\n    int a = 5;\n    int &b = a;\n    b = 10;\n    cout << a;\n    return 0;\n}\n```",
              options: ["5", "10", "15", "Compilation Error"],
              answer: "10",
              explanation:
                "Reference b refers to variable a. Changing b to 10 changes a to 10."
            },
            {
              question:
                "Which of the following is not a valid C++ access specifier?",
              options: ["public", "private", "protected", "package"],
              answer: "package",
              explanation:
                'C++ access specifiers are public, private, and protected. "package" is not a valid specifier.'
            }
          ]
        }
        // Add more programming MCQs...
      ]
    }
},

{
  id: 5,
  name: "Tech Mahindra",
  logo: "/logos/tech-mahindra.png",
  type: "Service Based",
  categories: ["DSA", "Aptitude", "Core CS", "HR Questions", "Machine Coding"],
  content: {
    DSA: [
      {
        title: "Kadane’s Algorithm",
        difficulty: "Medium",
        frequency: "High",
        description:
          "Find the maximum sum of a contiguous subarray within a one-dimensional array of numbers.",
        approach:
          "Iterate through the array, at each step, decide whether to add the current element to the existing subarray or start a new subarray.",
        timeComplexity: "O(n)",
        spaceComplexity: "O(1)",
        sampleInput: "[-2,1,-3,4,-1,2,1,-5,4]",
        sampleOutput: "6 (subarray [4,-1,2,1])",
        solution:
          "Yes, by iterating over the array while maintaining the current subarray sum and updating the maximum sum accordingly. This ensures we track the largest subarray sum in O(n) time."
      },
      {
        title: "Top K Frequent Elements",
        difficulty: "Medium",
        frequency: "High",
        description:
          "Given a non-empty array of integers, return the k most frequent elements.",
        approach:
          "Use a hash map to count frequencies, then use a heap or bucket sort to find the top k elements.",
        timeComplexity: "O(n)",
        spaceComplexity: "O(n)",
        sampleInput: "[1,1,1,2,2,3], k = 2",
        sampleOutput: "[1,2]",
        solution:
          "Yes, by maintaining a frequency map of elements and using a max heap to extract the top k frequent elements efficiently."
      }
      // Add more DSA questions with solutions...
    ],
    Aptitude: [
      {
        title: "Percentage Increase/Decrease",
        type: "Quantitative",
        frequency: "High",
        sampleQuestions: [
          {
            question:
              "The price of a laptop increased from $800 to $1000. What is the percentage increase?",
            solution:
              "Percentage Increase = [(1000 - 800) / 800] * 100 = 25%"
          }
        ]
      }
      // Add more aptitude questions...
    ],
    "Core CS": [
      {
        title: "Concurrency in Operating Systems",
        frequency: "Medium",
        questions: [
          {
            question:
              "What is a semaphore and how is it used in process synchronization?",
            answer:
              "A semaphore is a synchronization tool that controls access by multiple processes to a common resource in a concurrent system. It uses counters to manage access and prevent race conditions."
          },
          {
            question: "Explain virtual memory and its advantages.",
            answer:
              "Virtual memory allows the execution of processes that may not be completely in memory. Advantages include efficient memory usage, isolation between processes, and the ability to run larger applications."
          }
        ]
      }
      // Add more Core CS questions...
    ],
    "HR Questions": [
      {
        title: "Why did you choose Tech Mahindra?",
        frequency: "High",
        tips:
          "Highlight specific reasons such as company reputation, projects, growth opportunities, and alignment with your career goals."
      },
      {
        title: "How do you handle tight deadlines?",
        frequency: "High",
        tips:
          "Discuss your time management skills, prioritization techniques, and ability to stay calm under pressure."
      }
      // Add more HR questions...
    ],
    "Machine Coding": [
      {
        title: "Implement a Stack using Queues",
        frequency: "Medium",
        description:
          "Implement a stack data structure using only queue operations.",
        approach:
          "Use two queues. For push operation, enqueue to one queue and dequeue all elements from the other queue into the first one to maintain LIFO order.",
        sampleInput: "push(1), push(2), push(3), pop()",
        sampleOutput: "3",
        solution:
          "By using two queues, we ensure the last element pushed to the stack is always dequeued first, simulating LIFO behavior."
      },
      {
        title: "Design a File System",
        frequency: "Medium",
        description:
          "Design a simple file system with functionalities to create, delete, and navigate through directories and files.",
        approach:
          "Use a tree structure where each node represents a directory or a file. Implement methods for file operations.",
        solution:
          "A tree-based structure allows efficient navigation and management of directories and files. Each node can either represent a file or a directory, with parent-child relationships representing the hierarchy."
      }
      // Add more machine coding questions...
    ]
  }
},

{
  id: 6,
  name: "HCL Technologies",
  logo: "/logos/HCL.png",
  type: "Service Based",
  categories: ["DSA", "Aptitude", "Core CS", "HR Questions", "Programming MCQs"],
  content: {
    DSA: [
      {
        title: "Depth-First Search (DFS)",
        difficulty: "Medium",
        frequency: "High",
        description: "Implement Depth-First Search for a given graph.",
        approach:
          "Use recursion or a stack to traverse the graph depth-wise, exploring as far as possible along each branch before backtracking.",
        timeComplexity: "O(V + E)",
        spaceComplexity: "O(V)",
        sampleInput: "Adjacency list representation of a graph",
        sampleOutput: "DFS traversal order",
        solution:
          "Using either recursion or an explicit stack, DFS explores each vertex once. The key is to mark nodes as visited to prevent revisiting, achieving traversal in O(V + E) time."
      },
      {
        title: "Breadth-First Search (BFS)",
        difficulty: "Medium",
        frequency: "High",
        description: "Implement Breadth-First Search for a given graph.",
        approach:
          "Use a queue to explore the graph level by level, visiting neighbors of each vertex before moving on to the next level.",
        timeComplexity: "O(V + E)",
        spaceComplexity: "O(V)",
        sampleInput: "Adjacency list representation of a graph",
        sampleOutput: "BFS traversal order",
        solution:
          "By using a queue, BFS ensures that the closest nodes to the source are processed first. This level-order exploration leads to a traversal in O(V + E) time."
      }
      // Add more DSA questions with solutions
    ],
    Aptitude: [
      {
        title: "Ratio and Proportion",
        type: "Quantitative",
        frequency: "High",
        sampleQuestions: [
          {
            question:
              "If the ratio of the ages of A and B is 3:4 and the sum of their ages is 35 years, find the ages of A and B.",
            solution:
              "Let the ages be 3x and 4x. Solving the equation 3x + 4x = 35 → x = 5, gives A = 15 years and B = 20 years."
          }
        ]
      }
      // Add more aptitude questions
    ],
    "Core CS": [
      {
        title: "Data Structures - Trees",
        frequency: "Medium",
        questions: [
          {
            question:
              "What is a Binary Search Tree and its properties?",
            answer:
              "A Binary Search Tree (BST) is a binary tree in which each node has a key, and the keys in the left subtree are smaller, while the keys in the right subtree are larger than the node’s key. This property ensures efficient search, insertion, and deletion."
          },
          {
            question:
              "Explain the difference between a binary tree and a binary search tree.",
            answer:
              "A binary tree is a tree data structure where each node has up to two children. A binary search tree (BST), however, imposes a specific order on its nodes, where the left child is smaller and the right child is greater than the parent node."
          }
        ]
      }
      // Add more Core CS questions
    ],
    "HR Questions": [
      {
        title: "Describe a situation where you demonstrated leadership.",
        frequency: "High",
        tips:
          "Provide a concrete example where you showed leadership by managing a team, resolving conflicts, or making critical decisions that positively impacted a project."
      },
      {
        title: "How do you prioritize your tasks?",
        frequency: "High",
        tips:
          "Explain your strategy for prioritization, emphasizing tools like task lists, deadlines, and focusing on high-value tasks. Highlight any methods that enhance your productivity under tight deadlines."
      }
      // Add more HR questions
    ],
    "Programming MCQs": [
      {
        topic: "Python Fundamentals",
        questions: [
          {
            question:
              "What is the output of the following code?\n\n```python\ndef func(a, b=[]):\n    b.append(a)\n    return b\nprint(func(1))\nprint(func(2))\n```",
            options: ["[1]\n[2]", "[1]\n[1,2]", "[2]\n[1,2]", "[1]\n[1]"],
            answer: "[1]\n[1,2]",
            explanation:
              "Default mutable arguments in Python retain their state across function calls. So, `b` accumulates values across calls, leading to the output `[1]` and then `[1, 2]`."
          },
          {
            question:
              "Which of the following is used to define a block of code in Python?",
            options: ["Indentation", "Braces", "Parentheses", "Keywords"],
            answer: "Indentation",
            explanation:
              "Python uses indentation to define blocks of code instead of braces or other symbols common in other languages."
          }
        ]
      }
      // Add more programming MCQs
    ]
  }
},

{
  id: 7,
  name: "Meta (Facebook)",
  logo: "/logos/Facebook.png",
  type: "Product Based",
  categories: ["DSA", "System Design", "Aptitude", "Core CS", "Machine Coding"],
  content: {
    DSA: [
      {
        title: "Merge Intervals",
        difficulty: "Medium",
        frequency: "High",
        description: "Given an array of intervals where intervals[i] = [start_i, end_i], merge all overlapping intervals, and return an array of the non-overlapping intervals that cover all the intervals in the input.",
        approach: "Sort the intervals based on the start time. Iterate and merge overlapping intervals.",
        timeComplexity: "O(n log n)",
        spaceComplexity: "O(n)",
        sampleInput: "[[1,3],[2,6],[8,10],[15,18]]",
        sampleOutput: "[[1,6],[8,10],[15,18]]",
        followUp: "Can you solve it without sorting?",
        solution: "Yes, after sorting by start time, merge intervals by comparing each interval's start with the previous interval's end. Without sorting, it's tricky since intervals may appear unordered. Sorting simplifies it to O(n log n)."
      },
      {
        title: "Longest Substring Without Repeating Characters",
        difficulty: "Medium",
        frequency: "Very High",
        description: "Given a string s, find the length of the longest substring without repeating characters.",
        approach: "Use sliding window with a HashSet to track unique characters.",
        timeComplexity: "O(n)",
        spaceComplexity: "O(min(m, n))",
        sampleInput: "\"abcabcbb\"",
        sampleOutput: "3",
        followUp: "Can you solve it using only one pass?",
        solution: "Yes, using a sliding window technique. Move the right pointer to add characters to a HashSet until a repeat is found. Then move the left pointer to shrink the window. This gives O(n) time complexity."
      }
    ],
    "System Design": [
      {
        title: "Design Facebook News Feed",
        difficulty: "Hard",
        frequency: "Very High",
        description: "Design the News Feed feature for Facebook, ensuring scalability, real-time updates, and personalized content delivery.",
        keyPoints: [
          "Content ranking algorithms",
          "Real-time data processing",
          "Caching strategies",
          "Data storage and retrieval"
        ],
        systemComponents: [
          "Load Balancers",
          "Databases (SQL & NoSQL)",
          "Microservices",
          "Message Queues"
        ],
        solution: "To handle scalability and real-time updates, Facebook News Feed uses a combination of distributed databases, caching, and machine learning algorithms for content ranking. Microservices handle independent tasks, and message queues ensure real-time notifications."
      },
      {
        title: "Design Instagram",
        difficulty: "Hard",
        frequency: "High",
        description: "Design the backend for Instagram, including photo uploads, user feeds, and real-time notifications.",
        keyPoints: [
          "Image storage and processing",
          "Feed generation and ranking",
          "User authentication and security",
          "Scalability and fault tolerance"
        ],
        systemComponents: [
          "CDN for content delivery",
          "Distributed databases",
          "Microservices architecture",
          "Real-time data streaming"
        ],
        solution: "For Instagram, images are stored and served via CDNs, ensuring low-latency access. Feed generation uses distributed databases and ranking algorithms, while real-time data streaming ensures notifications are pushed promptly."
      }
    ],
    Aptitude: [
      {
        title: "Probability",
        type: "Quantitative",
        frequency: "Medium",
        sampleQuestions: [
          {
            question: "A fair die is rolled 4 times. What is the probability of getting exactly two sixes?",
            solution: "Use binomial probability: C(4,2) * (1/6)^2 * (5/6)^2 = 6 * 1/36 * 25/36 = 150/1296 = 25/216"
          }
        ]
      },
      {
        title: "Permutation and Combination",
        type: "Quantitative",
        frequency: "High",
        sampleQuestions: [
          {
            question: "In how many ways can the letters of the word 'ENGINEERING' be arranged?",
            solution: "Total letters = 11. Repeating letters: E=3, N=3, G=2, I=2, R=1.",
            calculation: "11! / (3! * 3! * 2! * 2! * 1!) = 39916800 / (6 * 6 * 2 * 2 * 1) = 39916800 / 144 = 277200"
          }
        ]
      }
    ],
    "Core CS": [
      {
        title: "Operating Systems - Deadlocks",
        difficulty: "Medium",
        frequency: "High",
        description: "Explain the four necessary conditions for a deadlock to occur in an operating system.",
        approach: "Discuss mutual exclusion, hold and wait, no preemption, and circular wait.",
        additionalNotes: "Understanding of how deadlocks can be prevented, avoided, or detected.",
        solution: "Deadlocks require four conditions: mutual exclusion (resource lock), hold and wait (holding while waiting), no preemption (resources cannot be taken), and circular wait (processes waiting in a circular chain). Deadlocks can be avoided through careful resource allocation strategies like Banker's algorithm."
      },
      {
        title: "Database Normalization",
        difficulty: "Medium",
        frequency: "High",
        description: "Explain the different normal forms in database normalization and their significance.",
        approach: "Discuss 1NF, 2NF, 3NF, BCNF, and higher normal forms with examples.",
        additionalNotes: "Focus on eliminating redundancy and ensuring data integrity.",
        solution: "Normalization eliminates redundancy by structuring data into 1NF (no repeating groups), 2NF (removal of partial dependencies), 3NF (removal of transitive dependencies), and BCNF (handling more complex relationships). Higher normal forms further improve data integrity."
      }
    ],
    "Machine Coding": [
      {
        title: "Implement a Least Recently Used (LRU) Cache",
        difficulty: "Medium",
        frequency: "High",
        description: "Design and implement a data structure for LRU Cache. It should support the following operations: get and put.",
        approach: "Use a combination of a HashMap and a Doubly Linked List to achieve O(1) time complexity for both operations.",
        timeComplexity: "O(1) for both get and put",
        spaceComplexity: "O(capacity)",
        sampleInput: "LRUCache cache = new LRUCache(2);\ncache.put(1, 1);\ncache.put(2, 2);\ncache.get(1);\ncache.put(3, 3);\ncache.get(2);\ncache.put(4, 4);\ncache.get(1);\ncache.get(3);\ncache.get(4);",
        sampleOutput: "[1, -1, -1, 3, 4]",
        followUp: "Can you modify your implementation to handle thread-safety?",
        solution: "The LRU cache can be implemented with a HashMap to store keys and values, and a Doubly Linked List to keep track of the order of usage. For thread-safety, locks can be added around the get and put methods to prevent race conditions."
      }
    ]
  }
}

  // You can add more companies similarly
  // ...
];
export default function ComprehensivePreparationDashboard() {
  // Initialize selectedCompany with activeCategory
  const [selectedCompany, setSelectedCompany] = useState(() => {
    const firstCompany = companies[0];
    return { ...firstCompany, activeCategory: firstCompany.categories[0] };
  });

  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState('all'); // 'all', 'product', 'service'

  // Initialize bookmarkedQuestions from localStorage
  const [bookmarkedQuestions, setBookmarkedQuestions] = useState(() => {
    const saved = localStorage.getItem('bookmarkedQuestions');
    return saved ? new Set(JSON.parse(saved)) : new Set();
  });

  // Initialize solvedQuestions from localStorage
  const [solvedQuestions, setSolvedQuestions] = useState(() => {
    const saved = localStorage.getItem('solvedQuestions');
    return saved ? new Set(JSON.parse(saved)) : new Set();
  });

  // Persist bookmarkedQuestions to localStorage
  useEffect(() => {
    localStorage.setItem(
      'bookmarkedQuestions',
      JSON.stringify(Array.from(bookmarkedQuestions))
    );
  }, [bookmarkedQuestions]);

  // Persist solvedQuestions to localStorage
  useEffect(() => {
    localStorage.setItem(
      'solvedQuestions',
      JSON.stringify(Array.from(solvedQuestions))
    );
  }, [solvedQuestions]);

  // Filter companies based on search and filter criteria
  const filteredCompanies = companies.filter((company) => {
    const matchesSearch = company.name
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const matchesFilter =
      filter === 'all' ||
      (filter === 'product' && company.type === 'Product Based') ||
      (filter === 'service' && company.type === 'Service Based');
    return matchesSearch && matchesFilter;
  });

  const [isBookmarksModalOpen, setIsBookmarksModalOpen] = useState(false);
  const [isProgressModalOpen, setIsProgressModalOpen] = useState(false);
  const [bookmarkSearch, setBookmarkSearch] = useState('');
  const [isSolutionModalOpen, setIsSolutionModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);


  const getDifficultyColor = (difficulty) => {
    switch (difficulty?.toLowerCase()) {
      case 'easy':
        return 'bg-green-100 text-green-800';
      case 'medium':
        return 'bg-yellow-100 text-yellow-800';
      case 'hard':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-blue-100 text-blue-800';
    }
  };

  const toggleBookmark = (questionId) => {
    setBookmarkedQuestions((prev) => {
      const newBookmarks = new Set(prev);
      if (newBookmarks.has(questionId)) {
        newBookmarks.delete(questionId);
      } else {
        newBookmarks.add(questionId);
      }
      return newBookmarks;
    });
  };

  const toggleSolved = (questionTitle) => {
    setSolvedQuestions((prev) => {
      const newSolved = new Set(prev);
      if (newSolved.has(questionTitle)) {
        newSolved.delete(questionTitle);
      } else {
        newSolved.add(questionTitle);
      }
      return newSolved;
    });
  };

  const calculateProgress = () => {
    let total = 0;
    let solved = 0;
    let easy = 0;
    let medium = 0;
    let hard = 0;
    let easySolved = 0;
    let mediumSolved = 0;
    let hardSolved = 0;

    selectedCompany.content &&
      Object.values(selectedCompany.content).forEach((category) => {
        category.forEach((question) => {
          total += 1;
          const difficulty = question.difficulty?.toLowerCase();
          if (difficulty === 'easy') easy += 1;
          if (difficulty === 'medium') medium += 1;
          if (difficulty === 'hard') hard += 1;

          if (solvedQuestions.has(question.title)) {
            solved += 1;
            if (difficulty === 'easy') easySolved += 1;
            if (difficulty === 'medium') mediumSolved += 1;
            if (difficulty === 'hard') hardSolved += 1;
          }
        });
      });

      return {
        total,
        solved,
        easy,
        medium,
        hard,
        easySolved,
        mediumSolved,
        hardSolved,
      };
    };
  
    const progress = calculateProgress();
    
     // Function to handle opening the solution modal
  const handleViewSolution = (question) => {
    setSelectedQuestion(question);
    setIsSolutionModalOpen(true);
  };
  
  return (
    <>
      {/* <Navbar/> */}
      <div className="flex h-screen bg-gray-50">
        {/* Sidebar */}
        <div className="w-72 bg-white border-r border-gray-200 flex flex-col">
          <div className="p-4 border-b space-y-4">
            {/* Search */}
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
              <input
                type="text"
                placeholder="Search companies..."
                className="w-full pl-8 pr-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            {/* Filter Buttons */}
            <div className="flex space-x-2">
              <button
                onClick={() => setFilter('all')}
                className={`px-3 py-1 rounded-md text-sm ${
                  filter === 'all'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700'
                }`}
              >
                All
              </button>
              <button
                onClick={() => setFilter('product')}
                className={`px-3 py-1 rounded-md text-sm ${
                  filter === 'product'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700'
                }`}
              >
                Product
              </button>
              <button
                onClick={() => setFilter('service')}
                className={`px-3 py-1 rounded-md text-sm ${
                  filter === 'service'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-200 text-gray-700'
                }`}
              >
                Service
              </button>
            </div>
          </div>
          {/* Company List */}
          <div className="flex-1 overflow-y-auto">
            <div className="p-2">
              {filteredCompanies.map((company) => (
                <button
                  key={company.id}
                  onClick={() => {
                    setSelectedCompany({
                      ...company,
                      activeCategory: company.categories[0],
                    });
                  }}
                  className={`w-full p-4 flex items-center space-x-3 rounded-lg transition-colors ${
                    selectedCompany.id === company.id
                      ? 'bg-blue-50 text-blue-700'
                      : 'hover:bg-gray-50'
                  }`}
                >
                  <img
                    src={company.logo}
                    alt={`${company.name} logo`}
                    className="w-10 h-10 rounded"
                  />
                  <div className="text-left">
                    <div className="font-medium">{company.name}</div>
                    <div className="text-sm text-gray-500">{company.type}</div>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-auto">
          <div className="p-8">
            {/* Header */}
            <div className="mb-6">
              <div className="flex justify-between items-start">
                <div>
                  <h1 className="text-3xl font-bold">
                    {selectedCompany.name}
                  </h1>
                  <p className="text-gray-600 mt-2">
                    Comprehensive preparation guide for {selectedCompany.name} interviews
                  </p>
                </div>
                <div className="flex space-x-4">
                  <button
                    onClick={() => setIsBookmarksModalOpen(true)}
                    className="flex items-center space-x-2 px-4 py-2 border rounded-md text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <BookMarked className="h-4 w-4" />
                    <span>Bookmarks</span>
                  </button>
                  <button
                    onClick={() => setIsProgressModalOpen(true)}
                    className="flex items-center space-x-2 px-4 py-2 border rounded-md text-sm text-gray-700 hover:bg-gray-100"
                  >
                    <TrendingUp className="h-4 w-4" />
                    <span>Progress</span>
                  </button>
                </div>
              </div>

              {/* Progress Overview */}
              <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
                {/* Questions Solved */}
                <div className="bg-white p-6 rounded-lg shadow flex items-center space-x-4">
                  <div className="p-3 bg-blue-100 rounded-full">
                    <TrendingUp className="h-6 w-6 text-blue-600" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Questions Solved</p>
                    <div className="flex items-center space-x-2">
                      <span className="text-2xl font-semibold text-blue-700">
                        {progress.solved}/{progress.total}
                      </span>
                      <div className="w-32 bg-gray-200 rounded-full h-2">
                        <div
                          className="h-2 bg-blue-500 rounded-full transition-all duration-300"
                          style={{
                            width: `${progress.total > 0 ? (progress.solved / progress.total) * 100 : 0}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Easy */}
                <div className="bg-white p-6 rounded-lg shadow flex items-center space-x-4">
                  <div className="p-3 bg-green-100 rounded-full">
                    <CheckCircle2 className="h-6 w-6 text-green-600" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Easy</p>
                    <div className="flex items-center space-x-2">
                      <span className="text-2xl font-semibold text-green-600">
                        {progress.easySolved}/{progress.easy}
                      </span>
                      <div className="w-32 bg-gray-200 rounded-full h-2">
                        <div
                          className="h-2 bg-green-500 rounded-full transition-all duration-300"
                          style={{
                            width: `${progress.easy > 0 ? (progress.easySolved / progress.easy) * 100 : 0}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Medium */}
                <div className="bg-white p-6 rounded-lg shadow flex items-center space-x-4">
                  <div className="p-3 bg-yellow-100 rounded-full">
                    <Clock className="h-6 w-6 text-yellow-600" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Medium</p>
                    <div className="flex items-center space-x-2">
                      <span className="text-2xl font-semibold text-yellow-600">
                        {progress.mediumSolved}/{progress.medium}
                      </span>
                      <div className="w-32 bg-gray-200 rounded-full h-2">
                        <div
                          className="h-2 bg-yellow-500 rounded-full transition-all duration-300"
                          style={{
                            width: `${progress.medium > 0 ? (progress.mediumSolved / progress.medium) * 100 : 0}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Hard */}
                <div className="bg-white p-6 rounded-lg shadow flex items-center space-x-4">
                  <div className="p-3 bg-red-100 rounded-full">
                    <AlertCircle className="h-6 w-6 text-red-600" />
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Hard</p>
                    <div className="flex items-center space-x-2">
                      <span className="text-2xl font-semibold text-red-600">
                        {progress.hardSolved}/{progress.hard}
                      </span>
                      <div className="w-32 bg-gray-200 rounded-full h-2">
                        <div
                          className="h-2 bg-red-500 rounded-full transition-all duration-300"
                          style={{
                            width: `${progress.hard > 0 ? (progress.hardSolved / progress.hard) * 100 : 0}%`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Content Tabs */}
            <div className="space-y-4">
              {/* Tabs List */}
              <div className="flex space-x-2 overflow-x-auto">
                {selectedCompany.categories?.map((category) => (
                  <button
                    key={category}
                    onClick={() =>
                      setSelectedCompany((prev) => ({
                        ...prev,
                        activeCategory: category,
                      }))
                    }
                    className={`px-4 py-2 rounded-md text-sm whitespace-nowrap ${
                      selectedCompany.activeCategory === category
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    {category}
                  </button>
                ))}
              </div>

              {/* Tab Content */}
              <div className="space-y-4">
                {selectedCompany.activeCategory &&
                  selectedCompany.content?.[selectedCompany.activeCategory]?.map(
                    (item, index) => (
                      <div
                        key={index}
                        className="bg-white p-6 rounded-lg shadow hover:shadow-md transition-shadow"
                      >
                        {/* Header */}
                        <div className="flex justify-between items-start mb-4">
                          <div className="space-y-2">
                            <div className="flex items-center space-x-2">
                              <h3 className="text-lg font-semibold">
                                {item.title}
                              </h3>
                              <button
                                onClick={() => toggleBookmark(item.title)}
                                className="text-gray-400 hover:text-yellow-400 focus:outline-none"
                                aria-label="Toggle Bookmark"
                              >
                                <BookMarked
                                  className={`h-4 w-4 ${
                                    bookmarkedQuestions.has(item.title)
                                      ? 'fill-yellow-400 text-yellow-400'
                                      : ''
                                  }`}
                                />
                              </button>
                            </div>
                            {item.description && (
                              <p className="text-gray-600">{item.description}</p>
                            )}
                          </div>
                          {/* Difficulty and Frequency */}
                          <div className="flex space-x-2">
                            {item.difficulty && (
                              <span
                                className={`px-2 py-1 rounded-full text-xs font-medium ${
                                  getDifficultyColor(item.difficulty)
                                }`}
                              >
                                {item.difficulty}
                              </span>
                            )}
                            {item.frequency && (
                              <span className="px-2 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                {item.frequency} Frequency
                              </span>
                            )}
                          </div>
                        </div>

                        {/* Detailed Content */}
                        <div className="space-y-4">
                          {item.approach && (
                            <div className="bg-gray-50 p-4 rounded-lg">
                              <h4 className="font-semibold mb-2">Approach:</h4>
                              <p className="text-gray-700">{item.approach}</p>
                            </div>
                          )}

                          {(item.timeComplexity ||
                            item.spaceComplexity) && (
                            <div className="flex space-x-4">
                              {item.timeComplexity && (
                                <div className="flex-1 bg-gray-50 p-3 rounded-lg">
                                  <h4 className="font-semibold text-sm text-gray-600">
                                    Time Complexity
                                  </h4>
                                  <p className="font-mono mt-1">
                                    {item.timeComplexity}
                                  </p>
                                </div>
                              )}
                              {item.spaceComplexity && (
                                <div className="flex-1 bg-gray-50 p-3 rounded-lg">
                                  <h4 className="font-semibold text-sm text-gray-600">
                                    Space Complexity
                                  </h4>
                                  <p className="font-mono mt-1">
                                    {item.spaceComplexity}
                                  </p>
                                </div>
                              )}
                            </div>
                          )}

                          {/* Example Section */}
                          {(item.sampleInput || item.sampleOutput) && (
                            <div className="bg-gray-50 p-4 rounded-lg space-y-3">
                              <h4 className="font-semibold">Example:</h4>
                              {item.sampleInput && (
                                <div>
                                  <span className="text-sm font-medium text-gray-600">
                                    Input:
                                  </span>
                                  <code className="ml-2 p-1 bg-gray-100 rounded">
                                    {item.sampleInput}
                                  </code>
                                </div>
                              )}
                              {item.sampleOutput && (
                                <div>
                                  <span className="text-sm font-medium text-gray-600">
                                    Output:
                                  </span>
                                  <code className="ml-2 p-1 bg-gray-100 rounded">
                                    {item.sampleOutput}
                                  </code>
                                </div>
                              )}
                            </div>
                          )}

                          {/* Additional Notes */}
                          {item.additionalNotes && (
                            <div className="flex items-start space-x-2 text-gray-600">
                              <AlertCircle className="h-5 w-5 mt-0.5" />
                              <p>{item.additionalNotes}</p>
                            </div>
                          )}

                          {/* Follow-up Questions */}
                          {item.followUp && (
                            <div className="mt-4 border-t pt-4">
                              <h4 className="font-semibold mb-2">
                                Follow-up Questions:
                              </h4>
                              <p className="text-gray-700">{item.followUp}</p>
                            </div>
                          )}

                          {/* Key Points for System Design */}
                          {item.keyPoints && (
                            <div className="mt-4 space-y-2">
                              <h4 className="font-semibold">Key Points:</h4>
                              <ul className="list-disc list-inside space-y-1">
                                {item.keyPoints.map((point, idx) => (
                                  <li key={idx} className="text-gray-700">
                                    {point}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {/* For Aptitude Questions */}
                          {item.sampleQuestions && (
                            <div className="space-y-4">
                              {item.sampleQuestions.map((q, idx) => (
                                <div
                                  key={idx}
                                  className="bg-gray-50 p-4 rounded-lg"
                                >
                                  <p className="font-medium mb-2">
                                    {q.question}
                                  </p>
                                  <div className="text-gray-700">
                                    <span className="font-medium">Solution: </span>
                                    {q.solution}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}

                          {/* For Core CS Questions with multiple sub-questions */}
                          {item.questions && (
                            <div className="space-y-4">
                              {item.questions.map((q, idx) => (
                                <div
                                  key={idx}
                                  className="bg-gray-50 p-4 rounded-lg"
                                >
                                  <p className="font-medium mb-2">
                                    {q.question}
                                  </p>
                                  <div className="text-gray-700">
                                    <span className="font-medium">Answer: </span>
                                    {q.answer}
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}

                          {/* For HR Questions */}
                          {item.tips && (
                            <div className="space-y-4">
                              <p className="text-gray-700">{item.tips}</p>
                            </div>
                          )}
                        </div>

                        {/* Action Buttons */}
                        <div className="mt-6 flex space-x-2">
                          <button
                            onClick={() => toggleSolved(item.title)}
                            className={`flex items-center space-x-2 px-4 py-2 rounded-md text-sm ${
                              solvedQuestions.has(item.title)
                                ? 'bg-gray-300 text-gray-700 cursor-not-allowed'
                                : 'bg-green-500 text-white hover:bg-green-600'
                            }`}
                            disabled={solvedQuestions.has(item.title)}
                          >
                            <CheckCircle2 className="h-4 w-4" />
                            <span>
                              {solvedQuestions.has(item.title)
                                ? 'Solved'
                                : 'Mark as Solved'}
                            </span>
                          </button>
                          <button
                            onClick={() => handleViewSolution(item)}
                            className="flex items-center space-x-2 px-4 py-2 bg-blue-500 text-white rounded-md text-sm hover:bg-blue-600"
                          >
                            <CheckCircle2 className="h-4 w-4" />
                            <span>View Solution</span>
                          </button>
                          <button className="flex items-center space-x-2 px-4 py-2 border border-gray-300 rounded-md text-sm hover:bg-gray-100">
                            Practice
                          </button>
                        </div>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Bookmarks Modal */}
      <BookmarkModal
        isOpen={isBookmarksModalOpen}
        onClose={() => setIsBookmarksModalOpen(false)}
        bookmarkedQuestions={bookmarkedQuestions}
        toggleBookmark={toggleBookmark}
        bookmarkSearch={bookmarkSearch}
        setBookmarkSearch={setBookmarkSearch}
        companies={companies}
      />

      {/* Progress Modal */}
      <ProgressModal
        isOpen={isProgressModalOpen}
        onClose={() => setIsProgressModalOpen(false)}
        progress={progress}
      />

      {/* Solution Modal */}
      <SolutionModal
        isOpen={isSolutionModalOpen}
        onClose={() => setIsSolutionModalOpen(false)}
        question={selectedQuestion}
        companyName={selectedCompany.name}
      />
    </>
  );
}

  
  
  