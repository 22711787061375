import React from "react";
import axios from "axios";

const AdminBulkCreateTests = () => {
  const handleBulkCreateTest = () => {};

  const handleBulkCreateTestCSVUpload = () => {};

  const handleGoBackToAdminDashboard = () => {};
  return (
    <>
      <h1 className="text-2xl font-bold mb-4">Create Bulk Tests</h1>
      <h2 classname="text-sm font-bold mb-4">
        <p>Total Tests Availble in file: </p>
      </h2>
      <form onSubmit={handleBulkCreateTest} className="max-w-md mx-auto">
        <div className="mb-4">
          <label htmlFor="csvFile" className="block text-sm font-semibold mb-2">
            Upload CSV File
          </label>
          <input
            type="file"
            id="csvFile"
            accept=".csv"
            onChange={handleBulkCreateTestCSVUpload}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <a
            href="/sample.csv" // change this later
            download
            className="inline-block px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600"
          >
            Download Sample CSV
          </a>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
        >
          Create
        </button>
        <button
          type="button"
          onClick={handleGoBackToAdminDashboard}
          className="w-full bg-gray-300 text-gray-700 py-2 mt-2 rounded-md hover:bg-gray-400 transition duration-300"
        >
          Go Back
        </button>
      </form>
    </>
  );
};

export default AdminBulkCreateTests;
