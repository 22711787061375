// src/components/AdminDashboard/QuestionTypeSelector.js
import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const QuestionTypeSelector = ({ onSelectType }) => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Select Question Type
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSelectType('coding')}
        >
          Coding Questions
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onSelectType('mcq')}
        >
          MCQ Questions
        </Button>
      </Box>
    </Box>
  );
};

export default QuestionTypeSelector;