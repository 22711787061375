import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../css/PoliciesPage.css'; // Add your shared styles here

import Navbar from '../components/Navbar'; // Import the Navbar component
import TermsAndConditions from '../components/TermsAndConditions';
import RefundPolicy from '../components/RefundPolicy';
import CancellationPolicy from '../components/CancellationPolicy';
import ShippingPolicy from '../components/ShippingPolicy'; // New component for Shipping Policy
import PrivacyPolicy from '../components/PrivacyPolicy'; 

const PoliciesPage = () => {
  const { policy } = useParams();  // Capture the URL parameter
  const navigate = useNavigate();  // Updated to useNavigate

  // Set the activePolicy based on the URL parameter
  const renderContent = () => {
    switch (policy) {
      case 'terms':
        return <TermsAndConditions />;
      case 'refund':
        return <RefundPolicy />;
      case 'cancellation':
        return <CancellationPolicy />;
      case 'shipping':
        return <ShippingPolicy />; // Added case for Shipping Policy
        case 'privacy':
          return <PrivacyPolicy />;  // Added case for Privacy Policy
      default:
        // Redirect to a default policy if an invalid param is used
        navigate('/policy-page/terms');
        return <TermsAndConditions />;
    }
  };

  return (
    <div className="policies-page">
      <Navbar />  {/* Add Navbar here */}
      <div className="policies-container">
        <aside className="policies-sidebar">
          <ul className="sidebar-list">
            <li
              className={`sidebar-item ${policy === 'terms' ? 'active' : ''}`}
              onClick={() => navigate('/policy-page/terms')}
            >
              Terms and Conditions
            </li>
            <li
              className={`sidebar-item ${policy === 'refund' ? 'active' : ''}`}
              onClick={() => navigate('/policy-page/refund')}
            >
              Refund Policy
            </li>
            <li
              className={`sidebar-item ${policy === 'cancellation' ? 'active' : ''}`}
              onClick={() => navigate('/policy-page/cancellation')}
            >
              Cancellation Policy
            </li>
            <li
              className={`sidebar-item ${policy === 'shipping' ? 'active' : ''}`}
              onClick={() => navigate('/policy-page/shipping')}
            >
              Shipping and Delivery Policy
            </li>
            <li
              className={`sidebar-item ${policy === 'privacy' ? 'active' : ''}`}
              onClick={() => navigate('/policy-page/privacy')}
            >
              Privacy Policy {/* New entry for Privacy Policy */}
            </li>
          </ul>
        </aside>

        <main className="policies-content">
          {renderContent()}
        </main>
      </div>
    </div>
  );
};

export default PoliciesPage;
