import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import "../../css/AdminViewTest.css";

const AdminViewTest = () => {
  const [questions, setQuestions] = useState([]);
  const { testId } = useParams();  // Assuming testId is part of the URL
  const navigate = useNavigate();

  useEffect(() => {
    fetchQuestions();
  }, [testId]);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/tests/${testId}/questions`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleGoToDashboard = () => {
    navigate("/admin-dashboard");  // Redirect to the admin dashboard
  };

  return (
    <>
      <div className="AdminViewTest-container">
        <h1 className="AdminViewTest-title">Test Questions</h1>
        <table className="AdminViewTest-table">
          <thead>
            <tr>
              <th>Question ID</th>
              <th>Question Content</th>
            </tr>
          </thead>
          <tbody>
            {questions.map((question) => (
              <tr key={question.question_id}>
                <td>{question.question_id}</td>
                <td>{question.question_content}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <button onClick={handleGoToDashboard} className="admin-dashboard-button">
          Go To Dashboard
        </button>
      </div>
    </>
  );
};

export default AdminViewTest;
