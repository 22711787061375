import React from 'react';
import { Box, Button, Typography } from '@mui/material';

const TestTypeSelector = ({ onSelectType }) => {
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Select Test Type
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onSelectType('coding')}
        >
          Coding Test
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => onSelectType('mcq')}
        >
          MCQ Test
        </Button>
      </Box>
    </Box>
  );
};

export default TestTypeSelector;