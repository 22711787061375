import React from 'react';

export const AdminDashboard = () => {
  return (
    <div className="cc-doc-content">
      <h1 className="cc-doc-content__title">Introduction</h1>
      <p>Upon logging into the <strong>Admin Dashboard</strong>, admins are presented with a variety of tools and insights for managing the entire CirrusCypher ecosystem. The interface offers clear, intuitive navigation to access key functions. Below is a detailed breakdown of each section and the functionalities they provide.</p>

      <h2 className="cc-doc-content__subtitle">Getting Started</h2>
      
      <h3 className="cc-doc-content__subsubtitle">Login instructions</h3>
      <h4 className="cc-doc-content__subsubsubtitle">How to access the Admin DASHBOARD</h4>
      <ol className="cc-doc-content__list">
        <li>Select the <strong>Login</strong> from the navigation bar</li>
       
        <li>The Login Page will appear where we have to fill Admin Credentials to enter in Admin Dashboard</li>
      
        <li>After filling all the information and clicking on "Login" on login page, It will be redirecting to "Admin Dashboard"</li>
        <li>Here is the Admin Dashboard</li>
        <img src="/Documentation/Admin-dashboard.png" alt="Admin Dashboard" className="cc-doc-content__image" />
      </ol>

      <h3 className="cc-doc-content__subsubtitle">Recommended security practices for admin</h3>
      <h4 className="cc-doc-content__subsubsubtitle">Regular Password Updates</h4>
      <p>Encourage or enforce regular password changes among administrators, ideally every 3 to 6 months. Passwords should be complex, combining letters, numbers, and symbols.</p>
      
      <h4 className="cc-doc-content__subsubsubtitle">Secure Connections</h4>
      <p>Always access the Admin Dashboard via a secure, encrypted connection. Avoid using public Wi-Fi networks when managing sensitive data. If remote access is necessary, use a VPN to secure the connection.</p>
      
      <h4 className="cc-doc-content__subsubsubtitle">Education and Awareness</h4>
      <p>Regularly train administrators on the latest security practices and potential phishing scams. Keeping everyone informed about the latest security risks is critical for maintaining a secure platform.</p>

      <h2 className="cc-doc-content__subtitle">Section 1: Dashboard</h2>
      <h3 className="cc-doc-content__subsubtitle">Overview:</h3>
      
      <p>The <strong>Dashboard</strong> is the primary landing page for the admin upon logging into the CirrusCypher platform. This section provides a high-level overview of the organizations that have registered on the platform and a snapshot of the tests they have created. The table structure simplifies the process of monitoring and managing organizations.</p>

      <h4 className="cc-doc-content__subsubsubtitle">Components Displayed:</h4>
      <ul className="cc-doc-content__list">
        <li><strong>User ID</strong>: This is a unique identifier automatically assigned to each organization upon registration. It helps in tracking and managing the organization effectively.</li>
        <li><strong>Test ID</strong>: The identifier for the tests created by each organization. Every test added to the platform is assigned a distinct Test ID for tracking purposes.</li>
        <li><strong>Title</strong>: The title of the test created by the organization. This helps quickly identify the nature of the test being administered by the organization. Example: "Code-Editor Test" or "Python Basics Challenge".</li>
        <li><strong>Description</strong>: A brief description or summary of the test, providing insight into what the test is about or the skills it aims to assess. This helps differentiate between multiple tests.</li>
        <li><strong>Action (Delete)</strong>: This button allows the admin to permanently delete an organization from the CirrusCypher platform. By clicking "Delete", all the organization's data, including their tests and participants, will be removed. <strong>Important Note</strong>: Admins should use this feature with caution as deleting an organization is irreversible.</li>
      </ul>

      <h4 className="cc-doc-content__subsubsubtitle">Key Functions for Admins:</h4>
      <ul className="cc-doc-content__list">
        <li><strong>Monitoring Organizations</strong>: The table format provides a clear and concise view of all registered organizations, allowing the admin to assess their activity on the platform.</li>
        <li><strong>Removing Inactive or Problematic Organizations</strong>: The delete functionality helps in managing inactive organizations or dealing with any organizations that may be violating platform policies.</li>
        <li><strong>Test Tracking</strong>: Admins can quickly check how many tests each organization has created and manage them accordingly.</li>
      </ul>

      <h2 className="cc-doc-content__subtitle">SECTION 2: MANAGING USERS</h2>
      <h3 className="cc-doc-content__subsubtitle">Overview:</h3>
      <p>The <strong>Users</strong> section is dedicated to providing detailed information about all the organizations registered on the platform and their activity levels, particularly focusing on the number of tests they have created and the participants they've enrolled. This section gives a clearer view of how actively organizations are using the platform.</p>
      <img src="/Documentation/users.png" alt="Users Section" className="cc-doc-content__image" />

      <h3 className="cc-doc-content__subsubtitle">Components Displayed:</h3>
      <ul className="cc-doc-content__list">
        <li><strong>User ID</strong>: This is the same unique identifier as seen in the Dashboard section, linking each organization to its specific records.</li>
        <li><strong>Name</strong>: The name of the organization or the main contact person registered on the platform. This helps identify who the registered user or organization is. Example: "New Org", "Sanket", etc.</li>
        <li><strong>Number of Tests</strong>: The total count of tests created by the organization. This number is critical for understanding the engagement and activity level of the organization on the CirrusCypher platform. Example: If an organization has created five coding challenges, this number will reflect that.</li>
        <li><strong>Number of Participants</strong>: This reflects how many participants the organization has added or registered for their tests. It helps admins track how engaged the organization's participants are. Example: If an organization registers 20 participants for a test, the number "20" will be displayed.</li>
      </ul>

      <h4 className="cc-doc-content__subsubsubtitle">Key Functions for Admins:</h4>
      <ul className="cc-doc-content__list">
        <li><strong>Tracking Organizational Activity</strong>: The <strong>Number of Tests</strong> and <strong>Number of Participants</strong> columns are critical metrics for assessing how actively organizations are using the platform. High numbers in these fields may indicate an organization's engagement with the platform.</li>
        <li><strong>Understanding Usage Trends</strong>: Admins can use this section to identify trends in platform usage, such as which organizations are actively creating tests and which may need more engagement.</li>
        <li><strong>Managing Growth</strong>: This section helps admins ensure the platform is being used optimally by different organizations and offers insights into which accounts may require additional support or guidance.</li>
      </ul>

      <h2 className="cc-doc-content__subtitle">SECTION 3: MANAGING SUBSCRIPTIONS</h2>
      <h3 className="cc-doc-content__subsubtitle">Overview:</h3>
      <img src="/Documentation/Subscriptions.png" alt="Subscriptions Section" className="cc-doc-content__image" />
      <p>The <strong>Subscriptions</strong> section provides admins with an in-depth look at the subscription plans for each organization using CirrusCypher. This section is crucial for managing the financial and service-tier aspects of the platform, helping the admin keep track of the organization's subscription type, validity, and renewal needs.</p>

      <h4 className="cc-doc-content__subsubsubtitle">Components Displayed:</h4>
      <ul className="cc-doc-content__list">
        <li><strong>User ID</strong>: The same unique identifier for the organization, as seen in previous sections, providing consistency across all records.</li>
        <li><strong>Username</strong>: The username associated with the organization. This field helps identify organizations based on their login credentials or primary account name. Example: sanket6619@gmail.com.</li>
        <li><strong>Subscription Type</strong>: This indicates the plan that the organization is currently subscribed to. CirrusCypher offers different plans (e.g., Free, Basic, Premium), each with different features and levels of access. Example:
          <ul>
            <li><strong>Free</strong>: Limited access, basic features.</li>
            <li><strong>Basic</strong>: Access to core features with some advanced functionalities.</li>
            <li><strong>Premium</strong>: Full access to all platform features, including enhanced support and capabilities.</li>
          </ul>
        </li>
        <li><strong>Plan Validity</strong>: The expiration date or validity period for the organization's subscription. This lets the admin know when a particular organization's plan is due for renewal or has expired. Example: "Valid till 31st December 2024".</li>
      </ul>

      <h4 className="cc-doc-content__subsubsubtitle">Key Functions for Admins:</h4>
      <ul className="cc-doc-content__list">
        <li><strong>Subscription Management</strong>: This section allows admins to easily track which organizations are on which plan, ensuring they are receiving the correct level of service and features based on their subscription.</li>
        <li><strong>Plan Validity and Renewal</strong>: By monitoring the <strong>Plan Validity</strong>, admins can see which organizations are nearing the end of their subscription period and may need renewal reminders or plan upgrades.</li>
        <li><strong>Monitoring Free vs. Paid Usage</strong>: The <strong>Subscription Type</strong> field helps identify which organizations are using the platform for free and which are paying for premium services. This can assist in planning for platform upgrades or offering targeted promotions to free users.</li>
        <li><strong>Strategic Account Management</strong>: With insights into the type of subscription and its expiration, admins can proactively engage with organizations, offering renewal options or upsell opportunities to those on lower tiers.</li>
      </ul>

      <h2 className="cc-doc-content__subtitle">SECTION 4: MANAGING TESTS</h2>
      <p>The <strong>Tests</strong> section in the <strong>Admin Dashboard</strong> provides admin's with complete control over creating, managing, and organizing coding assessments. This section enables you to create new tests, edit existing ones, manage participants, view test details, and delete tests if necessary. Below is a detailed guide to help you navigate and utilize the features within the <strong>Tests</strong> section.</p>
     

      <h3 className="cc-doc-content__subsubtitle">Accessing the Tests Section</h3>
      <p>To access the <strong>Tests</strong> section:</p>
      <ol className="cc-doc-content__list">
        <li>From the dashboard, click on the <strong>"Tests"</strong> option in the left-side navigation panel.</li>
        <li>You will see a list of tests along with key information such as:
          <ul>
            <li><strong>Test ID</strong>: A unique identifier for each test.</li>
            <li><strong>Title</strong>: The name of the test.</li>
            <li><strong>Description</strong>: A brief summary of the test's content or purpose.</li>
          </ul>
        </li>
      </ol>
      <p>There are also <strong>action icons</strong> associated with each test, providing functionalities like editing, managing participants, viewing, and deleting tests.</p>

      <h3 className="cc-doc-content__subsubtitle">1. Creating a New Test</h3>
      <p>To create a new test:</p>
      <ol className="cc-doc-content__list">
        <li>Click on the <strong>"+ Create New Test"</strong> button located at the top of the page.</li>
       
        <li>You will be prompted to enter the following details:
          <ul>
            <li><strong>Test Title</strong>: A descriptive title for the test.</li>
            <li><strong>Test Description</strong>: A brief summary of the test, outlining its objectives or focus.</li>
          </ul>
        </li>
        <li>After providing the necessary details, click <strong>Create Test</strong> to create the test.</li>
      </ol>

      <h3 className="cc-doc-content__subsubtitle">2. Editing an Existing Test</h3>
      <p>If you need to make changes to an existing test:</p>
      <ol className="cc-doc-content__list">
        <li>Locate the test you want to edit from the test list.</li>
        <li>Click on the <strong>Edit Icon</strong> (✏️) under the <strong>Actions</strong> column next to the test.</li>
        <li>You can update the test's title, description, duration, and any other configurations.</li>
        <li>Save the changes to update the test in real-time.</li>
      </ol>
      <img src="/Documentation/admin-edit-test.png" alt="Edit Test" className="cc-doc-content__image" />

      <h3 className="cc-doc-content__subsubtitle">3. Viewing Test Details</h3>
      <p>To preview or review the details of a test:</p>
      <ol className="cc-doc-content__list">
        <li>Click on the <strong>View Icon</strong> (👁️) under the <strong>Actions</strong> column next to the test you wish to view.</li>
        <li>You will be able to see the test structure, including the questions and configurations.</li>
        <li>This feature is helpful for reviewing the test before making it live for candidates.</li>
      </ol>

      <h3 className="cc-doc-content__subsubtitle">4. Deleting a Test</h3>
      <p>If you no longer need a test and wish to remove it:</p>
      <ol className="cc-doc-content__list">
        <li>Click on the <strong>Delete Icon</strong> (🗑️) in the <strong>Actions</strong> column next to the test.</li>
      </ol>

      <h2 className="cc-doc-content__subtitle">SECTION 5: MANAGING QUESTIONS</h2>
      <p>The <strong>Questions</strong> section in the <strong>Admin Dashboard</strong> allows organizations to create, manage, and organize the questions that will be included in tests. Admins have the flexibility to create individual questions, add bulk questions through CSV upload, and fully manage test cases and points for each question. Below is a guide on how to use the <strong>Questions</strong> section effectively.</p>
      

      <h3 className="cc-doc-content__subsubtitle">Accessing the Questions Section</h3>
      <p>From the dashboard, click on the <strong>"Questions"</strong> option in the left-side navigation panel.</p>
      <p>The page displays the following features:</p>
      <ul className="cc-doc-content__list">
        <li><strong>Create Question</strong>: For adding a single question manually.</li>
        <li><strong>Create Bulk Questions</strong>: For uploading multiple questions using a CSV file.</li>
      </ul>
      <p>Additionally, you'll see a list of existing questions with the following columns:</p>
      <ul className="cc-doc-content__list">
        <li><strong>Title</strong>: The question title.</li>
        <li><strong>Description</strong>: A brief description of the question.</li>
        <li><strong>Actions</strong>: Icons to <strong>view</strong>, <strong>edit</strong>, and <strong>delete</strong> each question.</li>
      </ul>

      <h3 className="cc-doc-content__subsubtitle">1. Creating a Single Question</h3>
      <p>To create an individual question:</p>
      <ol className="cc-doc-content__list">
        <li>Click on the <strong>"Create Question"</strong> button at the top of the page.</li>
        <li>You will be prompted to enter:
          <ul>
            <li><strong>Question Title</strong>: A concise and descriptive title for the question.</li>
            <li><strong>Question Description</strong>: A detailed explanation or problem statement for the candidate to understand the task.</li>
          </ul>
        </li>
        <li>After entering the basic question details, you'll be directed to the <strong>Test Cases</strong> section:
          <ul>
            <li><strong>Input Test Case</strong>: Provide the input for the test case that the candidate's code will receive.</li>
            <li><strong>Output Test Case</strong>: Define the expected output for the corresponding input.</li>
          </ul>
        </li>
        <li>You can add multiple test cases to ensure thorough testing of candidate solutions.</li>
        <li>Finally, assign <strong>Points</strong> to the question. These points will determine the score candidates receive when they solve the question correctly.</li>
        <li>Once all fields are filled, save the question, and it will be added to your question bank.</li>
      </ol>

      <h3 className="cc-doc-content__subsubtitle">2. Creating Bulk Questions</h3>
      <p>If you need to add a large number of questions quickly:</p>
      <ol className="cc-doc-content__list">
        <li>Click on the <strong>"Create Bulk Questions"</strong> button.</li>
        <li>You will be asked to upload a <strong>CSV file</strong> containing the details of multiple questions.</li>
        <li>The CSV file should have specific columns for each question's title, description, input test cases, output test cases, and points.</li>
        <li>After uploading, the questions will automatically be added to the platform and can be managed just like individual questions.</li>
      </ol>

      <h3 className="cc-doc-content__subsubtitle">3. Viewing a Question</h3>
      <p>To view the details and test cases for a specific question:</p>
      <ol className="cc-doc-content__list">
        <li>Click the <strong>View Icon</strong> (👁️) under the <strong>Actions</strong> column next to the question.</li>
        <li>This will display all the question details, including the test cases and scoring information.</li>
      </ol>

      <h3 className="cc-doc-content__subsubtitle">4. Editing a Question and Test Cases</h3>
      <p>If you need to modify a question:</p>
      <ol className="cc-doc-content__list">
        <li>Click the <strong>Edit Icon</strong> (✏️) under the <strong>Actions</strong> column for the desired question.</li>
        <li>You can update the question title, description, test cases, and points as needed.</li>
        <li>After making changes, save the updated question to apply the modifications.</li>
      </ol>

      <h3 className="cc-doc-content__subsubtitle">5. Deleting a Question</h3>
      <p>To remove a question from the system:</p>
      <ol className="cc-doc-content__list">
        <li>Click the <strong>Delete Icon</strong> (🗑️) in the <strong>Actions</strong> column next to the question.</li>
        <li>Confirm the deletion when prompted.</li>
        <li>Once deleted, the question will be permanently removed and will no longer be available for tests.</li>
      </ol>

      <h2 className="cc-doc-content__subtitle">Conclusion</h2>
      <p>In conclusion, the <strong>Admin Dashboard</strong> plays a pivotal role in ensuring the smooth operation of the CirrusCypher platform. It gives administrators the ability to manage essential platform elements while maintaining oversight of organizational activities, user engagement, and assessment management. With these capabilities, admins can optimize the platform's functionality, enhance user experience, and ensure that CirrusCypher continues to deliver value to all users.</p>
    </div>
  );
};