import React from "react";
import { Link } from "react-router-dom";
import "../../css/HomeHeroSection.css";

const HomeHeroSection = ({ isDarkTheme }) => {

  
  return (
    <section className={`home-hero ${isDarkTheme ? "dark-theme" : ""}`}>
      <div className="hero-container">
        <div className="hero-content">
          <div className="hero-text">
            <h1 className="hero-title">Master Coding with CirrusCypher</h1>
            <p className="hero-description">
              Level up your coding skills with interactive challenges, tutorials, and real-world projects.
            </p>
            <div className="cta-buttons">
              <Link to="/get-started" className="cta-button">Get Started</Link>
              <Link to="/WhyCodeEditor" className="cta-button secondary">Learn More</Link>
            </div>
          </div>
          <div className="hero-image">
            <img src="/assets/hero-image.jpg" alt="Coding Illustration" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHeroSection;
