import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Typography,
    TextField,
    Paper,
    CircularProgress
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';

const MCQBulkQuestion = ({ onBack }) => {
    const [file, setFile] = useState(null);
    const [planLimit, setPlanLimit] = useState(0);
    const [createdQuestions, setCreatedQuestions] = useState(0);
    const [remainingQuestions, setRemainingQuestions] = useState(0);
    const [questionsInFile, setQuestionsInFile] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const userId = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");
    const BASE_URL = import.meta.env.VITE_BASE_URL;

    useEffect(() => {
        fetchPlanLimits();
    }, []);

    const fetchPlanLimits = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/get-plan-id-limits`, { user_id: userId }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setPlanLimit(response.data.max_mcq_questions);

            const countResponse = await axios.post(`${BASE_URL}/get-question-count`, { created_by: userId }, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setCreatedQuestions(countResponse.data.mcq_question_count);
            setRemainingQuestions(response.data.max_mcq_questions - countResponse.data.mcq_question_count);
        } catch (error) {
            console.error("Error fetching plan limits:", error);
            toast.error("Failed to fetch plan limits");
        }
    };

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleFileUpload = async () => {
        if (!file) {
            toast.error("Please select a file to upload");
            return;
        }

        setIsLoading(true);
        setErrorMessage('');

        const formData = new FormData();
        formData.append('csvFile', file);
        formData.append('userId', userId);
        formData.append('maxMcqQuestions', planLimit);
        formData.append('mcqQuestionCount', createdQuestions);

        try {
            const response = await axios.post(`${BASE_URL}/api/org/question/validate-file`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                }
            });

            setQuestionsInFile(response.data.rowCount);
            toast.success("File validated successfully");
        } catch (error) {
            console.error("Error validating file:", error);
            setErrorMessage(error.response?.data?.message || "Error validating file");
            toast.error("File validation failed");
        } finally {
            setIsLoading(false);
        }
    };

    const handleSaveAll = async () => {
        setIsLoading(true);
        setErrorMessage('');

        try {
            await axios.post(`${BASE_URL}/api/org/question/bulk-create-mcq-question`, { userId }, {
                headers: { Authorization: `Bearer ${token}` }
            });

            toast.success("Questions created successfully");
            onBack(); // Go back to the questions list
        } catch (error) {
            console.error("Error creating questions:", error);
            setErrorMessage(error.response?.data?.message || "Error creating questions");
            toast.error("Failed to create questions");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box sx={{ p: 3, bgcolor: 'background.paper' }}>
            <Button startIcon={<ArrowBackIcon />} onClick={onBack} sx={{ mb: 2, justifyContent: 'space-between', alignItems: 'center' }}>
                Back to Questions
            </Button>
            <Typography variant="h4" gutterBottom>Bulk Create MCQ Questions</Typography>

            <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
                <Typography variant="h6" gutterBottom>Plan Limits</Typography>
                <Typography>Plan Limit to create MCQ questions: {planLimit}</Typography>
                <Typography>MCQ Questions Already Created: {createdQuestions}</Typography>
                <Typography>MCQ Questions that can be created more: {remainingQuestions}</Typography>
            </Paper>

            <TextField
                type="file"
                onChange={handleFileChange}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
            />

            <Button
                variant="contained"
                onClick={handleFileUpload}
                disabled={!file || isLoading}
                sx={{ mt: 2, mr: 2 }}
            >
                Validate File
            </Button>

            <Button
                variant="contained"
                onClick={handleSaveAll}
                disabled={!file || isLoading || questionsInFile === 0}
                sx={{ mt: 2 }}
            >
                Save All
            </Button>

            {isLoading && <CircularProgress sx={{ mt: 2 }} />}

            {errorMessage && (
                <Typography color="error" sx={{ mt: 2 }}>{errorMessage}</Typography>
            )}

            {questionsInFile > 0 && (
                <Typography sx={{ mt: 2 }}>
                    Number of questions in file: {questionsInFile}
                </Typography>
            )}
        </Box>
    );
};

export default MCQBulkQuestion;