import React from "react";
import Navbar from "../components/Navbar";
// import Footer from "../components/Footer";
import LoginForm from "../components/LoginForm";
import AdminLoginForm from "../components/AdminDashboard/AdminLoginForm";
import "../css/LoginPage.css";

const LoginPage = () => {
  return (
    <div>
      <Navbar />
      <div className="login-page-container">
        <div className="login-left-content">
          <LoginForm />
        </div>
        {/* <div className="login-right-content">
          <AdminLoginForm />
        </div> */}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default LoginPage;
