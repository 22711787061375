import React, { useState, useEffect } from "react";
import { FaEye, FaEdit, FaTrash, FaArrowLeft } from "react-icons/fa";
import { Box, Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from "react-router-dom";
import "../../css/AdminQuestions.css";
import axios from "axios";

const AdminQuestions = ({ onBack }) => {
  const [questions, setQuestions] = useState([]);
  const [createQuestionView, setCreateQuestionView] = useState(false);
  const [createBulkQuestionView, setCreateBulkQuestionView] = useState(false);
  const [step, setStep] = useState(1);
  const [questionId, setQuestionId] = useState("");
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionDescription, setQuestionDescription] = useState("");
  const [input, setInput] = useState("");
  const [output, setOutput] = useState("");
  const [points, setPoints] = useState(1);
  const [testCases, setTestCases] = useState([]);
  const [selectedQuestionTestCases, setSelectedQuestionTestCases] = useState(
    []
  );
  const [isTestCaseDialogOpen, setIsTestCaseDialogOpen] = useState(false);
  const [csvRowCount, setCsvRowCount] = useState(0);
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    fetchQuestionData();
  }, []);

  const fetchQuestionData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/get-data-for-public-test-question`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error Fetching Question Data", error);
    }
  };

  useEffect(() => {
    fetchQuestionData();
  }, []);

  const handleSubmitQuestion = async () => {
    try {
      const response = await axios.post(
        `${BASE_URL}/create-public-test-question`,
        {
          questionTitle,
          questionDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuestionId(response.data.questionId);
      setQuestionTitle("");
      setQuestionDescription("");
      return true;
    } catch (error) {
      console.error("Error Creating Question", error);
      return false;
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const success = await handleSubmitQuestion();
    if (success) {
      setStep(2);
    }
  };

  const handleTestcaseSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/create-testcase-for-public-question`,
        {
          question_id: questionId,
          input,
          output,
          points,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInput("");
      setOutput("");
      setPoints(1);
      setTestCases([...testCases, { input, output, points }]);
      console.log("question_id", questionId);
    } catch (error) {
      console.error("Error Creating Testcase", error);
    }
  };

  const handleGoBackToQuestions = () => {
    setStep(1);
    setCreateQuestionView(false);
    navigate("/admin-questions");
    setTestCases([]);
  };

  const handleCSVUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("user_id", user_id);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/count-csv-rows`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setCsvRowCount(response.data.rowCount);
    } catch (error) {
      console.error("Error uploading CSV file", error);
    }
  };

  const handleCreateQuestionClick = () => {
    setCreateQuestionView(true);
    setCreateBulkQuestionView(false);
  };

  const handleCreateBulkQuestionsClick = () => {
    setCreateBulkQuestionView(true);
    setCreateQuestionView(false);
  };

  const resetForm = () => {
    setStep(1);
    setQuestionTitle("");
    setQuestionDescription("");
    setInput("");
    setOutput("");
    setPoints(1);
    setTestCases([]);
    setCreateQuestionView(false);
  };

  const handleShowTestCases = (testCases) => {
    setSelectedQuestionTestCases(testCases);
    setIsTestCaseDialogOpen(true);
  };

  const handleCreateBulkQuestionsSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${BASE_URL}/bulk-public-test-question-and-testcase-upload`,
        {
          user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setCreateBulkQuestionView(false);
        navigate("/admin-questions");
      }
    } catch (error) {
      console.error("Error Creating Bulk Questions");
    }
  };

  const handleGoBackToQuestionsView = () => {
    setCreateBulkQuestionView(false);
    setCsvRowCount(0);
  };

  const handleClose = () => {
    navigate("/admin/dashboard"); // Navigate to admin dashboard
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      const userConfirmation = window.confirm(
        "Are you sure you want to delete this question"
      );
      if (userConfirmation) {
        await axios.delete(
          `${BASE_URL}/delete-public-question`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: {
              questionId,
            },
          }
        );
        fetchQuestionData();
      }
    } catch (error) {
      console.error("Error Deleting Question", error);
    }
  };

  const handleEditQuestion = (questionId) => {
    navigate(`/admin-edit-question/${questionId}`);
  };

  return (
    <div className="admin-dashboard__container">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={onBack}
          sx={{ color: 'primary.main' }}
        >
          Back to Question Types
        </Button>
      </Box>
      {!createQuestionView && !createBulkQuestionView && (
        <>
          <Typography variant="h4">Coding Questions</Typography>
          <div className="admin-dashboard__button-group">
            <button
              onClick={handleCreateQuestionClick}
              className="admin-dashboard__create-question-button"
            >
              Create Question
            </button>
            <button
              onClick={handleCreateBulkQuestionsClick}
              className="admin-dashboard__create-bulk-button"
            >
              Create Bulk Questions
            </button>
          </div>
          <table className="admin-dashboard__questions-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question) => (
                <tr key={question.id}>
                  <td>{question.title}</td>
                  <td>{question.content}</td>
                  <td>
                    <div className="admin-dashboard__actions-container">
                      <i
                        className="admin-dashboard__icon-eye"
                        title="View Test Cases"
                        onClick={() => handleShowTestCases(question.testcases)}
                      >
                        <FaEye className="icon" />
                      </i>
                      <i
                        className="fas fa-edit admin-dashboard__icon-edit"
                        title="Edit Question"
                        onClick={() => handleEditQuestion(question.id)}
                      ></i>
                      <i
                        className="fas fa-trash-alt admin-dashboard__icon-delete"
                        title="Delete Question"
                        onClick={() => handleDeleteQuestion(question.id)}
                      ></i>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}

      {createQuestionView && (
        <div className="admin-dashboard__create-question-container">
          <h1>Create Question</h1>
          {step === 1 ? (
            <form
              onSubmit={handleFormSubmit}
              className="admin-dashboard__create-question-form"
            >
              <div>
                <label htmlFor="questionTitle">Question Title</label>
                <input
                  type="text"
                  id="questionTitle"
                  placeholder="Enter question title"
                  value={questionTitle}
                  onChange={(e) => setQuestionTitle(e.target.value)}
                  required
                  className="admin-dashboard__input"
                />
              </div>
              <div>
                <label htmlFor="questionDescription">
                  Question Description
                </label>
                <textarea
                  id="questionDescription"
                  placeholder="Enter question description"
                  value={questionDescription}
                  onChange={(e) => setQuestionDescription(e.target.value)}
                  required
                  className="admin-dashboard__textarea"
                />
              </div>
              <button type="submit" className="admin-dashboard__save-button">
                Save and Next
              </button>
            </form>
          ) : (
            <form
              onSubmit={handleTestcaseSubmit}
              className="admin-dashboard__create-question-form"
            >
              <h2>Test Cases</h2>
              <div>
                <label htmlFor="testCaseInput">Input</label>
                <textarea
                  id="testCaseInput"
                  placeholder="Enter input"
                  value={input}
                  onChange={(e) => setInput(e.target.value)}
                  required
                  className="admin-dashboard__textarea"
                />
              </div>
              <div>
                <label htmlFor="testCaseOutput">Output</label>
                <textarea
                  id="testCaseOutput"
                  placeholder="Enter output"
                  value={output}
                  onChange={(e) => setOutput(e.target.value)}
                  required
                  className="admin-dashboard__textarea"
                />
              </div>
              <div>
                <label htmlFor="points">Points</label>
                <input
                  type="number"
                  id="points"
                  placeholder="Enter points"
                  value={points}
                  onChange={(e) => setPoints(e.target.value)}
                  min={1}
                  required
                  className="admin-dashboard__input"
                />
              </div>
              <button
                type="submit"
                className="admin-dashboard__add-testcase-button"
              >
                Add Test Case
              </button>
              <button
                type="button"
                onClick={handleGoBackToQuestions}
                className="admin-dashboard__submit-question-button"
              >
                Submit Question
              </button>
              <div>
                <h3>Current Test Cases</h3>
                <ul>
                  {testCases.map((testCase, index) => (
                    <li key={index}>
                      <strong>Test Case {index + 1}:</strong>
                      <br />
                      <strong>Input:</strong> {testCase.input}
                      <br />
                      <strong>Output:</strong> {testCase.output}
                      <br />
                      <strong>Points:</strong> {testCase.points}
                    </li>
                  ))}
                </ul>
              </div>
            </form>
          )}
        </div>
      )}

      {createBulkQuestionView && (
        <>
          <h1 className="text-2xl font-bold mb-4">Bulk Create Questions</h1>
          <form
            onSubmit={handleCreateBulkQuestionsSubmit}
            className="max-w-md mx-auto"
          >
            <div className="mb-4">
              <label
                htmlFor="csvFile"
                className="block text-sm font-semibold mb-2"
              >
                Upload CSV File
              </label>
              <input
                type="file"
                id="csvFile"
                accept=".csv"
                onChange={handleCSVUpload}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                required
              />
            </div>
            {csvRowCount > 0 && (
              <div className="mb-4">
                <p>Number of rows in CSV: {csvRowCount}</p>
              </div>
            )}
            <div className="mb-4">
              <a
                href="/sample.csv"
                download
                className="text-blue-500 hover:underline"
              >
                Download Sample CSV
              </a>
            </div>
            <div className="flex justify-between">
              <button
                type="button"
                onClick={handleGoBackToQuestionsView}
                className="admin-dashboard__back-button"
              >
                Back to Questions
              </button>
              <button type="submit" className="admin-dashboard__save-button">
                Save Bulk Questions
              </button>
            </div>
          </form>
        </>
      )}

      {isTestCaseDialogOpen && (
        <div className="admin-dashboard__testcase-dialog">
          <h2>Test Cases</h2>
          <button
            onClick={() => setIsTestCaseDialogOpen(false)}
            className="admin-dashboard__close-dialog"
          >
            Close
          </button>
          <ul>
            {selectedQuestionTestCases.map((testCase, index) => (
              <li key={index}>
                <strong>Test Case {index + 1}:</strong>
                <br />
                <strong>Input:</strong> {testCase.inputContent}
                <br />
                <strong>Output:</strong> {testCase.outputContent}
                <br />
                <strong>Points:</strong> {testCase.points}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AdminQuestions;
