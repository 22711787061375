import React, { useState, useEffect } from "react";
import axios from "axios";
import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "../../css/ProfileHeader.css";

const ProfileHeader = ({ onProfileSettingsClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [logo, setLogo] = useState("/Vyapar.png");
  const [username, setUsername] = useState("");
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoClick = () => {
    document.getElementById("logoUpload").click();
  };

  const handleLogoChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setLogo(e.target.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleProfileSettingsClick = () => {
    handleClose();
    onProfileSettingsClick();
  };

  const handleLogout = () => {
    const confirmLogout = window.confirm(
      "Do you want to log out from your profile?"
    );
    if (confirmLogout) {
      localStorage.clear();
      navigate("/candidate-login");
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    const fetchUsername = async () => {
      try {
        const result = await axios.post(
          `${BASE_URL}/fetch-candidate-username`,
          {
            email,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUsername(result.data.username);
      } catch (error) {
        console.error("Error Fetching Username", error);
      }
    };
    fetchUsername();
  }, []);

  return (
    <header className="profile-header">
      <div className="header-left">
        <Avatar
          className="company-logo"
          src={logo}
          alt="Company Logo"
          onClick={handleLogoClick}
        />
        <input
          type="file"
          id="logoUpload"
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleLogoChange}
        />
        <h2 className="username">Welcome, {username}</h2>
      </div>
      <div className="header-right">
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClick}
          className="account-icon"
          style={{ color: "#ffffff" }}
        >
          <AccountCircle fontSize="large" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className="menu"
        >
          <MenuItem onClick={handleProfileSettingsClick}>
            Profile Settings
          </MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </div>
    </header>
  );
};

export default ProfileHeader;
