import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../../css/AdminEditTest.css";

const AdminEditTest = ({ onTestUpdated }) => {
  const [testTitle, setTestTitle] = useState("");
  const [testDescription, setTestDescription] = useState("");
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [addedQuestions, setAddedQuestions] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState({});
  const { testId } = useParams(); // Assumes testId comes from URL params

  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    fetchTestDetails();
    fetchQuestions();
    fetchLanguages();
  }, [testId]);

  const fetchTestDetails = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/get-test-details/${testId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const {
        test_title,
        test_description,
        questions: testQuestions,
      } = response.data;

      setTestTitle(test_title);
      setTestDescription(test_description);
      setSelectedQuestions(testQuestions);
      setAddedQuestions(testQuestions.map((q) => q.questionId));
      setSelectedLanguages(
        testQuestions.reduce(
          (acc, { questionId, language }) => ({
            ...acc,
            [questionId]: language,
          }),
          {}
        )
      );
    } catch (error) {
      console.error("Error fetching test details:", error);
    }
  };

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/get-question-id-and-title/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const fetchLanguages = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/get-languages-for-public-test`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLanguages(response.data.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  const handleAddQuestion = (questionId) => {
    const selectedLanguage = selectedLanguages[questionId];
    if (selectedLanguage) {
      setSelectedQuestions((prevSelectedQuestions) => [
        ...prevSelectedQuestions,
        { questionId, language: selectedLanguage },
      ]);
      setAddedQuestions((prevAddedQuestions) => [
        ...prevAddedQuestions,
        questionId,
      ]);
    }
  };

  const handleRemoveQuestion = (questionId) => {
    setSelectedQuestions((prevSelectedQuestions) =>
      prevSelectedQuestions.filter((q) => q.questionId !== questionId)
    );
    setAddedQuestions((prevAddedQuestions) =>
      prevAddedQuestions.filter((id) => id !== questionId)
    );
  };

  const handleLanguageChange = (questionId, language) => {
    setSelectedLanguages((prevSelectedLanguages) => ({
      ...prevSelectedLanguages,
      [questionId]: language,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updateResponse = await axios.post(
        `${BASE_URL}/update-test/${testId}`,
        {
          test_title: testTitle,
          test_description: testDescription,
          questions: selectedQuestions,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (updateResponse.data.message !== "Test Updated Successfully") {
        throw new Error("Test update failed.");
      }

      onTestUpdated();
    } catch (error) {
      console.error("Error updating test:", error);
    }
  };

  const isQuestionAdded = (questionId) => addedQuestions.includes(questionId);

  return (
    <div className="admin-edit-test-container">
      <div className="admin-edit-test-left">
        <h1 className="admin-edit-test-header">Edit Test</h1>
        <form onSubmit={handleSubmit}>
          <div className="admin-edit-test-form-group">
            <label htmlFor="adminTestTitle">Test Title</label>
            <input
              type="text"
              id="adminTestTitle"
              value={testTitle}
              onChange={(e) => setTestTitle(e.target.value)}
              placeholder="Enter test title"
              required
            />
          </div>
          <div className="admin-edit-test-form-group">
            <label htmlFor="adminTestDescription">Test Description</label>
            <textarea
              id="adminTestDescription"
              value={testDescription}
              onChange={(e) => setTestDescription(e.target.value)}
              placeholder="Enter test description"
              required
            />
          </div>
          <button type="submit" className="admin-edit-test-submit-btn">
            Update Test
          </button>
        </form>
      </div>
      <div className="admin-edit-test-right">
        <h2 className="admin-edit-test-questions-header">
          Available Questions
        </h2>
        <div className="admin-edit-test-questions-container">
          <table className="admin-edit-test-questions-table">
            <thead>
              <tr>
                <th>Question Text</th>
                <th>Select Language</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {questions.map((question) => (
                <tr key={question.question_id}>
                  <td>{question.question_title}</td>
                  <td>
                    <select
                      onChange={(e) =>
                        handleLanguageChange(
                          question.question_id,
                          e.target.value
                        )
                      }
                      value={selectedLanguages[question.question_id] || ""}
                    >
                      <option value="" disabled>
                        Select language
                      </option>
                      {languages.map((language) => (
                        <option
                          key={language.language_id}
                          value={language.language}
                        >
                          {language.language}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    {isQuestionAdded(question.question_id) ? (
                      <button
                        type="button"
                        onClick={() =>
                          handleRemoveQuestion(question.question_id)
                        }
                        className="admin-edit-test-question-remove"
                      >
                        Remove
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => handleAddQuestion(question.question_id)}
                        disabled={!selectedLanguages[question.question_id]}
                        className="admin-edit-test-question-add"
                      >
                        Add
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="admin-edit-test-selected-questions">
          <h3>Selected Questions</h3>
          {selectedQuestions.length === 0 ? (
            <p>No questions selected yet.</p>
          ) : (
            <ul>
              {selectedQuestions.map(({ questionId, language }) => (
                <li key={questionId}>
                  Question ID: {questionId} (Language: {language})
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminEditTest;
