import React from "react";
import "../css/FooterHome.css";
import OnlinePopup from "./OnlinePopup";

const UniqueFooter = () => {
    return (
        <footer className="unique-footer">
            <div className="unique-footer-section">
                <p className="unique-footer-text" style={{ fontSize: '13px' }}>&#169; Copyright 2024 <a href="https://www.technocirrus.com/">TechnoCirrus Solutions</a>. All rights reserved.</p>
            </div>
            <OnlinePopup/>
        </footer>
    );
}

export default UniqueFooter;
