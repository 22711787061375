import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const testData = {
  'Python Basics': [
    { name: 'Python If-Else', difficulty: 'Easy', maxScore: 10, successRate: '89.62%' },
    { name: 'Arithmetic Operators', difficulty: 'Easy', maxScore: 10, successRate: '97.35%' },
    { name: 'Python: Division', difficulty: 'Easy', maxScore: 10, successRate: '98.67%' },
    { name: 'Loops', difficulty: 'Easy', maxScore: 10, successRate: '98.07%' },
    { name: 'Write a function', difficulty: 'Medium', maxScore: 10, successRate: '90.28%' },
  ],
  'JavaScript Challenges': [
    { name: 'Variables and Types', difficulty: 'Easy', maxScore: 10, successRate: '95.23%' },
    { name: 'Functions and Scope', difficulty: 'Medium', maxScore: 15, successRate: '88.45%' },
    { name: 'Arrays and Objects', difficulty: 'Medium', maxScore: 15, successRate: '86.79%' },
  ],
  'Data Structures with C++': [
    { name: 'Linked Lists', difficulty: 'Medium', maxScore: 20, successRate: '82.14%' },
    { name: 'Binary Trees', difficulty: 'Hard', maxScore: 25, successRate: '75.92%' },
    { name: 'Sorting Algorithms', difficulty: 'Medium', maxScore: 20, successRate: '84.37%' },
  ],
};

const PublicTests = () => {
  const [selectedCategory, setSelectedCategory] = useState('Python Basics');
  const [categories, setCategories] = useState([]);
  const [tests, setTests] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      fetchTests(selectedCategory);
    }
  }, [selectedCategory]);

  const fetchCategories = async () => {
    // Simulating API call
    // const response = await axios.get(`${import.meta.env.VITE_BASE_URL}/categories`);
    // setCategories(response.data);
    setCategories(Object.keys(testData));
  };

  const fetchTests = async (category) => {
    // Simulating API call
    // const response = await axios.get(`${import.meta.env.VITE_BASE_URL}/tests/${category}`);
    // setTests(prevTests => ({ ...prevTests, [category]: response.data }));
    setTests(prevTests => ({ ...prevTests, [category]: testData[category] }));
  };

  const handleAttemptTest = async (testId) => {
    try {
      // Simulating API calls
      // const availabilityResponse = await axios.post(
      //   `${import.meta.env.VITE_BASE_URL}/check-test-availability`,
      //   { testId }
      // );
      
      // if (availabilityResponse.status === 200) {
      //   const email = localStorage.getItem('email');
      //   const response = await axios.post(
      //     `${import.meta.env.VITE_BASE_URL}/attempt-test`,
      //     { email, testId }
      //   );
        
      //   if (response.status === 200) {
      //     localStorage.setItem('testId', testId);
      //     navigate('/instruction', { state: { testId, candidateId: email } });
      //   }
      // }

      // For now, just navigate to the instruction page
      const email = localStorage.getItem('email') || 'test@example.com';
      localStorage.setItem('testId', testId);
      navigate('/instruction', { state: { testId, candidateId: email } });
    } catch (error) {
      console.error("Error attempting test:", error);
    }
  };

  return (
    <div className="flex h-full w-full bg-gray-100">
      <div className="w-1/4 p-4 bg-gray-100 border-r border-gray-300">
        <h2 className="text-xl font-bold mb-4 text-blue-600">Public Practice Tests</h2>
        <div className="space-y-2">
          {categories.map((category) => (
            <div
              key={category}
              className={`p-3 rounded-lg cursor-pointer transition-all ${
                selectedCategory === category ? 'bg-blue-500 text-white' : 'bg-white hover:bg-gray-200'
              }`}
              onClick={() => setSelectedCategory(category)}
            >
              <h3 className="text-sm font-semibold">{category}</h3>
              {selectedCategory !== category && (
                <p className="text-xs mt-1 text-gray-600">Click to view tests</p>
              )}
            </div>
          ))}
        </div>
      </div>
      
      <div className="w-3/4 p-4 bg-white">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">{selectedCategory}</h2>
        <div className="space-y-4">
          {testData[selectedCategory].map((test, index) => (
            <div key={index} className="bg-gray-50 p-4 rounded-lg shadow-sm">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold text-blue-600">{test.name}</h3>
                <button 
                  className="px-6 py-2 bg-green-500 text-white text-sm rounded hover:bg-green-600 transition-colors w-40"
                  onClick={() => handleAttemptTest(`${selectedCategory}-${index}`)}
                >
                  Solve Challenge
                </button>
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-600">
                <span className={`mr-4 px-2 py-1 rounded ${
                  test.difficulty === 'Easy' ? 'bg-green-100 text-green-800' :
                  test.difficulty === 'Medium' ? 'bg-yellow-100 text-yellow-800' :
                  'bg-red-100 text-red-800'
                }`}>
                  {test.difficulty}
                </span>
                <span className="mr-4">Max Score: {test.maxScore}</span>
                <span className="flex items-center">
                  <span className="text-yellow-400 mr-1">★</span>
                  Success Rate: {test.successRate}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PublicTests;