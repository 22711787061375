import React from 'react';
import '../css/Legal.css';  // Import the shared legal CSS

const ShippingPolicy = () => (
  <div className="legal-content">
    <h2>Shipping Policy</h2>
    <p className="updated-date">Last updated on 29th September 2024</p>
    
    <p>
      Shipping is not applicable for business.
    </p>
    
    <p className="footer-note">
      Please contact our support for further details regarding shipping policies.
    </p>

    <footer className="legal-footer">
  <p>
    For any questions about these Terms, please contact us at  
    <a href="mailto:info@technocirrus.com"> info@technocirrus.com </a> or call us at 
    <a href="tel:+919881507833">  +91 9175046101 </a>
  </p>
</footer>

  </div>
);

export default ShippingPolicy;
