import React from "react";
import Navbar from "../components/Navbar";
import SignupForm from "../components/SignupForm";
import RightContent from "../components/RightContent";
import "../css/SignupPage.css";
import FooterHome from "../components/FooterHome";


const SignupPage = () => {
    return (
      <div style={{minHeight:"100vh"}}>
        <Navbar/>
      <div className="signup-page">
      <div className="left-content">
        <SignupForm />
      </div>
      <div className="right-content">
        <RightContent />
      </div>

    </div>
        <FooterHome/>
    </div>
  );
};

export default SignupPage;