// import React, { useEffect, useState } from "react";

// import Editor from "@monaco-editor/react";

// const CodeEditorWindow = ({ onChange, language, code, theme }) => {
//   const [value, setValue] = useState(code || "");

//   useEffect(() => {
//     setValue(code);
//   }, [code]);

//   const handleEditorChange = (value) => {
//     setValue(value);
//     onChange("code", value);
//   };

//   return (
//     <div className="overlay rounded-md overflow-hidden w-full h-full shadow-4xl">
//       <Editor
//         height="70vh"
//         width={`99%`}
//         language={language || "javascript"}
//         value={value}
//         theme={theme}
//         defaultValue="// some comment"
//         onChange={handleEditorChange}
//       />
//     </div>
//   );
// };
// export default CodeEditorWindow;
//=============================================================
import React, { useEffect, useState } from "react";
import Editor from "@monaco-editor/react";

const CodeEditorWindow = ({ onChange, language, code, theme }) => {
  const [value, setValue] = useState(code || "");

  useEffect(() => {
    setValue(code);
  }, [code]);

  const handleEditorChange = (value) => {
    setValue(value);
    onChange("code", value);
  };

  const handleEditorDidMount = (editor) => {
    editor.onDidChangeModelContent(() => {
      handleEditorChange(editor.getValue());
    });
  };

  return (
    <div className="overlay rounded-md overflow-hidden w-full h-full shadow-4xl">
      <Editor
        height="70vh"
        width="99%"
        language={language || "javascript"}
        value={value}
        theme={theme}
        defaultValue="// some comment"
        onMount={handleEditorDidMount}
        options={{ contextmenu: false }} // Disable the context menu (i.e right click)
      />
    </div>
  );
};

export default CodeEditorWindow;
