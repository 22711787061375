import React, { useState } from 'react';
import {
  Box,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Paper,
  Grid
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';
import { toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateMCQQuestion = ({ onBack }) => {
  const [questionType, setQuestionType] = useState('singleCorrect');
  const [questionText, setQuestionText] = useState('');
  const [questionDescription, setQuestionDescription] = useState('');
  const [difficulty, setDifficulty] = useState('easy');
  const [points, setPoints] = useState(1);
  const [options, setOptions] = useState([
    { optionText: '', isCorrect: false },
    { optionText: '', isCorrect: false },
  ]);

  const handleQuestionTypeChange = (event) => {
    setQuestionType(event.target.value);
    setOptions(options.map(option => ({ ...option, isCorrect: false })));
  };

  const handleOptionChange = (index, field, value) => {
    const newOptions = [...options];
    if (field === 'isCorrect' && questionType === 'singleCorrect') {
      newOptions.forEach((option, i) => {
        option.isCorrect = i === index ? value : false;
      });
    } else {
      newOptions[index][field] = value;
    }
    setOptions(newOptions);
  };

  const handleAddOption = () => {
    if (options.length < 6) {
      setOptions([...options, { optionText: '', isCorrect: false }]);
    }
  };

  const handleRemoveOption = (index) => {
    if (options.length > 2) {
      const newOptions = options.filter((_, i) => i !== index);
      setOptions(newOptions);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate form
    if (!questionText.trim() || !questionDescription.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    if (options.some(option => !option.optionText.trim())) {
      toast.error('Please fill in all option texts');
      return;
    }

    if (questionType === 'singleCorrect' && !options.some(option => option.isCorrect)) {
      toast.error('Please select a correct answer for single correct question');
      return;
    }

    if (questionType === 'multipleCorrect' && !options.some(option => option.isCorrect)) {
      toast.error('Please select at least one correct answer for multiple correct question');
      return;
    }

    // Prepare data for API
    const questionData = {
      questionType: questionType === 'singleCorrect' ? false : true,
      questionText,
      questionDescription,
      difficulty,
      points: parseInt(points),
      userId: localStorage.getItem('user_id'),
      options: options.map(option => ({
        optionText: option.optionText,
        isCorrect: option.isCorrect
      }))
    };

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/api/org/question/create-mcq-question`,
        questionData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      if (response.data.success) {
        toast.success('MCQ question created successfully');
        setTimeout(() => onBack(), 2000); // Navigate back after 2 seconds
      } else {
        toast.error('Error creating MCQ question');
      }
    } catch (error) {
      console.error('Error creating MCQ question:', error);
      toast.error('Error creating MCQ question');
    }
  };

  return (
    <Box sx={{ mx: 'auto', mt: 4, bgcolor: 'background.paper', p: 3, borderRadius: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={onBack}
          sx={{ color: 'primary.main' }}
        >
          Back to MCQ Questions
        </Button>
      </Box>
      <Typography variant="h4">Create MCQ Question</Typography>

      <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
        <form onSubmit={handleSubmit}>
          <FormControl sx={{ width: '300px', mb: 2 }}>
            <InputLabel>Question Type</InputLabel>
            <Select
              value={questionType}
              onChange={handleQuestionTypeChange}
              label="Question Type"
            >
              <MenuItem value="singleCorrect">Single Correct Option</MenuItem>
              <MenuItem value="multipleCorrect">Multiple Correct Options</MenuItem>
            </Select>
          </FormControl>

          <TextField
            fullWidth
            margin="normal"
            label="Question Text"
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
            required
          />

          <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Question Description</Typography>
          <ReactQuill
            value={questionDescription}
            onChange={setQuestionDescription}
            modules={{
              toolbar: [
                [{ 'header': [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image'],
                ['clean']
              ],
            }}
            style={{ height: '200px', marginBottom: '50px' }}
          />

          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel>Difficulty</InputLabel>
                <Select
                  value={difficulty}
                  onChange={(e) => setDifficulty(e.target.value)}
                  label="Difficulty"
                >
                  <MenuItem value="easy">Easy</MenuItem>
                  <MenuItem value="medium">Medium</MenuItem>
                  <MenuItem value="hard">Hard</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Points"
                type="number"
                value={points}
                onChange={(e) => setPoints(e.target.value)}
                inputProps={{ min: 1 }}
                required
              />
            </Grid>
          </Grid>

          <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
            Options
          </Typography>

          {options.map((option, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              {questionType === 'singleCorrect' ? (
                <Radio
                  checked={option.isCorrect}
                  onChange={(e) => handleOptionChange(index, 'isCorrect', e.target.checked)}
                />
              ) : (
                <Checkbox
                  checked={option.isCorrect}
                  onChange={(e) => handleOptionChange(index, 'isCorrect', e.target.checked)}
                />
              )}
              <Box sx={{ flexGrow: 1, ml: 2 }}>
                <ReactQuill
                  value={option.optionText}
                  onChange={(content) => handleOptionChange(index, 'optionText', content)}
                  modules={{
                    toolbar: [
                      ['bold', 'italic', 'underline'],
                      ['link', 'image'],
                    ],
                  }}
                  style={{ height: '100px', marginBottom: '30px' }}
                />
              </Box>
              <IconButton onClick={() => handleRemoveOption(index)} disabled={options.length <= 2} color="error">
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}

          {options.length < 6 && (
            <Button 
              startIcon={<AddIcon />} 
              onClick={handleAddOption} 
              sx={{ mb: 2, color: 'success.main' }}
            >
              Add Option
            </Button>
          )}

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outlined" onClick={onBack}>
              Cancel
            </Button>
            <Button 
              variant="contained" 
              type="submit"
              sx={{ bgcolor: 'success.main', '&:hover': { bgcolor: 'success.dark' } }}
            >
              Create Question
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default CreateMCQQuestion;