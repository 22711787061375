// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// const OnlinePopup = () => {
//   const [showForm, setShowForm] = useState(false);
//   const [isMobile, setIsMobile] = useState(false);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth <= 768);
//     };
    
//     handleResize();
//     window.addEventListener('resize', handleResize);
    
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const togglePopup = () => {
//     setShowForm(prev => !prev);
//     if (!showForm) {
//       document.body.style.overflow = 'hidden';
//     } else {
//       document.body.style.overflow = 'unset';
//     }
//   };

//   const handleCloseForm = (e) => {
//     e.preventDefault();
//     setShowForm(false);
//     document.body.style.overflow = 'unset';
//   };

//    // Redirect to ContactInfo page with hash to scroll to the ContactQueryForm
//    const handleRedirect = () => {
//     navigate('/contact-info#form');  // Adding hash for scrolling
//   };

//   return (
//     <div className="fixed bottom-4 right-4 z-50">
//       {/* Initial Popup */}
//       <div className="bg-white rounded-full shadow-lg border-2 border-[#27ae60] overflow-hidden" style={{cursor:"pointer"}} onClick={togglePopup}>
//         <div className="p-3 relative">
//             <i className="fa fa-commenting" style={{fontSize:"24px",color:"#27ae60"}} ></i>
//         </div>
//       </div>

//       {/* Form Modal - Increased top margin */}
//       {showForm && (
//         <div className="fixed inset-0 bg-black bg-opacity-50 flex items-start justify-center z-[9999] px-4 pt-[150px] pb-4">
//           <div className={`bg-white rounded-lg shadow-xl ${
//             isMobile ? 'w-[calc(100%-2rem)] max-h-[calc(100vh-140px)]' : 'w-[400px] max-h-[calc(100vh-140px)]'
//           } overflow-y-auto`}>
//             <div className="p-5">
//               <form
//                 action="https://formkeep.com/f/a4ff462c2dd3"
//                 accept-charset="UTF-8"
//                 encType="multipart/form-data"
//                 method="POST"
//                 className="space-y-4"
//               >
//                 <div className="text-center mb-4">
//                   <h2 className="text-xl font-bold text-gray-800">
//                     SUBMIT YOUR <span className="text-blue-500">ENQUIRY</span>
//                   </h2>
//                 </div>

//                 <div className="space-y-3">
//                   {/* Name Field */}
//                   <div>
//                     <label htmlFor="Name" className="block text-sm font-medium text-gray-700 mb-1">
//                       Your Name *
//                     </label>
//                     <input
//                       type="text"
//                       name="Name"
//                       id="Name"
//                       required
//                       autoFocus
//                       className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all text-sm"
//                     />
//                   </div>

//                   {/* Email Field */}
//                   <div>
//                     <label htmlFor="Email" className="block text-sm font-medium text-gray-700 mb-1">
//                       Your Email *
//                     </label>
//                     <input
//                       type="email"
//                       name="Email"
//                       id="Email"
//                       required
//                       className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all text-sm"
//                     />
//                   </div>

//                   {/* Message Field */}
//                   <div>
//                     <label htmlFor="Message" className="block text-sm font-medium text-gray-700 mb-1">
//                       Your Message *
//                     </label>
//                     <textarea
//                       name="Message"
//                       id="Message"
//                       required
//                       rows={4}
//                       className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all resize-none text-sm"
//                     />
//                   </div>
//                 </div>

//                 {/* Hidden Email Field */}
//                 <div className="hidden">
//                   <input
//                     name="subscribe_d835f9941131_44665"
//                     type="email"
//                     tabIndex={-1}
//                     autoComplete="off"
//                   />
//                 </div>

//                 {/* Buttons */}
//                 <div className="flex gap-3 mt-4">
//                   <button
//                     type="submit"
//                     className="flex-1 bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-3 rounded transition-colors duration-200 shadow-md hover:shadow-lg text-sm"
//                   >
//                     Submit
//                   </button>
//                   <button
//                     onClick={handleCloseForm}
//                     className="flex-1 bg-gray-500 hover:bg-gray-600 text-white font-medium py-2 px-3 rounded transition-colors duration-200 shadow-md hover:shadow-lg text-sm"
//                   >
//                     Cancel
//                   </button>
//                 </div>
//               </form>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default OnlinePopup;


import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const OnlinePopup = () => {
  const navigate = useNavigate();

  // Redirect to ContactInfo page with hash to scroll to the ContactQueryForm
  const handleRedirect = () => {
    navigate('/contact-info#form');  // Adding hash for scrolling
  };

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {/* Initial Popup */}
      <div
        className="bg-white rounded-full shadow-lg border-2 border-[#27ae60] overflow-hidden"
        style={{ cursor: "pointer" }}
        onClick={handleRedirect}
      >
        <div className="p-3 relative">
          <i className="fa fa-commenting" style={{ fontSize: "24px", color: "#27ae60" }}></i>
        </div>
      </div>
    </div>
  );
};

export default OnlinePopup;
