import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../css/AdminCreateTest.css";

// const AdminCreateTest = ({ testId, onTestCreated }) => {
const AdminCreateTest = ({ onTestCreated }) => {
  const [testTitle, setTestTitle] = useState("");
  const [testDescription, setTestDescription] = useState("");
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [addedQuestions, setAddedQuestions] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState({});

  const navigate = useNavigate();

  const token = localStorage.getItem("token");
  const user_id = localStorage.getItem("user_id");
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    fetchQuestions();
    fetchLanguages();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/get-question-id-and-title/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const fetchLanguages = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/get-languages-for-public-test`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLanguages(response.data.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  const handleAddQuestion = (questionId) => {
    const selectedLanguage = selectedLanguages[questionId];
    if (selectedLanguage) {
      setSelectedQuestions((prevSelectedQuestions) => [
        ...prevSelectedQuestions,
        { questionId, language: selectedLanguage },
      ]);
      setAddedQuestions((prevAddedQuestions) => [
        ...prevAddedQuestions,
        questionId,
      ]);
    }
  };

  const handleLanguageChange = (questionId, language) => {
    setSelectedLanguages((prevSelectedLanguages) => ({
      ...prevSelectedLanguages,
      [questionId]: language,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const createResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/create-public-test/${user_id}`,
        {
          test_title: testTitle,
          test_description: testDescription,
          questions: selectedQuestions,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (createResponse.data.message !== "Test Created Successfully") {
        throw new Error("Test creation failed.");
      }

      // const fetchTestResponse = await axios.get(
      //   `${import.meta.env.VITE_BASE_URL}/tests/${testId}`
      // );

      // const createdTest = fetchTestResponse.data;
      // if (!createdTest.test_title || !createdTest.test_id) {
      //   console.error("Fetched test is missing critical data.");
      //   return;
      // }

      // onTestCreated(createdTest);
    } catch (error) {
      console.error("Error creating or fetching test:", error);
    }
  };

  const isQuestionAdded = (questionId) => addedQuestions.includes(questionId);

  return (
    <div className="admin-create-test-modal">
      <div className="admin-create-test-content">
        <button
          className="admin-create-test-close-btn"
          onClick={() => navigate("/admin/dashboard")}
        >
          &times; Close
        </button>
        <div className="admin-create-test-left">
          <h1 className="admin-create-test-header">Create New Test</h1>
          <form onSubmit={handleSubmit}>
            <div className="admin-create-test-form-group">
              <label htmlFor="adminTestTitle">Test Title</label>
              <input
                type="text"
                id="adminTestTitle"
                value={testTitle}
                onChange={(e) => setTestTitle(e.target.value)}
                placeholder="Enter test title"
                required
              />
            </div>
            <div className="admin-create-test-form-group">
              <label htmlFor="adminTestDescription">Test Description</label>
              <textarea
                id="adminTestDescription"
                value={testDescription}
                onChange={(e) => setTestDescription(e.target.value)}
                placeholder="Enter test description"
                required
              />
            </div>
            <button type="submit" className="admin-create-test-submit-btn">
              Create Test
            </button>
          </form>
        </div>
        <div className="admin-create-test-right">
          <h2 className="admin-create-test-questions-header">
            Available Questions
          </h2>
          <div className="admin-create-test-questions-container">
            <table className="admin-create-test-questions-table">
              <thead>
                <tr>
                  <th>Question Text</th>
                  <th>Select Language</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {questions.map((question) => (
                  <tr key={question.question_id}>
                    <td>{question.question_title}</td>
                    <td>
                      <select
                        onChange={(e) =>
                          handleLanguageChange(
                            question.question_id,
                            e.target.value
                          )
                        }
                        value={selectedLanguages[question.question_id] || ""}
                      >
                        <option value="" disabled>
                          Select language
                        </option>
                        {languages.map((language) => (
                          <option
                            key={language.language_id}
                            value={language.language}
                          >
                            {language.language}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => handleAddQuestion(question.question_id)}
                        disabled={
                          isQuestionAdded(question.question_id) ||
                          !selectedLanguages[question.question_id]
                        }
                        className={
                          isQuestionAdded(question.question_id)
                            ? "admin-create-test-question-added"
                            : "admin-create-test-question-add"
                        }
                      >
                        {isQuestionAdded(question.question_id)
                          ? "Added"
                          : "Add"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCreateTest;
