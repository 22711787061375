import React, { useState } from 'react';
import "../css/OurWorkDomain.css"; // Import the CSS file for styling
import Navbar from './Navbar';

// Define the filterable content types
const contentTypes = ['All', 'Cloud infrastructure', 'Web Development', 'DevOps Services'];

// Dummy data for projects
const dummyProjects = [
  {
    id: 1,
    contentType: 'Web Development',
    contentType2: 'Health',
    title: 'Callidus pharma',
    description: 'Our team worked closely with Callidus Pharma Labs, ensuring that every aspect of the website reflected their brand identity and values.',
    image: 'Callidus.jpg',
    websiteLink: 'https://www.calliduslabs.com/'
  },
  {
    id: 2,
    contentType: 'Web Development',
    contentType2: 'Ecommerce',
    title: 'Truck N Tow',
    description: 'We had the pleasure of collaborating with Truck N Tow to develop their ecommerce platform, specializing in trucking and towing accessories.',
    image: 'TruckNTow.png',
    websiteLink: 'https://www.truckntow.com/'
  },
  {
    id: 3,
    contentType: 'Web Development',
    contentType2: 'Ecommerce',
    title: 'Bookify',
    description: 'Create a stunning portfolio website to showcase your work and attract clients.',
    image: 'Bookify.jpg',
    websiteLink: 'https://bookify.space/'
  },
  {
    id: 4,
    contentType: 'DevOps Services',
    contentType2: 'Ecommerce',
    title: 'Vyapar',
    description: 'Implement CI/CD pipelines for automated testing and deployment of your applications.',
    image: 'Vyapar.png',
    websiteLink: 'https://vyaparapp.in/?campaign_id=GBA&adgroupid=108697283895&creative=535123163435&keyword=vyapar&targetid=aud-940577304712:kwd-391056922771&placement=&adposition=&g=g&device=c&devicemodel=&matchtype=b&loc_physical_ms=9075333&gclid=Cj0KCQjwxeyxBhC7ARIsAC7dS3-24kLzi9gu7mBS8GXUWEJRTgQBe7RLnRG5VPLd3oqoifS7aW8gY1kaAgiCEALw_wcB&gad_source=1'
  },
  {
    id: 5,
    contentType: 'Cloud infrastructure',
    contentType2: 'Ecommerce',
    title: 'Axess India',
    description: 'Implement serverless architecture for cost-efficient and scalable applications.',
    image: 'Axess.jpg',
    websiteLink: 'https://www.axessgroup.com/'
  },
  {
    id: 6,
    contentType: 'Web Development',
    contentType2: 'Ecommerce',
    title: 'BuffaloInn',
    description: 'Develop a custom content management system tailored to your business needs.',
    image: 'BuffaloInn.jpg',
    websiteLink: 'https://www.buffaloinncanyon.com/'
  },
  {
    id: 7,
    contentType: 'Web Development',
    contentType2: 'Ecommerce',
    title: 'Savespree',
    description: 'Create responsive web designs that adapt to various screen sizes and devices.',
    image: 'Savespree.png',
    websiteLink: 'https://www.savespree.ca/'
  },
  {
    id: 8,
    contentType: 'DevOps Services',
    contentType2: 'Ecommerce',
    title: 'My Small Space',
    description: 'Implement infrastructure as code (IaC) to automate infrastructure provisioning and management.',
    image: 'MySmallSpace.png',
    websiteLink: 'https://www.mysmallspace.co.uk/'
  },
  {
    id: 9,
    contentType: 'Web Development',
    contentType2: 'Engineering',
    title: 'Meson Valves',
    description: 'Configure high availability setup to ensure continuous availability of your applications.',
    image: 'Meson.jpg',
    websiteLink: 'https://example.com/callidus-pharma'
  },
  {
    id: 10,
    contentType: 'Web Development',
    contentType2: 'Ecommerce',
    title: 'PVRoof',
    description: 'Build progressive web apps (PWAs) for enhanced user experiences and performance.',
    image: 'Pvroof.jpg',
    websiteLink: 'https://www.pvroof.io/'
  },
  {
    id: 11,
    contentType: 'Cloud infrastructure',
    contentType2: 'Health',
    title: 'Relez Pharma',
    description: 'Optimize cloud infrastructure costs by identifying and eliminating unnecessary expenses.',
    image: 'RelezPharma.jpg',
    websiteLink: 'https://www.relezpharma.com/'
  },
  {
    id: 12,
    contentType: 'DevOps Services',
    contentType2: 'Cosmetics',
    title: 'Rejuvenate Beauty Salon',
    description: 'Leverage containerization technologies like Docker and Kubernetes for efficient application deployment and management.',
    image: 'Rejuvenate.jpg',
    websiteLink: 'https://example.com/callidus-pharma'
  },
  // Add more dummy projects as needed
];

// Define the second set of filterable content types
const contentTypes2 = ['All industries', 'Health', 'Engineering', 'Ecommerce', 'Cosmetics'];

const OurWorkDomain = () => {
  const [selectedContentType, setSelectedContentType] = useState('All');
  const [selectedContentType2, setSelectedContentType2] = useState('All industries');
  const [projects, setProjects] = useState(dummyProjects);

  // Function to filter projects based on content type and content type 2
  const filterProjects = (contentType, contentType2) => {
    let filteredProjects = dummyProjects;
    if (contentType !== 'All') {
      filteredProjects = filteredProjects.filter(project => project.contentType === contentType);
    }
    if (contentType2 !== 'All industries') {
      filteredProjects = filteredProjects.filter(project => project.contentType2 === contentType2);
    }
    setProjects(filteredProjects);
    setSelectedContentType(contentType);
    setSelectedContentType2(contentType2);
  };

  // Function to handle card click and redirect to website
  const handleCardClick = (websiteLink) => {
    window.location.href = websiteLink;
  };

  return (
    <div>
    <Navbar/>
    <>
    <div className="our-work-heading" >Our Other Work</div>
    
    <div className="our-work-domain">
        
      {/* Filterable content section 1 */}
      <div className="filter-section">
        <h2>Services</h2>
        <div className="content-types">
          {contentTypes.map(type => (
            <button
              key={type}
              className={selectedContentType === type ? 'active' : ''}
              onClick={() => filterProjects(type, selectedContentType2)}
            >
              {type}
            </button>
          ))}
        </div>
      </div>

      {/* Filterable content section 2 */}
      <div className="filter-section">
        <h2>Industry</h2>
        <div className="content-types">
          {contentTypes2.map(type => (
            <button
              key={type}
              className={selectedContentType2 === type ? 'active' : ''}
              onClick={() => filterProjects(selectedContentType, type)}
            >
              {type}
            </button>
          ))}
        </div>
      </div>

      {/* Card-like structure for projects */}
      <div className="project-cards">
        {projects.map(project => (
          <div key={project.id} className="project-card" onClick={() => handleCardClick(project.websiteLink)}>
            {/* Content of your card */}
            <img src={project.image} alt={project.title} />
            <h3>{project.contentType}</h3>
            <h3>{project.contentType2}</h3>
            <h2>{project.title}</h2>
            <p>{project.description}</p>
          </div>
        ))}
      </div>
    </div>
    </>
    </div>
  );
};

export default OurWorkDomain;
