import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ Component }) => {
  const navigate = useNavigate();

  let isUserLoggedIn = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
    }
  };

  useEffect(() => {
    isUserLoggedIn();
  }, []);

  return (
    <div>
      <Component />
    </div>
  );
};

export default ProtectedRoute;
