import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../css/ManageParticipants.css";
import { useParams, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const ManageParticipants = ({ testId, onClose }) => {
  const [email, setEmail] = useState("");
  const [participants, setParticipants] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [bulkCreateParticipantView, setBulkCreateParticipantView] =
    useState(false);
  const [bulkDeleteParticipantView, setBulkDeleteParticipantView] =
    useState(false);
  const [view, setView] = useState("");
  const [participantIdsToDelete, setParticipantIdsToDelete] = useState([]);
  const [csvRowCount, setCsvRowCount] = useState(0); // Define state for CSV row count
  const [csvDeleteRowCount, setCsvDeleteRowCount] = useState(0); // Define state for delete CSV row count
  const [errorMessage, setErrorMessage] = useState(""); // Define state for error message
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

 
  const navigate = useNavigate();
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    fetchParticipants();
  }, []);

  const fetchParticipants = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/participants/${testId}`
      );

      setParticipants(response.data);
      setError("");
    } catch (error) {
      if (error.response?.status === 404) {
        setError("No participants added");
      } else {
        console.error("Error fetching participants:", error);
        setError("Error fetching participants");
      }
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const handleAddParticipant = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setError("Invalid email format");
      return;
    }
    try {
      setLoading(true);

      const participantId = uuidv4();
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/add-participant`,
        {
          participant_id: participantId,
          user_id: userId,
          email,
          test_id: testId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.error === null) {
        setError("The entered email is not added by organization");
        return;
      }

      setEmail(""); // Clear email input
      setError(""); // Clear error message
      fetchParticipants(); // Refresh participant list
    } catch (error) {
      console.error("Error adding participant:", error);
      setError(error.response?.data?.error || "Error adding participant"); // Set error message
    } finally {
      setLoading(false); // Set loading to false
    }
  };

  const handleDeleteParticipant = async (participantId) => {
    try {
      await axios.delete(
        `${import.meta.env.VITE_BASE_URL}/participants/${participantId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      fetchParticipants(); // Refresh participant list after deletion
      window.location.reload();
    } catch (error) {
      console.error("Error deleting participant:", error);
      setError("Error deleting participant"); // Set error message
    }
  };

  const openBulkCreateParticipantModal = async () => {
    try {
      setBulkCreateParticipantView(true);
      setView("bulkCreateParticipant");
    } catch (error) {
      console.error("Error bulk uploading participant data", error);
    }
  };

  const handleGoToDashboard = () => {
    onClose();  // Calls the function passed from the parent to set the view to "test"
  };

  const handleCSVUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("user_id", userId);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/check-validations-and-save-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { invalidEmails, duplicateEmails } = response.data;

      if (invalidEmails.length === 0 && duplicateEmails.length === 0) {
        setIsButtonDisabled(false);
      } else {
        if (invalidEmails.length !== 0) {
          setErrorMessage(`File contains invalid emails ${invalidEmails}`);
          setIsButtonDisabled(true);
        } else if (duplicateEmails.length !== 0) {
          setErrorMessage(`File contains duplicate emails ${duplicateEmails}`);
          setIsButtonDisabled(true);
        }
      }
    } catch (error) {
      console.error("Error uploading CSV file for deletion", error);
      setIsButtonDisabled(true);
    }
  };

  const handleBulkSaveParticipant = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/bulk-add-test-participants`,
        {
          user_id: userId,
          test_id: testId,
        }
      );
      if (response.status === 200) {
        window.location.reload();
      } else {
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else if (response.data.message) {
          setErrorMessage(response.data.message);
        }
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error ||
          error.response?.data?.message ||
          "Internal server error"
      );
    }
  };
  const openBulkDeleteParticipantModal = () => {
    setBulkDeleteParticipantView(true);
    setView("bulkDeleteParticipant");
  };

  const handleBulkDeleteParticipantSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.delete(
        `${import.meta.env.VITE_BASE_URL}/bulk-delete-test-participants`,
        {
          params: {
            user_id: userId,
            test_id: testId,
          },
        }
      );
      if (response.status === 200) {
        setBulkDeleteParticipantView(false);
        setErrorMessage("");
        window.location.reload();
      } else {
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else if (response.data.message) {
          setErrorMessage(response.data.message);
        }
      }
    } catch (error) {
      setErrorMessage(
        error.response?.data?.error ||
          error.response?.data?.message ||
          "Internal server error"
      );
    }
  };
  const handleCloseModal = () => {
    setBulkCreateParticipantView(false);
    setBulkDeleteParticipantView(false);
    // setView("participants");
    setErrorMessage("");
  };

  const handleCSVDeleteUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("csvFile", file);
    formData.append("user_id", userId);

    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/check-validations-and-save-file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const { invalidEmails, duplicateEmails } = response.data;

      if (invalidEmails.length === 0 && duplicateEmails.length === 0) {
        setIsButtonDisabled(false);
      } else {
        if (invalidEmails.length !== 0) {
          setErrorMessage(`File contains invalid emails ${invalidEmails}`);
          setIsButtonDisabled(true);
        } else if (duplicateEmails.length !== 0) {
          setErrorMessage(`File contains duplicate emails ${duplicateEmails}`);
          setIsButtonDisabled(true);
        }
      }
    } catch (error) {
      console.error("Error uploading CSV file for deletion", error);
      setIsButtonDisabled(true);
    }
  };

  return (
    <div className="manage-participants-container">
      <h2>Manage Participants</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleAddParticipant}>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="email-input"
          />
          <button type="submit" className="add-button" disabled={loading}>
            {loading ? "Adding..." : "Add Participant"}{" "}
            {/* Button text changes based on loading state */}
          </button>
        </div>
      </form>
      <button
        onClick={openBulkCreateParticipantModal}
        className="Participant-create-button"
      >
        Bulk Add Participants
      </button>
      <button
        onClick={openBulkDeleteParticipantModal}
        className="Participant-create-button"
      >
        Bulk Delete Participants
      </button>

      {/* Modals for bulk operations */}
      {bulkCreateParticipantView && view === "bulkCreateParticipant" && (
        <div className="bulk-create-participant-modal modal">
          <div className="modal-content">
            <h1 className="text-2xl font-bold mb-4">Bulk Add Participants</h1>
            <h2 className="text-sm font-bold mb-4 text-red-600"></h2>
            <h3 className="text-sm font-bold mb-4">
              No. of Participants in file: {csvRowCount}
            </h3>
            {errorMessage && (
              <p className="text-sm font-bold mb-4 text-red-600">
                {errorMessage}
              </p>
            )}
            <form
              onSubmit={handleBulkSaveParticipant}
              className="max-w-md mx-auto"
            >
              <div className="mb-4">
                <label
                  htmlFor="csvFile"
                  className="block text-sm font-semibold mb-2"
                >
                  Upload CSV File
                </label>
                <input
                  type="file"
                  id="csvFile"
                  accept=".csv"
                  onChange={handleCSVUpload}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              <div className="mb-4">
                <a
                  href="/SampleCSV.csv"
                  download
                  className="inline-block px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600"
                >
                  Download Sample CSV
                </a>
              </div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
                disabled={isButtonDisabled}
              >
                Save All
              </button>
              <button
                type="button"
                onClick={handleCloseModal}
                className="w-full bg-gray-300 text-gray-700 py-2 mt-2 rounded-md hover:bg-gray-400 transition duration-300"
              >
                Close
              </button>
            </form>
          </div>
        </div>
      )}

      {bulkDeleteParticipantView && view === "bulkDeleteParticipant" && (
        <div className="bulk-delete-participant-modal modal">
          <div className="modal-content">
            <h1 className="text-2xl font-bold mb-4">
              Bulk Delete Participants
            </h1>
            {errorMessage && (
              <p className="text-sm font-bold mb-4 text-red-600">
                {errorMessage}
              </p>
            )}
            <form
              onSubmit={handleBulkDeleteParticipantSubmit}
              className="max-w-md mx-auto"
            >
              <div className="mb-4">
                <label
                  htmlFor="csvFileDelete"
                  className="block text-sm font-semibold mb-2"
                >
                  Upload CSV File with Participant IDs to Delete
                </label>
                <input
                  type="file"
                  id="csvFileDelete"
                  accept=".csv"
                  onChange={handleCSVDeleteUpload}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              <div className="mb-4">
                <a
                  href="/SampleDeleteCSV.csv"
                  download
                  className="inline-block px-4 py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600"
                >
                  Download Sample Delete CSV
                </a>
              </div>
              <button
                type="submit"
                className="w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600 transition duration-300"
              >
                Delete All
              </button>
              <button
                type="button"
                onClick={handleCloseModal}
                className="w-full bg-gray-300 text-gray-700 py-2 mt-2 rounded-md hover:bg-gray-400 transition duration-300"
              >
                Close
              </button>
            </form>
          </div>
        </div>
      )}

      <div className="participants-list">
        <h3>Participants of Test:</h3>
        {loading ? (
          <p>Loading...</p> // Show loading message when data is being fetched
        ) : (
          <table>
            <thead>
              <tr>
                <th>Email</th>
                <th>Actions</th>
                <th>Email</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {participants.map((participant, index) =>
                index % 2 === 0 ? (
                  <tr key={participant.participant_id}>
                    <td>{participant.email}</td>
                    <td>
                      <button
                        className="delete-button"
                        onClick={() =>
                          handleDeleteParticipant(participant.participant_id)
                        }
                      >
                        <i className="fas fa-trash"></i>
                      </button>
                    </td>
                    {participants[index + 1] ? (
                      <>
                        <td>{participants[index + 1].email}</td>
                        <td>
                          <button
                            className="delete-button"
                            onClick={() =>
                              handleDeleteParticipant(
                                participants[index + 1].participant_id
                              )
                            }
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                        </td>
                      </>
                    ) : (
                      <>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        )}
      </div>
      <button onClick={handleGoToDashboard} className="dashboard-button">
        Go To Dashboard
      </button>
    </div>
  );
};

export default ManageParticipants;
