import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";

const EditQuestionAndTestcases = () => {
  const navigate = useNavigate();
  const { questionId } = useParams();
  const [questionData, setQuestionData] = useState({
    title: "",
    description: "",
    testCases: [],
  });

  useEffect(() => {
    getQuestionAndTestcases();
  }, []);

  const getQuestionAndTestcases = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-question-and-testcases-content`,
        { question_id: questionId }
      );
      setQuestionData(response.data[0]);
    } catch (error) {
      console.error("Error fetching question and test cases:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuestionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTestCaseChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTestCases = [...questionData.testCases];
    updatedTestCases[index] = {
      ...updatedTestCases[index],
      [name]: value,
    };
    setQuestionData((prevData) => ({
      ...prevData,
      testCases: updatedTestCases,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${import.meta.env.VITE_BASE_URL}/save-question-and-testcases-content`,
        {
          question_id: questionId,
          question_title: questionData.title,
          question_description: questionData.description,
          testcases: questionData.testCases,
        }
      );
      console.log("Save successful", response.data);
      navigate("/user/dashboard");
    } catch (error) {
      console.error("Error saving question and test cases:", error);
    }
  };

  const handleGoToDashboard = () => {
    navigate("/user/dashboard");
  };

  return (
    <div className="flex h-screen bg-gray-100">
      {/* Left Panel - Question Details */}
      <div className="w-1/2 p-8">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <h1 className="text-2xl font-bold mb-6">Edit Question</h1>
          
          <div className="mb-6">
            <label className="block text-sm font-semibold mb-2">Title:</label>
            <textarea
              name="title"
              value={questionData.title}
              onChange={handleInputChange}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
              rows="2"
            />
          </div>

          <div className="mb-6">
            <label className="block text-sm font-semibold mb-2">Description:</label>
            <textarea
              name="description"
              value={questionData.description}
              onChange={handleInputChange}
              className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500"
              rows="8"
            />
          </div>
        </div>
      </div>

      {/* Right Panel - Test Cases */}
      <div className="w-1/2 p-8">
        <div className="bg-white rounded-lg shadow-lg p-6 h-[calc(100vh-4rem)]">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Test Cases</h2>
            <button 
              onClick={handleGoToDashboard}
              className="px-4 py-2 bg-gray-600 text-white rounded-lg hover:bg-gray-700"
            >
              Dashboard
            </button>
          </div>

          <div className="h-[calc(100%-8rem)] overflow-y-auto pr-2">
            {questionData.testCases.map((testCase, index) => (
              <div key={testCase.id} className="bg-gray-50 p-3 rounded-lg mb-3">
                <h3 className="font-semibold mb-2 text-sm">Test Case {index + 1}</h3>
                
                <div className="mb-2">
                  <label className="block text-sm font-medium mb-1">Input:</label>
                  <textarea
                    name="inputContent"
                    value={testCase.inputContent}
                    onChange={(e) => handleTestCaseChange(index, e)}
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 text-sm"
                    rows="2"
                  />
                </div>

                <div className="mb-2">
                  <label className="block text-sm font-medium mb-1">Output:</label>
                  <textarea
                    name="outputContent"
                    value={testCase.outputContent}
                    onChange={(e) => handleTestCaseChange(index, e)}
                    className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500 text-sm"
                    rows="2"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">Points:</label>
                  <input
                    type="number"
                    name="points"
                    value={testCase.points}
                    onChange={(e) => handleTestCaseChange(index, e)}
                    className="w-24 p-2 border rounded focus:ring-2 focus:ring-blue-500 text-sm"
                    min="0"
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="border-t mt-4 pt-4 flex justify-end gap-4">
            <button
              type="button"
              onClick={handleGoToDashboard}
              className="px-6 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmit}
              className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditQuestionAndTestcases;