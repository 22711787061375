import React from 'react';
import '../../css/DocNavigation.css';

export const DocNavigation = ({ docs, selectedDoc, setSelectedDoc }) => {
  return (
    <nav className="cc-doc-navigation">
      <h2 className="cc-doc-navigation__title">Documentation</h2>
      <ul className="cc-doc-navigation__list">
        {docs.map((doc) => (
          <li
            key={doc.id}
            className={`cc-doc-navigation__item ${selectedDoc === doc.id ? 'cc-doc-navigation__item--active' : ''}`}
            onClick={() => setSelectedDoc(doc.id)}
          >
            {doc.title}
          </li>
        ))}
      </ul>
    </nav>
  );
};