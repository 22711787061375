import React from "react";
import { Link } from "react-router-dom";
import "../css/GetStarted.css";
import Navbar from "./Navbar";
// import Footer from "./Footer";
import FooterHome from "./FooterHome";

const GetStarted = () => {
  return (
    <div>
      <Navbar />
      <div className="get-started-container">
        {/* <h1 className="title">Welcome to CirrusCypher</h1>
        <p className="subtitle">
          Unlock your coding potential with personalized experiences.
        </p> */}

        <div className="options">
          <div className="option organization">
            <div className="card">
              <img
                src="/assets/org.jpg"
                alt="Organization Logo"
                className="get-started-logo"
              />
              <h2>Organization</h2>
              <p className="description">
                Manage and create tests for your organization. Monitor
                participants progress and performance.
              </p>
              <Link to="/signup" className="get-started-button">
                Sign Up as Organization
              </Link>
            </div>
          </div>

          <div className="option student">
            <div className="card">
              <img
                src="/assets/student.jpg"
                alt="Student Logo"
                className="get-started-logo"
              />
              <h2>Candidate</h2>
              <p className="description">
                Join the CirrusCypher community to explore and practice tests.
                Track your activity and performance.
              </p>
              <Link to="/candidate-signup" className="get-started-button">
                Sign up as Candidate
              </Link>
            </div>
          </div>

          {/* <div className="option admin">
            <div className="card">
              <img
                src="/assets/Admin.jpg"
                alt="Admin Logo"
                className="get-started-logo"
              />
              <h2>Admin</h2>
              <p className="description">
                Manage the platform settings, users, and exams. Ensure smooth
                operation and integrity of the system.
              </p>
              <Link to="/login" className="get-started-button">
                Admin Login
              </Link>
            </div>
          </div> */}
        </div>
      </div>
      {/* <Footer /> */}
      <FooterHome />
    </div>
  );
};

export default GetStarted;
