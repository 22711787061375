import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactInfo from "../components/ContactInfo";
import ContactQueryForm from "../components/ContactQueryForm";


const ContactUsPage = () => {
    return (
        <div style={{height:'100vh'}}>
      
     <ContactInfo/>
     {/* <ContactQueryForm/> */}
    
   
      {/* Add other sections/components here */}
    </div>
  );
};

export default ContactUsPage;