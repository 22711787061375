import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../css/CreateTest.css";

const CreateTest = ({ onClose, testId, onTestCreated }) => {
  const [testTitle, setTestTitle] = useState("");
  const [testDescription, setTestDescription] = useState("");
  const [testDuration, setTestDuration] = useState("30");
  const [testDate, setTestDate] = useState(new Date());
  const [testEndDate, setTestEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [questions, setQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [addedQuestions, setAddedQuestions] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState({});

  const navigate = useNavigate();
  

  useEffect(() => {
    fetchQuestions();
    fetchLanguages();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/api/questions`
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const fetchLanguages = async () => {
    try {
      const user_id = localStorage.getItem("user_id");
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-plan-wise-languages`,
        {
          user_id: user_id,
        }
      );
      setLanguages(response.data.queryResult);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  const handleAddQuestion = (questionId) => {
    const selectedLanguage = selectedLanguages[questionId];
    if (selectedLanguage) {
      setSelectedQuestions((prevSelectedQuestions) => [
        ...prevSelectedQuestions,
        { questionId, language: selectedLanguage },
      ]);
      setAddedQuestions((prevAddedQuestions) => [
        ...prevAddedQuestions,
        questionId,
      ]);
    }
  };

  const handleLanguageChange = (questionId, language) => {
    setSelectedLanguages((prevSelectedLanguages) => ({
      ...prevSelectedLanguages,
      [questionId]: language,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const createdBy = localStorage.getItem("user_id");
      const formattedTestDate = testDate.toISOString().split("T")[0];
      const formattedTestEndDate = testEndDate.toISOString().split("T")[0]; // Add this line
      const formattedStartTime = startTime.toTimeString().split(" ")[0];
      const formattedEndTime = endTime.toTimeString().split(" ")[0];
  
      const createResponse = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/tests/${testId}/create`,
        {
          test_id: testId,
          test_title: testTitle,
          test_description: testDescription,
          test_duration: testDuration,
          test_date: formattedTestDate,
          test_end_date: formattedTestEndDate, // Add this line
          start_time: formattedStartTime,
          end_time: formattedEndTime,
          created_by: createdBy,
          questions: selectedQuestions,
        }
      );
  
      if (createResponse.data.message !== 'Test created successfully') {
        throw new Error('Test creation failed.');
      }
  
      // Fetch the created test data
      const fetchTestResponse = await axios.get(
        `${import.meta.env.VITE_BASE_URL}/tests/${testId}`
      );
  
      const createdTest = fetchTestResponse.data;
      console.log('Created Test:', createdTest);
  
      if (!createdTest.test_title || !createdTest.test_id) {
        console.error("Fetched test is missing critical data.");
        return;
      }
  
      onTestCreated(createdTest);
      onClose(createdTest);
  
    } catch (error) {
      console.error("Error creating or fetching test:", error);
    }
  };
  
  
  
  const isQuestionAdded = (questionId) => addedQuestions.includes(questionId);

  return (
    <div className="create-test-wrapper">
     
      <div className="create-test-container">
        <div className="form-section">
          <h1>Create New Test</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="testTitle">Test Title</label>
              <input
                type="text"
                id="testTitle"
                value={testTitle}
                onChange={(e) => setTestTitle(e.target.value)}
                placeholder="Enter test title"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="testDescription">Test Description</label>
              <textarea
                id="testDescription"
                value={testDescription}
                onChange={(e) => setTestDescription(e.target.value)}
                placeholder="Enter test description"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="testDuration">Test Duration (in minutes)</label>
              <input
                type="number"
                id="testDuration"
                value={testDuration}
                onChange={(e) => setTestDuration(e.target.value)}
                min="1"
                placeholder="Enter test duration"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="testDate">Test Date</label>
              <DatePicker
                selected={testDate}
                onChange={(date) => setTestDate(date)}
                dateFormat="yyyy-MM-dd"
                className="form-control"
                required
              />
            
            </div>
            <div className="form-group">
  <label htmlFor="testEndDate">Test End Date</label>
  <DatePicker
    selected={testEndDate}
    onChange={(date) => setTestEndDate(date)}
    dateFormat="yyyy-MM-dd"
    className="form-control"
    required
    minDate={testDate} // Ensures end date can't be before start date
  />
</div>

            <div className="form-group time-group">
              <div className="time-input">
                <label htmlFor="startTime">Start Time</label>
                <DatePicker
                  selected={startTime}
                  onChange={(date) => setStartTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Start Time"
                  dateFormat="h:mm aa"
                  className="form-control"
                  required
                />
              </div>
              <div className="time-input">
                <label htmlFor="endTime">End Time</label>
                <DatePicker
                  selected={endTime}
                  onChange={(date) => setEndTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="End Time"
                  dateFormat="h:mm aa"
                  className="form-control"
                  required
                />
              </div>
            </div>
            <button type="submit" className="create-button">
              Create Test
            </button>
          </form>
        </div>
        <div className="questions-section">
          <h2>Available Questions</h2>
          <div className="questions-container">
            <table>
              <thead>
                <tr>
                  <th>Question Text</th>
                  <th>Select Language</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {questions.map((question) => (
                  <tr key={question.question_id}>
                    <td>{question.question_text}</td>
                    <td>
                      <select
                        onChange={(e) =>
                          handleLanguageChange(
                            question.question_id,
                            e.target.value
                          )
                        }
                        value={selectedLanguages[question.question_id] || ""}
                      >
                        <option value="" disabled>
                          Select language
                        </option>
                        {languages.map((language) => (
                          <option
                            key={language.language_id}
                            value={language.language}
                          >
                            {language.language}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <button
                        type="button"
                        onClick={() => handleAddQuestion(question.question_id)}
                        disabled={
                          isQuestionAdded(question.question_id) ||
                          !selectedLanguages[question.question_id]
                        }
                        className={
                          isQuestionAdded(question.question_id) ? "added" : ""
                        }
                      >
                        {isQuestionAdded(question.question_id)
                          ? "Added"
                          : "Add"}
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
           
          </div>
        
        </div>
       
      </div>
     
    </div>
  );
};

export default CreateTest;
