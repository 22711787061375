import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    TextField,
    Typography,
    Paper,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Tooltip,
    Modal,
    InputAdornment,
    Menu,
    MenuItem,
    ListItemText,
    Checkbox,
    Chip,
} from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreateMCQTest = ({ onClose, onTestCreated, editTest = null }) => {
    const [testTitle, setTestTitle] = useState(editTest ? editTest.testTitle : '');
    const [testDescription, setTestDescription] = useState(editTest ? editTest.testDescription : '');
    const [duration, setDuration] = useState(editTest ? editTest.duration : 30);
    const [testDate, setTestDate] = useState(editTest ? new Date(editTest.testDate) : new Date());
    const [testEndDate, setTestEndDate] = useState(editTest ? new Date(editTest.testEndDate) : new Date());
    const [startTime, setStartTime] = useState(editTest ? new Date(`2000-01-01T${editTest.startTime}`) : new Date());
    const [endTime, setEndTime] = useState(editTest ? new Date(`2000-01-01T${editTest.endTime}`) : new Date());
    const [questions, setQuestions] = useState([]);
    // const [selectedQuestions, setSelectedQuestions] = useState(editTest ? editTest.questions.map(q => q.mcq_question_id) : []);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedQuestionDetails, setSelectedQuestionDetails] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [filters, setFilters] = useState({
        type: [],
        difficulty: [],
        points: [],
    });
    const [initialSelectedQuestions, setInitialSelectedQuestions] = useState([]);
    const [deletedQuestions, setDeletedQuestions] = useState([]);

    useEffect(() => {
        fetchQuestions();
    }, []);

    useEffect(() => {
        if (editTest && editTest.questions) {
            const selectedIds = editTest.questions.map(q => q.mcqQuestionId);
            console.log("Setting initial selected questions:", selectedIds);
            setSelectedQuestions(selectedIds);
            setInitialSelectedQuestions(selectedIds); // Store initial state
        }
    }, [editTest]);

    const fetchQuestions = async () => {
        try {
            const userId = localStorage.getItem("user_id");
            const response = await axios.get(
                `${import.meta.env.VITE_BASE_URL}/api/org/question/fetch-questions/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }
            );
            setQuestions(response.data.data.questions);
        } catch (error) {
            console.error("Error fetching MCQ questions:", error);
        }
    };

    const handleAddQuestion = (questionId) => {
        console.log("Adding question:", questionId);
        if (!selectedQuestions.includes(questionId)) {
            setSelectedQuestions(prev => {
                const newSelected = [...prev, questionId];
                console.log("New selected questions:", newSelected);
                return newSelected;
            });

            // If this question was previously deleted, remove it from deletedQuestions
            if (deletedQuestions.includes(questionId)) {
                setDeletedQuestions(prev => prev.filter(id => id !== questionId));
            }
            toast.info('Question added to the test');
        }
    };


    const handleRemoveQuestion = (questionId) => {
        console.log("Removing question:", questionId);
        setSelectedQuestions(prev => {
            const newSelected = prev.filter(id => id !== questionId);
            console.log("New selected questions:", newSelected);
            return newSelected;
        });

        // If this question was in the initial selection, add it to deletedQuestions
        if (initialSelectedQuestions.includes(questionId)) {
            setDeletedQuestions(prev => [...new Set([...prev, questionId])]);
        }
        toast.info('Question removed from the test');
    };


    const handleViewQuestion = (question) => {
        setSelectedQuestionDetails(question);
        setViewModalOpen(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formattedTestDate = testDate.toISOString().split("T")[0];
            const formattedTestEndDate = testEndDate.toISOString().split("T")[0];

            const formatTime = (date) => {
                return date.toTimeString().split(':').slice(0, 2).join(':');
            };

            const formattedStartTime = formatTime(startTime);
            const formattedEndTime = formatTime(endTime);
            const filteredArray = selectedQuestions.filter(element => element !== null);
            const testData = {
                testTitle,
                testDescription,
                duration,
                testDate: formattedTestDate,
                testEndDate: formattedTestEndDate,
                startTime: formattedStartTime,
                endTime: formattedEndTime,
                questions: filteredArray.map(id => ({ questionId: id })),
            };

            let response;
            if (editTest) {
                response = await axios.put(
                    `${import.meta.env.VITE_BASE_URL}/api/org/test/update-test-data`,
                    {
                        ...testData,
                        mcqTestId: editTest.mcq_test_id,
                        deletedQuestions: [...new Set(deletedQuestions)] // Send unique deleted questions
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
            } else {
                const createdBy = localStorage.getItem("user_id");
                response = await axios.post(
                    `${import.meta.env.VITE_BASE_URL}/api/org/test/create-mcq-test`,
                    { ...testData, createdBy },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                );
            }

            if (response.data.success) {
                toast.success(editTest ? 'MCQ Test updated successfully!' : 'MCQ Test created successfully!');
                onTestCreated(response.data);
                onClose();
            } else {
                throw new Error(response.data.error || 'MCQ Test operation failed.');
            }

        } catch (error) {
            console.error("Error with MCQ test operation:", error);
            toast.error(error.response?.data?.message || 'Failed to perform MCQ Test operation. Please try again.');
        }
    };

    const handleFilterClick = (event) => {
        setFilterAnchorEl(event.currentTarget);
    };

    const handleFilterClose = () => {
        setFilterAnchorEl(null);
    };

    const handleFilterChange = (category, value) => {
        setFilters(prev => ({
            ...prev,
            [category]: prev[category].includes(value)
                ? prev[category].filter(item => item !== value)
                : [...prev[category], value]
        }));
    };

    const filteredQuestions = questions.filter(question =>
        (question.mcq_question_id.toLowerCase().includes(searchTerm.toLowerCase()) ||
            question.question_text.toLowerCase().includes(searchTerm.toLowerCase()) ||
            question.question_description.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (filters.type.length === 0 || filters.type.includes(question.question_type === 0 ? 'single' : 'multiple')) &&
        (filters.difficulty.length === 0 || filters.difficulty.includes(question.difficulty)) &&
        (filters.points.length === 0 || filters.points.includes(question.points.toString()))
    );

    const filterOptions = {
        type: [
            { value: 'single', label: 'Single Correct' },
            { value: 'multiple', label: 'Multiple Correct' },
        ],
        difficulty: ['easy', 'medium', 'hard'],
        points: ['1', '2', '3', '4', '5'],
    };

    const handleSelectAll = () => {
        setSelectedQuestions(filteredQuestions.map(q => q.mcq_question_id));
        toast.info('All filtered questions added to the test');
    };

    const handleDeselectAll = () => {
        // Add all initially selected questions that are being deselected to deletedQuestions
        const newDeletedQuestions = initialSelectedQuestions.filter(
            id => selectedQuestions.includes(id)
        );

        setDeletedQuestions(prev => [...new Set([...prev, ...newDeletedQuestions])]);
        setSelectedQuestions([]);
        toast.info('All questions removed from the test');
    };

    // Sort questions to show selected ones first
    const sortedQuestions = [...filteredQuestions].sort((a, b) => {
        const aSelected = selectedQuestions.includes(a.mcq_question_id);
        const bSelected = selectedQuestions.includes(b.mcq_question_id);
        return bSelected - aSelected;
    });

    return (
        <Box sx={{ p: 3, bgcolor: 'background.default', minHeight: '100vh' }}>
            <Button
                startIcon={<ArrowBackIcon />}
                onClick={onClose}
                sx={{ mb: 2, display: "flex", justifyContent: "flex-start" }}
            >
                Back to Tests
            </Button>
            <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom>
                    {editTest ? 'Edit MCQ Test' : 'Create New MCQ Test'}
                </Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Test Title"
                                value={testTitle}
                                onChange={(e) => setTestTitle(e.target.value)}
                                required
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                label="Test Description"
                                value={testDescription}
                                onChange={(e) => setTestDescription(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                fullWidth
                                type="number"
                                label="Test Duration (minutes)"
                                value={duration}
                                onChange={(e) => setDuration(e.target.value)}
                                required
                                InputProps={{ inputProps: { min: 1 } }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DatePicker
                                selected={testDate}
                                onChange={(date) => setTestDate(date)}
                                dateFormat="yyyy-MM-dd"
                                customInput={<TextField fullWidth label="Test Date" variant="outlined" />}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <DatePicker
                                selected={testEndDate}
                                onChange={(date) => setTestEndDate(date)}
                                dateFormat="yyyy-MM-dd"
                                customInput={<TextField fullWidth label="Test End Date" variant="outlined" />}
                                required
                                minDate={testDate} // This ensures end date can't be before start date
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                selected={startTime}
                                onChange={(date) => setStartTime(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Start Time"
                                dateFormat="h:mm aa"
                                customInput={<TextField fullWidth label="Start Time" variant="outlined" />}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                selected={endTime}
                                onChange={(date) => setEndTime(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="End Time"
                                dateFormat="h:mm aa"
                                customInput={<TextField fullWidth label="End Time" variant="outlined" />}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ mt: 4 }}>
                        <Typography variant="h6" gutterBottom>
                            Available Questions
                        </Typography>
                        <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Search questions..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    size="small"
                                    sx={{ width: '60%', mr: 1 }}
                                />
                                <Button
                                    variant="outlined"
                                    startIcon={<FilterListIcon />}
                                    onClick={handleFilterClick}
                                    size="small"
                                >
                                    Filter
                                </Button>
                                <Menu
                                    anchorEl={filterAnchorEl}
                                    open={Boolean(filterAnchorEl)}
                                    onClose={handleFilterClose}
                                >
                                    {Object.entries(filterOptions).map(([category, options]) => (
                                        <MenuItem key={category} style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                                                {category.charAt(0).toUpperCase() + category.slice(1)}
                                            </Typography>
                                            {options.map((option) => (
                                                <MenuItem key={option.value || option} dense>
                                                    <Checkbox
                                                        checked={filters[category].includes(option.value || option)}
                                                        onChange={() => handleFilterChange(category, option.value || option)}
                                                    />
                                                    <ListItemText primary={option.label || option} />
                                                </MenuItem>
                                            ))}
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                                    <Chip
                                        label={`Total: ${filteredQuestions.length}`}
                                        color="primary"
                                        variant="outlined"
                                        size="medium"
                                        sx={{ fontSize: '1rem', height: 'auto', '& .MuiChip-label': { px: 2, py: 1 } }}
                                    />
                                    <Chip
                                        label={`Selected: ${selectedQuestions.length}`}
                                        color="secondary"
                                        variant="outlined"
                                        size="medium"
                                        sx={{ fontSize: '1rem', height: 'auto', '& .MuiChip-label': { px: 2, py: 1 } }}
                                    />
                                    <Button onClick={handleSelectAll} variant="outlined" size="small">
                                        Select All Filtered
                                    </Button>
                                    <Button onClick={handleDeselectAll} variant="outlined" size="small">
                                        Deselect All
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>


                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Question Text</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Difficulty</TableCell>
                                        <TableCell>Points</TableCell>
                                        <TableCell>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sortedQuestions.map((question) => (
                                        <TableRow key={question.mcq_question_id}>
                                            <TableCell>
                                                <div dangerouslySetInnerHTML={{ __html: question.question_text }} />
                                            </TableCell>
                                            <TableCell>
                                                <div dangerouslySetInnerHTML={{ __html: question.question_description }} />
                                            </TableCell>
                                            <TableCell>{question.difficulty}</TableCell>
                                            <TableCell>{question.points}</TableCell>
                                            <TableCell>
                                                {selectedQuestions.includes(question.mcq_question_id) ? (
                                                    <Button
                                                        onClick={() => handleRemoveQuestion(question.mcq_question_id)}
                                                        color="error"
                                                    >
                                                        Remove
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        onClick={() => handleAddQuestion(question.mcq_question_id)}
                                                        color="success"
                                                    >
                                                        Add
                                                    </Button>
                                                )}
                                                <Tooltip title="View Details">
                                                    <IconButton onClick={() => handleViewQuestion(question)}>
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            {editTest ? 'Save Changes' : 'Create MCQ Test'}
                        </Button>
                    </Box>
                </form>
            </Paper>

            {/* View Question Modal */}
            <Modal
                open={viewModalOpen}
                onClose={() => setViewModalOpen(false)}
                aria-labelledby="question-details-modal"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}>
                    <Typography id="question-details-modal" variant="h6" component="h2">
                        Question Details
                    </Typography>
                    {selectedQuestionDetails && (
                        <>
                            <Typography variant="subtitle1">Question Text:</Typography>
                            <div dangerouslySetInnerHTML={{ __html: selectedQuestionDetails.question_text }} />
                            <Typography variant="subtitle1" mt={2}>Description:</Typography>
                            <div dangerouslySetInnerHTML={{ __html: selectedQuestionDetails.question_description }} />
                            <Typography variant="subtitle1" mt={2}>Difficulty: {selectedQuestionDetails.difficulty}</Typography>
                            <Typography variant="subtitle1">Points: {selectedQuestionDetails.points}</Typography>
                        </>
                    )}
                    <Button onClick={() => setViewModalOpen(false)} sx={{ mt: 2 }}>Close</Button>
                </Box>
            </Modal>
        </Box>
    );
};

export default CreateMCQTest;