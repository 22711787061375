import React, { useState, useEffect, useRef } from 'react';
import { 
  Grid, 
  Paper, 
  Typography, 
  Box,
  CircularProgress,
  useTheme,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  Collapse
} from '@mui/material';
import {
  AssignmentTurnedIn as AssignmentIcon,
  School as SchoolIcon,
  EmojiEvents as AchievementIcon,
  Leaderboard as LeaderboardIcon,
  ExpandLess,
  ExpandMore
} from '@mui/icons-material';
import { BarChart, Bar,LineChart, Line,  XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Simulated API call with hierarchical test data
const fetchDashboardData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        totalQuestionsSolved: 150,
        totalTestsTaken: 45,
        overallScore: 85,
        rank: 15,
        testResults: {
          'Python Basics': [
            { name: 'Python If-Else', difficulty: 'Easy', score: 9, maxScore: 10 },
            { name: 'Arithmetic Operators', difficulty: 'Easy', score: 10, maxScore: 10 },
            { name: 'Python: Division', difficulty: 'Easy', score: 10, maxScore: 10 },
            { name: 'Loops', difficulty: 'Easy', score: 9, maxScore: 10 },
            { name: 'Write a function', difficulty: 'Medium', score: 8, maxScore: 10 },
          ],
          'JavaScript Challenges': [
            { name: 'Variables and Types', difficulty: 'Easy', score: 9, maxScore: 10 },
            { name: 'Functions and Scope', difficulty: 'Medium', score: 13, maxScore: 15 },
            { name: 'Arrays and Objects', difficulty: 'Medium', score: 12, maxScore: 15 },
          ],
          'Data Structures with C++': [
            { name: 'Linked Lists', difficulty: 'Medium', score: 16, maxScore: 20 },
            { name: 'Binary Trees', difficulty: 'Hard', score: 18, maxScore: 25 },
            { name: 'Sorting Algorithms', difficulty: 'Medium', score: 17, maxScore: 20 },
          ],
        },
        progressOverTime: [
          { date: '2024-07-01', score: 70 },
          { date: '2024-07-15', score: 75 },
          { date: '2024-08-01', score: 80 },
          { date: '2024-08-15', score: 85 },
          { date: '2024-08-30', score: 90 },
        ]
      });
    }, 1000);
  });
};

const StatCard = ({ title, value, icon: Icon }) => {
  const theme = useTheme();
  
  return (
    <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" component="h3" color="text.secondary">
          {title}
        </Typography>
        <Icon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
      </Box>
      <Typography variant="h4" component="p" fontWeight="bold" mt={2}>
        {value}
      </Typography>
    </Paper>
  );
};

const ScrollableLanguagePerformanceChart = ({ data }) => {
  const theme = useTheme();
  const scrollContainerRef = useRef(null);

  const colors = {
    Easy: theme.palette.success.main,
    Medium: theme.palette.warning.main,
    Hard: theme.palette.error.main
  };

  const chartData = Object.entries(data).map(([language, tests]) => {
    const languageData = { language };
    tests.forEach(test => {
      if (!languageData[test.difficulty]) languageData[test.difficulty] = 0;
      languageData[test.difficulty] += (test.score / test.maxScore) * 100;
    });
    ['Easy', 'Medium', 'Hard'].forEach(difficulty => {
      if (languageData[difficulty]) {
        languageData[difficulty] = +(languageData[difficulty] / tests.filter(t => t.difficulty === difficulty).length).toFixed(2);
      }
    });
    return languageData;
  });

  const handleWheel = (e) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft += e.deltaY;
    }
  };

  return (
    <Box
      ref={scrollContainerRef}
      onWheel={handleWheel}
      sx={{
        width: '100%',
        overflowX: 'auto',
        '&::-webkit-scrollbar': {
          height: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.palette.grey[300],
          borderRadius: '10px',
        },
      }}
    >
      <Box sx={{ width: Math.max(600, chartData.length * 100) + 'px', height: '400px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="language" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="Easy" name="Easy" fill={colors.Easy} />
            <Bar dataKey="Medium" name="Medium" fill={colors.Medium} />
            <Bar dataKey="Hard" name="Hard" fill={colors.Hard} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

const ProgressChart = ({ data }) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer width="100%" height={300}>
    <LineChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Line 
        type="monotone" 
        dataKey="score" 
        stroke={theme.palette.primary.main} 
        strokeWidth={2}
        dot={{ r: 4, fill: theme.palette.primary.main }}
        activeDot={{ r: 8 }}
      />
    </LineChart>
  </ResponsiveContainer>
);
};


const TestList = ({ data }) => {
  const [open, setOpen] = useState({});
  const theme = useTheme();

  const handleClick = (language) => {
    setOpen(prev => ({ ...prev, [language]: !prev[language] }));
  };

  const getDifficultyColor = (difficulty) => {
    switch (difficulty) {
      case 'Easy': return theme.palette.success.main;
      case 'Medium': return theme.palette.warning.main;
      case 'Hard': return theme.palette.error.main;
      default: return theme.palette.text.primary;
    }
  };

  return (
    <List>
      {Object.entries(data).map(([language, tests]) => (
        <React.Fragment key={language}>
          <ListItem button onClick={() => handleClick(language)}>
            <ListItemText primary={language} />
            {open[language] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open[language]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {tests.map((test, index) => (
                <ListItem key={index} sx={{ pl: 4 }}>
                  <ListItemText 
                    primary={test.name}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color={getDifficultyColor(test.difficulty)}
                        >
                          {test.difficulty}
                        </Typography>
                        {" - Score: "}
                        {test.score}/{test.maxScore}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  );
};

const DashboardOverview = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();

  useEffect(() => {
    fetchDashboardData().then(setDashboardData);
  }, []);

  if (!dashboardData) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box sx={{ flexGrow: 1, p: 3, backgroundColor: theme.palette.background.default }}>
      <Typography variant="h4" component="h2" gutterBottom sx={{ color: theme.palette.text.primary }}>
        Dashboard Overview
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Questions Solved"
            value={dashboardData.totalQuestionsSolved}
            icon={AssignmentIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Tests Taken"
            value={dashboardData.totalTestsTaken}
            icon={SchoolIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Overall Score"
            value={`${dashboardData.overallScore}%`}
            icon={AchievementIcon}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Rank"
            value={`#${dashboardData.rank}`}
            icon={LeaderboardIcon}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Tabs value={activeTab} onChange={handleTabChange} centered>
              <Tab label="Performance Overview" />
              <Tab label="Test Details" />
              <Tab label="Progress Over Time" />
            </Tabs>
            <Box sx={{ mt: 2 }}>
              {activeTab === 0 && <ScrollableLanguagePerformanceChart data={dashboardData.testResults} />}
              {activeTab === 1 && <TestList data={dashboardData.testResults} />}
              {activeTab === 2 && <ProgressChart data={dashboardData.progressOverTime} />}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardOverview;