import React from 'react';
import '../css/Legal.css';  // Import the shared legal CSS

const RefundPolicy = () => (
  <div className="legal-content">
    <h2>Refund Policy</h2>
    <p className="updated-date">Last updated on 29th September 2024</p>

    <p>
      TECHNOCIRRUS SOLUTIONS PRIVATE LIMITED believes in helping its customers as far as possible, and 
      has therefore a liberal cancellation policy. Under this policy:
    </p>

    <ul>
      <li>
        Cancellations will be considered only if the request is made within 7 days of placing the order. 
        However, the cancellation request may not be entertained if the orders have been communicated to the 
        vendors/merchants and they have initiated the process of shipping them.
      </li>
      <li>
        TECHNOCIRRUS SOLUTIONS PRIVATE LIMITED does not accept cancellation requests for perishable items 
        like flowers, eatables, etc. However, refund/replacement can be made if the customer establishes that 
        the quality of the product delivered is not good.
      </li>
      <li>
        In case of receipt of damaged or defective items, please report the same to our Customer Service team. 
        The request will, however, be entertained once the merchant has checked and determined the same at his 
        own end. This should be reported within 7 days of receipt of the products.
      </li>
      <li>
        In case you feel that the product received is not as shown on the site or as per your expectations, 
        you must bring it to the notice of our customer service within 7 days of receiving the product. 
        The Customer Service Team, after looking into your complaint, will take an appropriate decision.
      </li>
      <li>
        In case of complaints regarding products that come with a warranty from manufacturers, please refer 
        the issue to them.
      </li>
      <li>
        In case of any refunds approved by TECHNOCIRRUS SOLUTIONS PRIVATE LIMITED, it will take 3-5 days for 
        the refund to be processed to the end customer.
      </li>
    </ul>

    <p className="footer-note">
      Please note that by making a purchase, you agree to this refund policy.
    </p>

    <footer className="legal-footer">
  <p>
    For any questions about these Terms, please contact us at  
    <a href="mailto:info@technocirrus.com"> info@technocirrus.com </a> or call us at 
    <a href="tel:+919881507833">  +91 9175046101 </a>
  </p>
</footer> 
  </div>
);

export default RefundPolicy;
