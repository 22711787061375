import React from "react";
import { Link } from "react-router-dom";
import "../../css/HomeLandingPage.css";

const HomeLandingPage = () => {
  return (
    <section className="home-landing">
      <div className="landing-container">
        <div className="landing-content">
          <div className="landing-image">
            <img src="/assets/LandingPage.png" alt="Landing Page Screenshot" />
          </div>
          <div className="landing-text">
            <h1 className="landing-title">Experience a User-Friendly Environment</h1>
            <p className="landing-description">
              We now support 30+ languages, multiple test cases, and unlimited participant capacity in our premium feature.
            </p>
            <Link to="/subscription" className="landing-button">Start Free</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeLandingPage;
