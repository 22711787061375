import { useEffect } from "react";


import Footer from "../components/Footer";

import BlogsMain from "../components/Blogs/BlogsMain";


const BlogsPage = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      
    
      <BlogsMain/>
     
    </>
  );
};

export default BlogsPage;