import React, { useState } from 'react';
import Modal from 'react-modal';
import '../../css/VideoPlayer.css'; // We'll use custom CSS for styling

// Modal styling (customizable)
const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0',
    width: '80%',
    maxWidth: '900px',
    borderRadius: '12px',
    overflow: 'hidden',
  },
};

Modal.setAppElement('#root'); // For accessibility, this should be set to the root of your app

const VideoPlayer = () => {
  const videoCategories = [
    {
      title: 'Data Structures & Algorithms',
      videos: [
        { title: 'DSA Basics', videoId: 'BBpAmxU_NQo' }, // Replace with real video IDs
        { title: 'Sorting Algorithms', videoId: 'ByLlEk7zmyw' },
        { title: 'Dynamic Programming', videoId: '0BhhiQGDbEA' },
        { title: 'Graphs', videoId: '59fUtYYz7ZU' },
        { title: 'Trees', videoId: '9oTV7fDEaCY' },
        { title: 'Recursion', videoId: '5Boqfjissv0' },
        { title: 'Backtracking', videoId: 'bRs6E_SL2Tk' },
        { title: 'Greedy Algorithms', videoId: 'v0eQ4nXJjsk' },
        { title: 'Divide and Conquer', videoId: 'YOh6hBtX5l0' },
        { title: 'Binary Search', videoId: '9HlbVEVt_Y0' },
      ],
    },
    {
        title: 'System Design',
        videos: [
          { title: 'System Design Basics', videoId: 'P_eh1b6vE-4' },
          { title: 'Designing a URL Shortener', videoId: '4fTWfFg91V0' },
          { title: 'How to Scale Web Applications', videoId: 'ejofP2VKu-4' },
          { title: 'CAP Theorem Explained', videoId: 'BHqjEjzAicA' },
          { title: 'Database Sharding', videoId: 'be6PLMKKSto' },
          { title: 'Designing Instagram', videoId: 'QmX2NPkJTKg' },
          { title: 'Microservices Architecture', videoId: 'lL_j7ilk7rc' },
          { title: 'Load Balancing Strategies', videoId: 'chyZRNT7eEo' },
          { title: 'Caching for System Design', videoId: '6GY1akbxyEo' },
          { title: 'Designing a Chat System', videoId: 'xyLO8ZAk2KE' },
        ],
      },
      {
        title: 'Coding Patterns & Problem Solving',
        videos: [
          { title: 'Sliding Window Pattern', videoId: 'p-ss2JNynmw' },
          { title: 'Two Pointers Pattern', videoId: 'On03HWe2tZM' },
          { title: 'Fast & Slow Pointers', videoId: 'XWyXy2aNrXM' },
          { title: 'Merge Intervals Pattern', videoId: 'dzNIPX7HY6A' },
          { title: 'Cyclic Sort Pattern', videoId: 'Wlk5-p_IHiQ' },
          { title: 'In-Place Reversal of a LinkedList', videoId: 'XgABnoJLtG4' },
          { title: 'Subsets Pattern', videoId: '3tpjp5h3M6Y' },
          { title: 'Top K Elements Pattern', videoId: 'xjC8LNJByrw' },
          { title: 'Backtracking Problems', videoId: '51Zy1ULau1s' },
          { title: 'Dynamic Programming Patterns', videoId: 'aPQY__2H3tE' },
        ],
      },
      {
        title: 'Behavioral & Soft Skills',
        videos: [
          { title: 'Behavioral Interview Preparation', videoId: '2uM7gYuOvr4' },
          { title: 'STAR Method for Behavioral Questions', videoId: 'ZSSID5mp93o' },
          { title: 'Answering Conflict-Resolution Questions', videoId: 'CgZRtOLwcS8' },
          { title: 'Handling Failure Questions', videoId: 'YuVqJZqxJJY' },
          { title: 'How to Talk About Strengths & Weaknesses', videoId: 'M5905oDA34c' },
          { title: 'Effective Communication in Interviews', videoId: 'ZxYv61_wH00' },
          { title: 'Leadership & Teamwork Stories', videoId: 'ZLtO_7LjzVg' },
          { title: 'Growth Mindset in Interviews', videoId: 'FpN1yQap_is' },
          { title: 'Time Management & Organization Questions', videoId: 'RvE5VrivqHw' },
          { title: 'How to Approach Situational Questions', videoId: 'epKb1L9nVZw' },
        ],
      },
      {
        title: 'Aptitude & Problem Solving',
        videos: [
          { title: 'Basic Math Problems', videoId: 'V2azCSchs58' },
          { title: 'Number Theory for Coders', videoId: 'Eot3kF5EeW4' },
          { title: 'Time & Work Problems', videoId: 'KE7tQf9spPg' },
          { title: 'Probability in Coding Interviews', videoId: 'GfSCnbsZHm4' },
          { title: 'Logical Puzzles Explained', videoId: 'r2OPVVRZfNo' },
          { title: 'Common Aptitude Questions', videoId: 'V2azCSchs58' },
          { title: 'Data Interpretation Skills', videoId: '97DDlwXomyU' },
          { title: 'Permutations & Combinations', videoId: 'ETiRE7N7pEI' },
          { title: 'Advanced Number Systems', videoId: 'bJCdFBfSR5Q' },
          { title: 'Analytical Problem Solving', videoId: 'QYCSvBab51Y' },
        ],
      },
      {
        title: 'JavaScript & Web Development',
        videos: [
          { title: 'JavaScript Basics', videoId: 'upDLs1sn7g4' },
          { title: 'Closures & Callbacks in JS', videoId: 'z-H8S83jJec' },
          { title: 'Understanding Promises', videoId: 'DHvZLI7Db8E' },
          { title: 'Event Loop in JavaScript', videoId: '8aGhZQkoFbQ' },
          { title: 'JavaScript Design Patterns', videoId: 'tv-_1er1mWI' },
          { title: 'Asynchronous JavaScript', videoId: 'ZYb_ZU8LNxs' },
          { title: 'ReactJS Basics', videoId: 'DLX62G4lc44' },
          { title: 'Redux in ReactJS', videoId: 'JZQWKYjfZlQ' },
          { title: 'Handling Forms in React', videoId: '7Vo_VCcWupQ' },
          { title: 'Building Responsive Web Apps', videoId: 'HG10yrq1pbk' },
        ],
      },
      {
        title: 'Mock Interviews & Coding Challenges',
        videos: [
          { title: 'Mock Coding Interview 1', videoId: '1qw5ITr3k9E' },
          { title: 'Mock Coding Interview 2', videoId: 'EirBuUUPbio' },
          { title: 'Mock System Design Interview', videoId: 'S1DvEdR0iUo' },
          { title: 'Leetcode Hard Problem Walkthrough', videoId: 'DjYZk8nrXVY' },
          { title: 'Live Coding Challenge', videoId: '7-FUiAFwEVA' },
          { title: 'Google Interview Prep', videoId: 'a5Z7pZgVfcQ' },
          { title: 'Amazon Interview Walkthrough', videoId: '6p1m2nCE7jE' },
          { title: 'Coding Interview Tips', videoId: 'nf6vrGcGDSI' },
          { title: 'Phone Screen Interview Simulation', videoId: 'ffDWJOmPQIQ' },
          { title: 'Pair Programming Challenge', videoId: 'H3ScYTN_ojw' },
        ],
      }
    // Other categories like Aptitude, Java Programming, etc.
  ];

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState('');
  const [currentPage, setCurrentPage] = useState(Array(videoCategories.length).fill(0)); // State for pagination

  // Constants for pagination
  const videosPerPage = 6; // Number of videos to display at once

  const openModal = (videoId) => {
    setCurrentVideoId(videoId);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideoId('');
  };

  const nextPage = (index) => {
    const totalPages = Math.ceil(videoCategories[index].videos.length / videosPerPage);
    if (currentPage[index] < totalPages - 1) {
      setCurrentPage((prev) => {
        const newPage = [...prev];
        newPage[index] += 1;
        return newPage;
      });
    }
  };

  const prevPage = (index) => {
    if (currentPage[index] > 0) {
      setCurrentPage((prev) => {
        const newPage = [...prev];
        newPage[index] -= 1;
        return newPage;
      });
    }
  };

  return (
    <div className="video-player-container">
      <h1 className="main-title">Learn Coding with Tutorials</h1>
      <p className="main-description">Curated best videos from YouTube to help you on your learning journey.</p>
      {/* Loop through each category */}
      {videoCategories.map((category, categoryIndex) => {
        // Paginate video cards
        const currentVideos = category.videos.slice(
          currentPage[categoryIndex] * videosPerPage,
          (currentPage[categoryIndex] + 1) * videosPerPage
        );

        const totalPages = Math.ceil(category.videos.length / videosPerPage);

        return (
          <div key={categoryIndex} className="category-section">
            <h2 className="category-title">{category.title}</h2>

            <div className="video-grid-container">
              {/* Left arrow */}
              <button
                className={`arrow-btn prev ${currentPage[categoryIndex] === 0 ? 'disabled' : ''}`}
                onClick={() => prevPage(categoryIndex)}
              >
                &#9664;
              </button>

              <div className="video-grid">
                {currentVideos.map((video, idx) => (
                  <div
                    key={idx}
                    className="video-card"
                    onClick={() => openModal(video.videoId)}
                  >
                    <img
                      src={`https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg`}
                      alt={video.title}
                      className="video-thumbnail"
                    />
                    <p className="video-title">{video.title}</p>
                  </div>
                ))}
              </div>

              {/* Right arrow */}
              <button
                className={`arrow-btn next ${currentPage[categoryIndex] === totalPages - 1 ? 'disabled' : ''}`}
                onClick={() => nextPage(categoryIndex)}
              >
                &#9654;
              </button>
            </div>
          </div>
        );
      })}

      {/* Modal for YouTube video player */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="YouTube Video Player"
      >
        <div className="modal-header">
          <button className="close-button" onClick={closeModal}>
            &times;
          </button>
        </div>
        <div className="modal-body">
          {currentVideoId && (
            <iframe
              width="100%"
              height="500px"
              src={`https://www.youtube.com/embed/${currentVideoId}?autoplay=1`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              title="YouTube Video Player"
            ></iframe>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default VideoPlayer;