import React from 'react';
import '../../css/DocContentDisplay.css';
import { OrgDashboard } from './OrgDashboard'; // Import the new component
import { AdminDashboard } from './AdminDashboard';
import { NavBar } from '../Navbar';

export const DocContentDisplay = ({ selectedDoc }) => {
  const renderOverallGuide = () => (
    <div className="cc-doc-content">
      <h1 className="cc-doc-content__title">Introduction</h1>
      <p><strong>Welcome to CirrusCypher</strong>, the all-in-one coding test and assessment platform designed to streamline the exam process for organizations, admins, and candidates. Whether you're an organization conducting assessments, an admin overseeing platform operations, or a candidate preparing for coding challenges, CirrusCypher offers a comprehensive solution that empowers all users to achieve their objectives seamlessly.</p>
      <p>This guide will walk you through the platform, highlighting its key features, functions, and the value it brings to each user role.</p>
      
      <img src="/Documentation/Homepage.png" alt="CirrusCypher platform overview" className="cc-doc-content__image" />

      <h2 className="cc-doc-content__subtitle"> 1) OVERVIEW OF ENTITIES IN CIRRUScypher</h2>
      <p>CirrusCypher is built around three core user types:</p>
      <ul className="cc-doc-content__list">
        <li><strong>Organizers</strong>: The exam conductors, responsible for creating and managing tests, participants, and results.</li>
        <li><strong>Admins</strong>: The platform managers who maintain the health of the platform, overseeing organizations, and setting up public practice tests.</li>
        <li><strong>Candidates</strong>: The individuals participating in tests, either through organizational invites or public practice tests.</li>
      </ul>

      <img src="/Documentation/get-started.png" alt="CirrusCypher entities overview" className="cc-doc-content__image" />

      <h2 className="cc-doc-content__subtitle">2. Key Features by User Role</h2>
      
      <h3 className="cc-doc-content__subsubtitle">A. Organizers: Efficient Test Management & Performance Tracking</h3>
      <p><strong>Organizers</strong> leverage the <strong>Organization Dashboard</strong> to conduct, manage, and evaluate coding tests for candidates. The dashboard is designed for intuitive navigation, making it easy to create and customize coding assessments. Here are the main features available to organizers:</p>
      
      <img src="/Documentation/Organization-dashboard.png" alt="Organization Dashboard" className="cc-doc-content__image" />

      <h4 className="cc-doc-content__subsubsubtitle">1. Test Creation & Management:</h4>
      <ul className="cc-doc-content__list">
        <li><strong>Create and Edit Tests</strong>: Organizers can create tailored tests with unique questions and test cases. Each question can have assigned test cases, points, and other relevant metadata to define difficulty and importance.</li>
        <li><strong>Bulk Actions</strong>: Organizers can use bulk features to upload multiple questions, test cases, or candidates at once—saving time and effort.</li>
        <li><strong>Test Customization</strong>: Customize time limits, test conditions, and other parameters to align with your organization's specific needs.</li>
      </ul>

      <h4 className="cc-doc-content__subsubsubtitle">2. Participant Management:</h4>
      <ul className="cc-doc-content__list">
        <li><strong>Add Participants</strong>: Easily add candidates to a test through manual input or bulk upload.</li>
        <li><strong>Assign Specific Tests</strong>: Organizers can assign individual candidates to specific tests and ensure appropriate distribution of test slots and opportunities.</li>
      </ul>

      <h4 className="cc-doc-content__subsubsubtitle">3. Score Review & Analysis:</h4>
      <ul className="cc-doc-content__list">
        <li><strong>Real-time Progress Tracking</strong>: Monitor test progress in real time with an intuitive dashboard that gives insight into how candidates are performing as they take the test.</li>
        <li><strong>Detailed Scorecards</strong>: Review comprehensive scorecards for each participant, including test completion status, scores, and breakdowns of correct/incorrect answers.</li>
        <li><strong>Performance Analytics</strong>: View analytical insights to better understand test results, identify trends, and make informed decisions for candidate selection.</li>
      </ul>

      <h3 className="cc-doc-content__subsubtitle">B. Admins: Platform Governance & Public Test Creation</h3>
      <p>The Admin Dashboard allows admins to oversee the entire CirrusCypher ecosystem, ensuring a well-maintained, streamlined experience for all users. Admins can manage organizational access, control platform subscriptions, and facilitate practice environments for candidates.</p>
      
      <img src="/Documentation/Admin-dashboard.png" alt="Admin Dashboard" className="cc-doc-content__image" />

      <h4 className="cc-doc-content__subsubsubtitle">1. Organization Management:</h4>
      <ul className="cc-doc-content__list">
        <li><strong>Monitor Registered Organizations</strong>: Track all organizations registered with CirrusCypher, including their subscription plans and status.</li>
        <li><strong>Subscription Management</strong>: View active plans, check expiration dates, and update subscription statuses for organizations.</li>
        <li><strong>Organization Removal</strong>: Admins have the authority to delete organizations and revoke their access to the platform if necessary.</li>
      </ul>

      <h4 className="cc-doc-content__subsubsubtitle">2. Public Test Creation:</h4>
      <ul className="cc-doc-content__list">
        <li><strong>Public Practice Tests</strong>: Admins can create and manage public tests for candidates looking to practice and improve their coding skills. These tests serve as a learning tool for individuals who may not be affiliated with any particular organization but want to sharpen their coding abilities.</li>
        <li><strong>Test Customization for Candidates</strong>: Admins can adjust public practice tests to cover a variety of difficulty levels and topics, giving candidates a wide array of challenges to tackle.</li>
        <li><strong>Usage Analytics</strong>: Admins can track the usage and performance of public tests, gaining insights into candidate engagement and performance trends across the platform.</li>
      </ul>

      <h3 className="cc-doc-content__subsubtitle">C. Candidates: Seamless Test Participation and Practice Opportunities</h3>
      
      <img src="/Documentation/Candidate Profile.png" alt="Candidate Interface" className="cc-doc-content__image" />
      
      <p>Candidates engage with the CirrusCypher platform in two key ways:</p>
      <ul className="cc-doc-content__list">
        <li>Through <strong>invited participation</strong> in tests created by an organization.</li>
        <li>By <strong>directly signing up</strong> to practice on public tests created by admins.</li>
      </ul>

      <h4 className="cc-doc-content__subsubsubtitle">1. Invited Test Participation:</h4>
      <ul className="cc-doc-content__list">
        <li><strong>Test Access</strong>: Candidates receive a unique invitation to participate in a specific test. They can log in to the platform, view their assigned tests, and begin as per the organizer's instructions.</li>
        <li><strong>Real-Time Interface</strong>: The test interface allows candidates to track time, view test cases, and submit answers in real time. Instant feedback on test case pass/fail results helps them gauge their performance during the test.</li>
      </ul>

      <h4 className="cc-doc-content__subsubsubtitle">2. Public Test Participation:</h4>
      <ul className="cc-doc-content__list">
        <li><strong>Direct Signup</strong>: Candidates who are not part of an organization can still sign up directly for the platform and participate in public practice tests designed by the admin team.</li>
        <li><strong>Skill Development</strong>: Public tests offer a valuable opportunity for candidates to practice and enhance their coding skills without waiting for an organizational invite.</li>
        <li><strong>Performance Analytics</strong>: After completing tests, candidates can view their performance, review areas for improvement, and track progress over time.</li>
      </ul>

      <h2 className="cc-doc-content__subtitle">3. How CirrusCypher Enhances Your Assessment Process</h2>
      <p>CirrusCypher is built to maximize efficiency and effectiveness at each stage of the coding test lifecycle, from test creation to performance analysis. By consolidating testing, management, and analytics into one easy-to-use platform, CirrusCypher reduces administrative workload while enhancing the overall testing experience.</p>

      <h3 className="cc-doc-content__subsubtitle">Benefits for Organizations:</h3>
      <ul className="cc-doc-content__list">
        <li><strong>Streamlined Operations</strong>: Conduct multiple tests simultaneously, manage large numbers of candidates effortlessly, and access detailed results with just a few clicks.</li>
        <li><strong>Data-Driven Insights</strong>: Access performance metrics to make data-driven decisions about candidates and optimize future tests.</li>
      </ul>

      <h3 className="cc-doc-content__subsubtitle">Benefits for Admins:</h3>
      <ul className="cc-doc-content__list">
        <li><strong>Complete Control</strong>: Manage every aspect of platform usage, ensuring that the right organizations and users have access to the right tools.</li>
        <li><strong>Public Engagement</strong>: Expand the platform's reach by creating engaging public tests that attract a wide pool of coding talent.</li>
      </ul>

      <h3 className="cc-doc-content__subsubtitle">Benefits for Candidates:</h3>
      <ul className="cc-doc-content__list">
        <li><strong>Real-World Preparation</strong>: Test under real-world conditions and receive instant feedback, allowing for continuous skill improvement.</li>
        <li><strong>Accessible Learning</strong>: Access a library of public tests at any time to practice, enhance coding skills, and track progress.</li>
      </ul>

      <h2 className="cc-doc-content__subtitle">4. Getting Started with CirrusCypher</h2>
      <p>Whether you're an organization, admin, or candidate, getting started with CirrusCypher is simple:</p>
      <ul className="cc-doc-content__list">
        <li><strong>Organizations</strong>: Sign up for an account and begin creating tests immediately. Use the Organization Dashboard to add participants and monitor performance.</li>
        <li><strong>Admins</strong>: Set up platform configurations, manage organizations, and create public practice tests.</li>
        <li><strong>Candidates</strong>: Sign up directly or join through an organization invitation and start improving your coding skills with practice tests or formal assessments.</li>
      </ul>

      <h2 className="cc-doc-content__subtitle">5. Conclusion</h2>
      <p>CirrusCypher is the ultimate solution for modern coding assessments, offering robust tools and insights for all stakeholders involved. With features that cater to organizers, admins, and candidates, CirrusCypher ensures a seamless and efficient testing process. Embrace the power of automation, analytics, and an intuitive user interface to make your coding assessments more productive, effective, and insightful.</p>
    </div>
  );

  const renderContent = () => {
    switch (selectedDoc) {
      case 'overall-guide':
        return renderOverallGuide();
        case 'org-dashboard':
            return <OrgDashboard />; // Use the new OrgDashboard component
            case 'admin-dashboard':
                return <AdminDashboard />;
      default:
        return <div>Select a document from the navigation menu</div>;
    }
  };

  return (
    <div className="cc-doc-content-display">
      {renderContent()}
    </div>
  );
};