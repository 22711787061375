import React from 'react';
import Overview from './Overview';
import TestHistory from './TestHistory';
import PublicTests from './PublicTests';
import Achievements from './Achievements';
import ProfileSettings from './ProfileSettings';
import '../../css/MainContent.css';

const MainContent = ({ selected }) => {
    return (
        <div className="main-content">
            {selected === 'overview' && <Overview />}
            {selected === 'test-history' && <TestHistory />}
            {selected === 'public-tests' && <PublicTests />}
            {selected === 'achievements' && <Achievements />}
            {selected === 'profile-settings' && <ProfileSettings />}
        </div>
    );
};

export default MainContent;