import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const AdminEditQuestionAndTestcases = () => {
  const navigate = useNavigate();
  const { questionId } = useParams();
  const [questionData, setQuestionData] = useState({
    title: "",
    description: "",
    testCases: [],
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    getQuestionAndTestcases();
  }, []);

  const getQuestionAndTestcases = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/get-public-question-and-testcases-content`,
        { question_id: questionId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setQuestionData(response.data[0]);
    } catch (error) {
      console.error("Error fetching question and test cases:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuestionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTestCaseChange = (index, e) => {
    const { name, value } = e.target;
    const updatedTestCases = [...questionData.testCases];
    updatedTestCases[index] = {
      ...updatedTestCases[index],
      [name]: value,
    };
    setQuestionData((prevData) => ({
      ...prevData,
      testCases: updatedTestCases,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${import.meta.env.VITE_BASE_URL}/save-public-question-and-testcases-content`,
        {
          question_id: questionId,
          question_title: questionData.title,
          question_description: questionData.description,
          testcases: questionData.testCases,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Save successful", response.data);
      navigate("/admin-questions");
    } catch (error) {
      console.error("Error saving question and test cases:", error);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6">Edit Question</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Title:
          </label>
          <input
            type="text"
            name="title"
            value={questionData.title}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Description:
          </label>
          <textarea
            name="description"
            value={questionData.description}
            onChange={handleInputChange}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-32"
          />
        </div>
        <h2 className="text-xl font-semibold mb-4">Test Cases</h2>
        {questionData.testCases.map((testCase, index) => (
          <div key={testCase.id} className="mb-6">
            <h3 className="text-lg font-medium mb-2">Test Case {index + 1}</h3>
            <div className="mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Input:
              </label>
              <input
                name="inputContent"
                value={testCase.inputContent}
                onChange={(e) => handleTestCaseChange(index, e)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Output:
              </label>
              <input
                name="outputContent"
                value={testCase.outputContent}
                onChange={(e) => handleTestCaseChange(index, e)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="mb-2">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Points:
              </label>
              <input
                name="points"
                type="number"
                value={testCase.points}
                onChange={(e) => handleTestCaseChange(index, e)}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
          </div>
        ))}
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Save
        </button>
      </form>
    </div>
  );
};

export default AdminEditQuestionAndTestcases;
