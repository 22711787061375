import React, { useEffect, useRef } from "react";
import "../css/ContactInfo.css";
import Navbar from "./Navbar";
import ContactQueryForm from "./ContactQueryForm";
import SubFooter from "./Home/SubFooter";
import FooterHome from "./FooterHome";

const ContactInfo = () => {
  const formRef = useRef(null);  // Create a ref for ContactQueryForm

  useEffect(() => {
    window.scrollTo(0, 0);

    // Check if URL has #form hash
    if (window.location.hash === "#form" && formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div>
    <Navbar/>
    <div className="contact-info-container">
      <div className="contact-info-top">
        <h2 className="contact-info-top-title">
          Contact <span>Us</span>
        </h2>
      </div>
      <div className="contact-info-main">
        <div className="contact-info-box3">
          <img
            src="mail.png"
            alt="Location"
            className="contact-info-box-image"
          />
          <div className="contact-info-box-desc">
            <h2 className="contact-info-box-desc-title">Send Mail</h2>
            <p className="contact-info-box-desc-text">info@technocirrus.com </p>
          </div>
        </div>
        
        <div className="contact-info-box2">
          <img
            src="phone.jpg"
            alt="Location"
            className="contact-info-box-image"
          />
          <div className="contact-info-box-desc">
            <h2 className="contact-info-box-desc-title">Make a Call</h2>
            <p className="contact-info-box-desc-text">+91 9175046101 </p>
          </div>
        </div>
        
        <div className="contact-info-box1">
          <img
            src="location.png"
            alt="Location"
            className="contact-info-box-image"
          />
          <div className="contact-info-box-desc">
            <h2 className="contact-info-box-desc-title">Find us</h2>
            <p className="contact-info-box-desc-text">
              B-908, YashOne,Maan-Hinjewadi Phase-1, Pune
            </p>
            {/* <p className="contact-info-box-desc-text">And </p>
            <p className="contact-info-box-desc-text">
              9 Lomond Road, Hemel Hempstead, England, HP2 6PA
            </p> */}
          </div>
        </div>
       
      </div>
      
    </div>
    <div ref={formRef}>
        <ContactQueryForm />
      </div>
    {/* <PolicyFooter /> */}
    <SubFooter/>
    </div>
  );
};

export default ContactInfo;
