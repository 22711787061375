import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Tabs,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Chip,
} from "@mui/material";
import { EventNote, CheckCircle, Pending } from "@mui/icons-material";

const TestHistory = () => {
  const [value, setValue] = useState(0);
  const [testData, setTestData] = useState({
    upcoming: [],
    current: [],
    past: [],
  });

  const token = localStorage.getItem("token");
  const email = localStorage.getItem("email");
  const BASE_URL = import.meta.env.VITE_BASE_URL;

  useEffect(() => {
    const fetchTestData = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}/fetch-test-history-data`,
          {
            email,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        categorizeTestData(response.data);
      } catch (error) {
        console.error("Error Fetching Test History Data", error);
      }
    };
    fetchTestData();
  }, []);

  const categorizeTestData = (data) => {
    const currentDate = new Date();
    const categorizedData = {
      upcoming: [],
      current: [],
      past: [],
    };

    data.forEach((test) => {
      const testDate = new Date(test.test_date);

      if (testDate > currentDate) {
        categorizedData.upcoming.push({
          id: test.test_id,
          name: test.test_title,
          date: test.test_date,
        });
      } else if (testDate.toDateString() === currentDate.toDateString()) {
        categorizedData.current.push({
          id: test.test_id,
          name: test.test_title,
          date: "Ongoing",
        });
      } else {
        categorizedData.past.push({
          id: test.test_id,
          name: test.test_title,
          date: test.test_date,
        });
      }
    });

    setTestData(categorizedData);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderTestTable = (tests, type) => (
    <TableContainer component={Paper}>
      {tests.length > 0 ? (
        <Table sx={{ minWidth: 650 }} aria-label={`${type} tests table`}>
          <TableHead>
            <TableRow>
              <TableCell>Test Name</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tests.map((test) => (
              <TableRow
                key={test.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {test.name}
                </TableCell>
                <TableCell align="right">{test.date}</TableCell>
                <TableCell align="right">
                  <Chip
                    label={
                      type === "upcoming"
                        ? "Upcoming"
                        : type === "current"
                        ? "Ongoing"
                        : "Completed"
                    }
                    color={
                      type === "upcoming"
                        ? "primary"
                        : type === "current"
                        ? "secondary"
                        : "success"
                    }
                    icon={
                      type === "upcoming" ? (
                        <Pending />
                      ) : type === "current" ? (
                        <EventNote />
                      ) : (
                        <CheckCircle />
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <Typography variant="h6" align="center" sx={{ my: 3 }}>
          No Data To Display
        </Typography>
      )}
    </TableContainer>
  );

  return (
    <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        sx={{ textAlign: "center", my: 3 }}
      >
        Test History
      </Typography>
      <Tabs value={value} onChange={handleChange} centered>
        <Tab label="Upcoming" />
        <Tab label="Current" />
        <Tab label="Past" />
      </Tabs>
      <Box sx={{ p: 3 }}>
        {value === 0 && renderTestTable(testData.upcoming, "upcoming")}
        {value === 1 && renderTestTable(testData.current, "current")}
        {value === 2 && renderTestTable(testData.past, "past")}
      </Box>
    </Box>
  );
};

export default TestHistory;
