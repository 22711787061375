import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const MCQTestSubmitted = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handlePopState = (event) => {
            event.preventDefault();
            navigate('/');
        };

        window.history.pushState(null, '', window.location.href);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [navigate]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
            <div className="bg-white p-8 rounded-lg shadow-md text-center">
                <h1 className="text-2xl font-bold mb-4">Test Submitted Successfully</h1>
                <p className="text-gray-600">
                    Your responses have been saved successfully. You can now press 'Esc' to exit fullscreen mode and then close this window.
                </p>
            </div>
        </div>
    );
};

export default MCQTestSubmitted;