import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/SignupForm.css";

const SignupForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isSendingOtp, setIsSendingOtp] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false); // To track if OTP is verified
  const navigate = useNavigate();

  // Email validation function
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle Send OTP
  const handleSendOTP = async () => {
    if (!isValidEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    try {
      setIsSendingOtp(true);
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/send-otp`,
        { email }
      );
      toast.success("OTP sent to your email.");
      setIsSendingOtp(false);
    } catch (error) {
      console.error("Error sending OTP:", error);
      toast.error("Failed to send OTP. Please try again.");
      setIsSendingOtp(false);
    }
  };

  // Handle Verify OTP
  const handleVerifyOTP = async () => {
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/verify-otp`,
        { email, otp }
      );
      if (response.data.message === "OTP verified successfully") {
        toast.success("OTP verified successfully.");
        setOtpVerified(true);
        setIsVerified(true);
      } else {
        toast.error("Invalid OTP. Please enter correct OTP.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Failed to verify OTP. Please try again.");
    }
  };

  // Handle Signup
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!isVerified) {
      toast.warning("Please verify the OTP before signing up.");
      return;
    }
  
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
  
    try {
      const response = await axios.post(
        `${import.meta.env.VITE_BASE_URL}/signup`,
        {
          email,
          name,
          password,
          username,
        }
      );
      if (response.data.error === null) {
        setIsVerified(false);
        toast.success("Signup successful! Redirecting to login.");
        
        // Store username in localStorage
        localStorage.setItem('username', username);
  
        navigate("/login");
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      if (errorMessage) {
        if (errorMessage === "this email is already used") {
          toast.error("This email is already used.");
        } else if (errorMessage === "this username is already used") {
          toast.error("This username is already used.");
        } else {
          toast.error("An error occurred: " + errorMessage);
        }
      } else {
        toast.error("An unexpected error occurred.");
      }
      setEmail("");
      setPassword("");
      setName("");
      setUsername("");
    }
  };
  

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <>
    <div className="signup-container">
      <ToastContainer />
      <h1 className="signup-header">Organization Signup</h1>
      <form onSubmit={handleSubmit} className="signup-form">

  {/* Email and OTP Section - Move this part above */}
<div className="input-container">
  <input
    type="email"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
    required
    className="input-field"
    placeholder=""  // This is important
    disabled={isSendingOtp || otpVerified} // Disable email field after OTP is sent or verified
  />
  <label className="floating-label">Email</label>
</div>

<button
  type="button"
  onClick={handleSendOTP}
  className={`send-otp-button button ${otpVerified ? 'disabled-button' : ''}`}  // Change button style if OTP is verified
  disabled={isSendingOtp || otpVerified}  // Disable button when OTP is verified or being sent
>
  {otpVerified ? "OTP sent" : isSendingOtp ? <span className="spinner"></span> : "Send OTP"}
</button>

  
  <div className="input-container">
    <input
      type="text"
      value={otp}
      onChange={(e) => setOtp(e.target.value)}
      required
      className="input-field"
      placeholder=""  // This is important
      disabled={otpVerified}
    />
    <label className="floating-label">OTP</label>
  </div>

  <button
    type="button"
    onClick={handleVerifyOTP}
    className="verify-otp-button button"
   
    disabled={otpVerified}
  >
    {otpVerified ? "OTP Verified" : "Verify OTP"}
  </button>

  {/* Now below is the rest of the signup form */}
  <div className="input-container">
    <input
      type="text"
      value={name}
      onChange={(e) => setName(e.target.value)}
      required
      className="input-field"
      placeholder=""  // This is important
    />
    <label className="floating-label">Organization Name</label>
  </div>

  <div className="input-container">
    <input
      type="text"
      value={username}
      onChange={(e) => setUsername(e.target.value)}
      required
      className="input-field"
      placeholder=""  // This is important
    />
    <label className="floating-label">Username</label>
  </div>

  <div className="input-container">
    <input
      type="password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      minLength={6}
      required
      className="input-field"
      placeholder=""  // This is important
    />
    <label className="floating-label">Password</label>
  </div>

  <div className="input-container">
    <input
      type="password"
      value={confirmPassword}
      onChange={(e) => setConfirmPassword(e.target.value)}
      minLength={6}
      required
      className="input-field"
      placeholder=""  // This is important
    />
    <label className="floating-label">Confirm Password</label>
  </div>

  <button type="submit" className="signup-button button">
    Sign Up
  </button>
</form>

      <p className="login-prompt">
        Already have an account?{" "}
        <span onClick={handleLoginClick} className="login-link">
          Log In
        </span>
      </p>
    </div>
    </>
  );
};

export default SignupForm;
