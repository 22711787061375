import React from 'react';
import "../css/FAQ.css"; // Import the CSS file
import Navbar from './Navbar';
import UniqueFooter from './FooterHome';
const faqData = [
  // ... existing FAQ data from previous response ...
  {
    question: 'What is CirrusCypher?',
    answer: (
      <p>
        CirrusCypher is a comprehensive online platform designed to streamline the process of conducting and administering code exams. It caters to three distinct user groups: organizations, participants, and administrators, providing a secure and efficient environment for code assessment.
      </p>
    ),
  },
  {
    question: 'Who can use CirrusCypher?',
    answer: (
      <p>
        CirrusCypher is ideal for organizations seeking to conduct effective code exams for various purposes, such as recruiting, skill assessment, and training evaluation. It's also beneficial for participants who can take secure and reliable coding exams in a familiar online environment.
      </p>
    ),
  },
  {
    question: 'How do I get started with CirrusCypher?',
    answer: (
      <ul>
        <li>
          **For Organizations:** Visit the CirrusCypher website and navigate to the signup page. Create an account by providing your organization's details. After verifying your email address, you'll be redirected to your user dashboard, where you can manage tests, participants, and subscriptions.
        </li>
        <li>
          **For Participants:** You'll receive an email invitation from an organization containing a test link and password. Click the link, enter your email ID and the received password, and proceed to review the test instructions before starting the exam.
        </li>
      </ul>
    ),
  },
  {
    question: 'What are the benefits of using CirrusCypher?',
    answer: (
      <p>
        CirrusCypher offers a multitude of benefits for both organizations and participants:
      </p>
    ),
    subAnswers: [
      {
        title: 'For Organizations:',
        content: (
          <ul>
            <li>Streamline code assessment processes.</li>
            <li>Save time and resources by managing exams efficiently.</li>
            <li>Gain valuable insights into candidate skills through detailed reports and analytics.</li>
            <li>Choose from a wide range of features, including question management, participant control, and test customization.</li>
          </ul>
        ),
      },
      {
        title: 'For Participants:',
        content: (
          <ul>
            <li>Take secure and reliable coding exams in a familiar online environment.</li>
            <li>Benefit from a user-friendly interface for code editing, language selection, and test execution.</li>
            <li>Receive clear and concise exam instructions for a smooth testing experience.</li>
          </ul>
        ),
      },
    ],
  },
  {
    question: 'What are the pricing options for CirrusCypher?',
    answer: (
      <p>
        CirrusCypher offers a range of affordable subscription plans to cater to the needs of organizations of all sizes. You can find detailed pricing information on our website or by contacting our sales team. We're committed to providing a cost-effective solution that aligns with your organization's budget.
      </p>
    ),
  },
  {
    question: 'Does CirrusCypher offer any security guarantees?',
    answer: (
      <p>
        Security is a top priority at CirrusCypher. We employ a robust security infrastructure to safeguard your data and protect the integrity of your exams. Here are some key security measures we implement:
      </p>
    ),
    subAnswers: [
      {
        title: 'Secure Data Storage:',
        content: <p>We utilize secure data centers to store all user information and test data.</p>,
      },
      {
        title: 'HTTPS Encryption:',
        content: (
          <p>
            All communication between your browser and our platform is encrypted using HTTPS to prevent unauthorized access to sensitive information.
          </p>
        ),
      },
      {
        title: 'Authentication and Authorization:',
        content: (
          <p>
            Strict authentication and authorization protocols are in place to ensure only authorized users can access and manage exams.
          </p>
        ),
      },
    ],
},
{
  question: 'What resources are available to help me learn more about CirrusCypher?',
  answer: (
    <p>
      We provide a variety of resources to help you get the most out of CirrusCypher:
    </p>
  ),
  subAnswers: [
    {
      title: 'Comprehensive Documentation:',
      content: (
        <p>
          Our website features detailed documentation covering all aspects of the platform, including user guides, tutorials, and FAQs.
        </p>
      ),
    },
    {
      title: 'Knowledge Base:',
      content: (
        <p>
          Our knowledge base offers a wealth of troubleshooting tips, best practices, and frequently asked questions.
        </p>
      ),
    },
    {
      title: 'Customer Support:',
      content: (
        <p>
          Our friendly customer support team is always happy to answer your questions and assist you with any challenges you encounter. You can reach us via email or live chat.
        </p>
      ),
    },
  ],
},
{
  question: 'Where can I find more information about CirrusCypher?',
  answer: (
    <p>
      For further information and inquiries, please visit our website at https://CirrusCypher.com . You can also contact our sales team at "Contact us" page in navbar or reach out through our "Submit enquiry form" on the website.
    </p>
  ),
},
];

const FAQ = () => {
  const [activeQuestion, setActiveQuestion] = React.useState(null);

  const handleClick = (index) => {
    setActiveQuestion(activeQuestion === index ? null : index);
  };

  return (
    <div>
    <Navbar/>
    <>
    <div className="faq-container">
     <h1 class="faq-title">Frequently Asked Questions</h1>
      <ul className="faq-list">
        {faqData.map((item, index) => (
          <li key={index}>
            <button onClick={() => handleClick(index)} aria-expanded={activeQuestion === index}>
              {item.question}
              {activeQuestion === index && <span className="icon">&#8595;</span>}
              {activeQuestion !== index && <span className="icon">&#8593;</span>}
            </button>
            {activeQuestion === index && (
              <div className="faq-answer">
                {item.answer}
                {item.subAnswers && (
                  <ul className="sub-answer-list">
                    {item.subAnswers.map((subAnswer) => (
                      <li key={subAnswer.title}>
                        <h4>{subAnswer.title}</h4>
                        <p className="faq-answer">{subAnswer.content}</p>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
    </>
    <UniqueFooter/>
    </div>
  );
};

export default FAQ;