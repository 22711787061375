import React from 'react';
import { FaPython, FaNodeJs, FaJava, FaPhp, FaRProject, FaRust, FaSwift } from 'react-icons/fa';
import { SiC, SiCplusplus, SiCsharp, SiClojure, SiElixir, SiFortran, SiHaskell, SiKotlin, SiOctave, SiPerl, SiScala, SiTypescript, SiVisualstudio } from 'react-icons/si';
import '../../css/Languages.css';

const Languages = () => {
  const skills = [
    { icon: <SiC />, name: 'C' },
    { icon: <SiCplusplus />, name: 'C++' },
    { icon: <SiCsharp />, name: 'C#' },
    { icon: <FaPython />, name: 'Python' },
    { icon: <FaNodeJs />, name: 'Node.js' },
    { icon: <SiClojure />, name: 'Clojure' },
    // { icon: <SiCobol />, name: 'Cobol' },
    { icon: <SiElixir />, name: 'Elixir' },
    { icon: <SiFortran />, name: 'Fortran' },
    // { icon: <SiGroovy />, name: 'Groovy' },
    { icon: <SiHaskell />, name: 'Haskell' },
    { icon: <FaJava />, name: 'Java' },
    { icon: <SiKotlin />, name: 'Kotlin' },
    // { icon: <SiLua />, name: 'Lua' },
    // { icon: <SiObjectivec />, name: 'Objective-C' },
    { icon: <SiOctave />, name: 'Octave' },
    // { icon: <SiPascal />, name: 'Pascal' },
    { icon: <SiPerl />, name: 'Perl' },
    { icon: <FaPhp />, name: 'PHP' },
    // { icon: <SiProlog />, name: 'Prolog' },
    { icon: <FaRProject />, name: 'R' },
    // { icon: <FaRuby />, name: 'Ruby' },
    { icon: <FaRust />, name: 'Rust' },
    { icon: <SiScala />, name: 'Scala' },
    // { icon: <SiSql />, name: 'SQL' },
    { icon: <FaSwift />, name: 'Swift' },
    { icon: <SiTypescript />, name: 'TypeScript' },
    { icon: <SiVisualstudio />, name: '.NET' }
  ];

  return (
    <div className="languages-container">
      <h1 className="languages-title">Programming Languages & Technologies</h1>
      <p className="languages-description">Our world-class IDE allows candidates to easily solve challenges directly online.</p>
      <div className="languages-grid">
        {skills.map((skill, index) => (
          <div key={index} className="language-item">
            {skill.icon}
            <span>{skill.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Languages;
