import React, { useState, useEffect,useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../../css/GuideDetail.css';
import { ArrowUp } from 'lucide-react';


// Expanded guide data with detailed chapters and long descriptions
const guidesData = [
    {
    id: 1,
    title: 'Mastering React Hooks',
    intro: 'React Hooks have revolutionized how we build React components. This guide covers everything from the basics to advanced usage.',
    heroImage: '/Guides/react-hook.png',
    chapters: [
      {
        title: 'Introduction to React Hooks',
        content: `
          React Hooks were introduced in React 16.8, marking a significant shift in how developers create and manage React applications. Before hooks, developers relied heavily on class components for managing state and lifecycle methods, which often led to complex and less readable code.
    
          Hooks allow functional components to manage state and side effects, streamlining the development process and enhancing code clarity. This chapter will cover the foundational concepts behind hooks and why they are essential in modern React development.
    
          Key Benefits of React Hooks:
          - Simplicity: Hooks simplify the component model by enabling the use of state and lifecycle features in function components, making them easier to read and maintain.
          - Enhanced Reusability: Hooks promote the creation of reusable logic, allowing developers to encapsulate stateful behavior in custom hooks and share it across components.
          - Improved Composition: Hooks enable better separation of concerns, allowing developers to compose behavior in a more intuitive manner compared to class components.
    
          Understanding hooks is crucial for any React developer, as they provide powerful tools for building functional and dynamic applications.
        `,
      },
      {
        title: 'useState and useEffect',
        content: `
          useState:
          The useState hook is the most commonly used hook in React, allowing developers to add state to functional components. It returns an array containing the current state value and a function to update that state.
    
          Detailed Explanation:
          - The useState function takes an initial state value and returns an array where the first element is the current state, and the second element is a function to update that state.
          - State can be any data type, including primitive types, objects, or arrays. This flexibility allows developers to manage complex state structures easily.
    
          Example of useState:
          import React, { useState } from 'react';
    
          function Counter() {
            const [count, setCount] = useState(0);
    
            return (
              <div>
                <p>You clicked {count} times</p>
                <button onClick={() => setCount(count + 1)}>Click me</button>
              </div>
            );
          }
    
          In this example, we initialize the count to 0, and each button click updates the count state, triggering a re-render of the component.
    
          useEffect:
          The useEffect hook is essential for managing side effects in functional components, such as data fetching, subscriptions, or directly interacting with the DOM. It runs after the render, allowing you to perform actions based on the updated state or props.
    
          Detailed Explanation:
          - useEffect can run after every render by default, but you can control when it executes by providing a dependency array as the second argument. If the array is empty, the effect will run only once after the initial render, mimicking the behavior of componentDidMount.
          - It’s also important to manage cleanup within useEffect to avoid memory leaks. Returning a cleanup function from the effect callback allows you to handle any necessary cleanup when the component unmounts or before the effect runs again.
    
          Example of useEffect:
          import React, { useState, useEffect } from 'react';
    
          function TitleUpdater() {
            const [count, setCount] = useState(0);
    
            useEffect(() => {
              document.title = Count is {count};
            }, [count]); // Dependency array
    
            return (
              <div>
                <button onClick={() => setCount(count + 1)}>Increment</button>
              </div>
            );
          }
    
          In this example, useEffect updates the document title each time count changes, demonstrating how effects can respond to state changes in real-time.
        `,
      },
      {
        title: 'Building Custom Hooks',
        content: `
          Custom hooks are a powerful feature in React that allows developers to extract reusable logic from components and share it across multiple parts of an application. A custom hook is a JavaScript function that starts with "use" and can call other hooks.
    
          Benefits of Custom Hooks:
          - Code Reusability: By encapsulating stateful logic, custom hooks allow developers to reuse functionality across different components, reducing code duplication.
          - Separation of Concerns: Custom hooks help maintain a clean separation between UI logic and business logic, making components easier to read and manage.
          - Enhanced Composition: They enable developers to compose behavior and manage complex stateful logic in a more intuitive and modular way.
    
          Example of a Custom Hook:
          import { useState, useEffect } from 'react';
    
          function useLocalStorage(key, initialValue) {
            const [storedValue, setStoredValue] = useState(() => {
              const item = window.localStorage.getItem(key);
              return item ? JSON.parse(item) : initialValue;
            });
    
            const setValue = value => {
              setStoredValue(value);
              window.localStorage.setItem(key, JSON.stringify(value));
            };
    
            return [storedValue, setValue];
          }
    
          In this custom hook, useLocalStorage, we manage state that persists in the browser's local storage. The hook initializes state from localStorage if available, and provides a function to update both the state and localStorage.
    
          Using the Custom Hook:
          function App() {
            const [name, setName] = useLocalStorage('name', 'Guest');
    
            return (
              <div>
                
                <p>Hello, {name}!</p>
              </div>
            );
          }
    
          In the App component, we use the useLocalStorage hook to manage the user's name. As the user types into the input field, their name is stored in both the component's state and local storage, ensuring persistence across page reloads.
    
          Overall, custom hooks provide an elegant way to encapsulate and share logic, making your React applications more affordable and scalable.
        `,
      },
    
    
    ],
  },
  {
    "id": 2,
    "title": "Advanced JavaScript Patterns",
    "intro": "JavaScript patterns are essential tools that help developers write better, more maintainable code by addressing common challenges in software design. In this guide, we dive deep into some of the most popular and powerful patterns in JavaScript. These patterns improve code structure, enhance modularity, and promote scalable development practices.",
    "heroImage": "/Guides/js-pattern.png",
    "chapters": [
      {
        "title": "Understanding JavaScript Patterns",
        "content": `
          Patterns are tried-and-true solutions to recurring problems in software development. By using these patterns in JavaScript, you can write cleaner, more maintainable, and scalable code. Patterns make it easier to communicate design intentions, reuse well-understood solutions, and prevent common issues.
  
          In JavaScript, some patterns are particularly useful due to the language's dynamic nature and functional programming capabilities. They can be implemented across various aspects of your application, from structuring modules to handling object creation and manipulation.
  
          **Key Benefits of JavaScript Patterns:**
          - **Improved Code Readability:** Patterns help structure code in a way that is easy to understand by other developers.
          - **Modularity:** They enable you to break down complex applications into manageable and reusable modules.
          - **Testing and Debugging:** Well-structured code through patterns leads to easier testing and debugging, reducing development time.
          - **Enhanced Scalability:** Patterns can be adapted as the size and complexity of the project grow.
          - **Consistency:** They establish a standard coding practice, making it easier to onboard new team members.
  
          Patterns also play a significant role in avoiding anti-patterns, which are ineffective solutions to common problems. Understanding the right pattern to use is essential for effective coding.
        `
      },
      {
        "title": "Module Pattern",
        "content": `
          The module pattern is one of the most commonly used design patterns in JavaScript. It allows you to encapsulate private variables and methods while exposing only specific functions or variables to the outside world. This pattern is especially useful for organizing code into self-contained units, which helps avoid global scope pollution.
  
          By using closures, the module pattern can maintain internal state and provide controlled access to that state. It creates a clear separation between the private (internal) and public (exposed) parts of your code, which improves maintainability and security.
  
          **Key Advantages:**
          - Encapsulation of private data.
          - Prevention of global namespace pollution.
          - Flexible public APIs for interaction with internal methods.
  
          **Example of Module Pattern:**
          \`\`\`javascript
          const CounterModule = (function () {
            let count = 0; // Private variable
  
            return {
              // Public methods
              increment() {
                return ++count;
              },
              decrement() {
                return --count;
              }
            };
          })();
  
          console.log(CounterModule.increment()); // 1
          console.log(CounterModule.decrement()); // 0
          console.log(CounterModule.count); // undefined (count is private)
          \`\`\`
  
          This pattern is perfect for creating modules where you need some level of control over the internal state, and you want to limit access to certain functions or variables.
        `
      },
      {
        "title": "Factory Pattern",
        "content": `
          The factory pattern is a creational design pattern that abstracts the process of creating objects, allowing us to create different types of objects based on input parameters without exposing the instantiation logic. This pattern provides an interface for object creation, but lets the subclasses or other classes decide which class to instantiate.
  
          The factory pattern is particularly useful when the exact type of object to be created isn't known until runtime, or when there are multiple potential object types that can be created. By centralizing the object creation logic, you can easily manage and extend the system without modifying existing code.
  
          **Key Advantages:**
          - Decoupling of object creation from object usage.
          - Increased flexibility in terms of object instantiation.
          - Centralized object management.
  
          **Example of Factory Pattern:**
          \`\`\`javascript
          function CarFactory(type) {
            if (type === 'SUV') {
              return new SUV();
            } else if (type === 'Sedan') {
              return new Sedan();
            } else {
              throw new Error('Unknown car type');
            }
          }
  
          // Example class definitions
          function SUV() {
            this.type = 'SUV';
            this.wheels = 4;
          }
  
          function Sedan() {
            this.type = 'Sedan';
            this.wheels = 4;
          }
  
          const myCar = CarFactory('SUV');
          console.log(myCar.type); // SUV
          console.log(myCar.wheels); // 4
          \`\`\`
  
          This pattern is ideal when dealing with complex object creation logic, as it allows you to centralize and manage object instantiation from one place, making it easier to extend or modify object creation behavior.
        `
      },
      {
        "title": "Observer Pattern",
        "content": `
          The observer pattern is a behavioral design pattern that allows objects to be notified of changes to the state of another object. It's especially useful when you have a one-to-many relationship between objects, where changes to one object need to be propagated to others automatically. This pattern is widely used in event handling systems, such as JavaScript’s DOM event listeners.
  
          The core idea is that an object (the subject) maintains a list of its dependent objects (observers) and notifies them whenever its state changes.
  
          **Key Advantages:**
          - Promotes loose coupling between components.
          - Provides a mechanism to notify multiple objects about changes in an efficient way.
          - Helps build event-driven systems where objects need to react to state changes.
  
          **Example of Observer Pattern:**
          \`\`\`javascript
          function Subject() {
            this.observers = []; // List of observers
  
            return {
              addObserver(observer) {
                this.observers.push(observer);
              },
              removeObserver(observer) {
                this.observers = this.observers.filter(obs => obs !== observer);
              },
              notifyObservers(message) {
                this.observers.forEach(observer => observer.notify(message));
              }
            };
          }
  
          function Observer(name) {
            return {
              notify(message) {
                console.log(\`\${name} received message: \${message}\`);
              }
            };
          }
  
          const subject = new Subject();
          const observer1 = new Observer('Observer 1');
          const observer2 = new Observer('Observer 2');
  
          subject.addObserver(observer1);
          subject.addObserver(observer2);
  
          subject.notifyObservers('Hello Observers!'); // Notifies all observers
          \`\`\`
  
          This pattern is highly beneficial in applications where certain components need to observe the state of others, such as in UI frameworks where different parts of the user interface must update in response to model changes.
        `
      }
    ]
  },
  
  {
    "id": 3,
    "title": "Understanding CSS Grid",
    "intro": "CSS Grid is a powerful and flexible layout system available in CSS that revolutionizes web design by providing a two-dimensional grid-based layout system. With CSS Grid, developers can easily create complex and responsive designs, controlling both rows and columns simultaneously. This guide will walk you through the basics and some advanced features of CSS Grid, helping you master the art of modern layouts.",
    "heroImage": "/Guides/css-grid.png",
    "chapters": [
      {
        "title": "What is CSS Grid?",
        "content": `
          CSS Grid is a CSS layout system that allows developers to create two-dimensional layouts with ease. Unlike Flexbox, which primarily handles one-dimensional layouts (either rows or columns), CSS Grid provides the ability to work with both dimensions simultaneously, making it the go-to choice for complex web designs.
  
          CSS Grid introduces new CSS properties and values that give developers fine-grained control over the positioning and alignment of elements within the grid. You can define specific areas on the grid for each element and span them across multiple rows or columns with ease.
  
          **Key Benefits of CSS Grid:**
          - **Control over both dimensions:** Aligns elements both horizontally and vertically, allowing for better control over the layout.
          - **Simplified complex layouts:** Handles intricate page designs with less code compared to older techniques like float-based layouts.
          - **Responsive design made easy:** The grid adapts seamlessly to different screen sizes.
          - **Improved modularity:** Elements can be easily repositioned without changing the HTML structure.
  
          CSS Grid is now fully supported by all major browsers, making it a practical solution for building modern, responsive websites.
        `
      },
      {
        "title": "Creating a Basic Grid",
        "content": `
          Setting up a basic grid is quite simple. First, apply \`display: grid;\` to the parent container. Then, define the number of columns and rows by using \`grid-template-columns\` and \`grid-template-rows\`. You can assign explicit widths to columns and rows or use flexible units like \`fr\` (fractional units) to distribute space automatically.
  
          **Example of a Basic Grid:**
          \`\`\`css
          .grid-container {
            display: grid;
            grid-template-columns: 1fr 1fr; /* Two columns of equal width */
            grid-template-rows: auto; /* Rows will adjust to content height */
            gap: 10px; /* Space between grid items */
          }
  
          .grid-item {
            background-color: #ddd;
            padding: 20px;
            text-align: center;
          }
          \`\`\`
  
          In this example:
          - The grid has two columns of equal width, and the rows adjust based on the content.
          - The \`gap\` property adds spacing between grid items.
          - The items inside the grid container (with the class \`.grid-item\`) will automatically be placed into the grid cells.
        `
      },
      {
        "title": "Advanced Grid Features",
        "content": `
          Once you understand the basics, you can explore some of the more advanced features of CSS Grid that make it incredibly powerful. These features allow for more sophisticated layouts that would be difficult or impossible to achieve with other layout systems like Flexbox.
  
          **Grid Areas:**
          Grid areas let you define named areas within the grid, which allows for easy placement of elements. This is particularly useful for defining the structure of complex layouts such as websites with headers, sidebars, and footers.
  
          **Example using Grid Areas:**
          \`\`\`css
          .grid-container {
            display: grid;
            grid-template-areas:
              "header header"
              "sidebar main"
              "footer footer";
            grid-gap: 10px;
          }
  
          .header {
            grid-area: header;
            background-color: lightblue;
          }
  
          .sidebar {
            grid-area: sidebar;
            background-color: lightgreen;
          }
  
          .main {
            grid-area: main;
            background-color: lightcoral;
          }
  
          .footer {
            grid-area: footer;
            background-color: lightgray;
          }
          \`\`\`
  
          In this example:
          - We define a grid with named areas for the header, sidebar, main content, and footer.
          - Elements are placed into these areas using the \`grid-area\` property, making the layout easy to manage and adjust.
  
          **Implicit Grids and Auto-Placement:**
          CSS Grid can automatically place items into the grid without explicitly defining where each item should go. This is known as implicit grid placement. When there are more items than explicitly defined grid cells, CSS Grid will create new rows or columns as needed.
  
          **Example of Auto-Placement:**
          \`\`\`css
          .grid-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-auto-rows: minmax(100px, auto);
          }
  
          .grid-item {
            background-color: #ddd;
            padding: 20px;
          }
          \`\`\`
  
          In this example:
          - Three columns are defined, and the rows are generated automatically as needed.
          - The height of each row is at least 100px, but it will grow based on the content size.
  
          **Responsive Layouts:**
          CSS Grid makes it easy to create responsive designs by combining media queries with grid properties. You can redefine grid layouts based on the screen size or device type.
  
          **Responsive Grid Example:**
          \`\`\`css
          .grid-container {
            display: grid;
            grid-template-columns: 1fr;
          }
  
          @media (min-width: 600px) {
            .grid-container {
              grid-template-columns: 1fr 1fr;
            }
          }
  
          @media (min-width: 900px) {
            .grid-container {
              grid-template-columns: 1fr 1fr 1fr;
            }
          }
          \`\`\`
  
          In this example:
          - For small screens (less than 600px), the grid has one column.
          - For medium screens (600px or more), the grid has two columns.
          - For larger screens (900px or more), the grid expands to three columns.
          
          This demonstrates how easy it is to adapt layouts for different screen sizes using CSS Grid.
        `
      }
    ]
  },
  
  {
    "id": 4,
    "title": "Building APIs with Node.js",
    "intro": "APIs are the backbone of modern web applications, enabling communication between the frontend and backend. In this guide, we'll explore how to build robust APIs using Node.js and Express, one of the most popular web frameworks for Node.js. By the end of this guide, you'll have a solid understanding of how to set up a Node.js server, create RESTful routes, and handle requests and responses effectively.",
    "heroImage": "/Guides/rest.png",
    "chapters": [
      {
        "title": "Introduction to APIs and REST",
        "content": `
          An API (Application Programming Interface) is a set of protocols and tools that allows different software components to communicate with each other. In web development, APIs are often used to enable communication between a client (like a web or mobile app) and a server. The server processes requests from the client and sends back a response.
  
          **REST (Representational State Transfer)** is a popular architecture style for designing networked applications. RESTful APIs use HTTP requests to perform CRUD operations on resources. Each resource is typically represented by a URL, and different HTTP methods are used to perform different actions on these resources.
  
          **Key Concepts in REST:**
          - **Stateless Architecture:** Each request from the client to the server must contain all the information the server needs to fulfill that request. The server does not store session information.
          - **HTTP Methods:** RESTful APIs leverage standard HTTP methods:
            - **GET:** Retrieve data from the server.
            - **POST:** Send data to the server to create a new resource.
            - **PUT/PATCH:** Update an existing resource.
            - **DELETE:** Remove a resource.
          - **JSON (JavaScript Object Notation):** The most common format used for transmitting data between the client and server in RESTful APIs. It's lightweight and easy to parse.
  
          REST APIs are designed to be scalable, maintainable, and flexible, making them the go-to architecture for modern web services.
        `
      },
      {
        "title": "Setting up a Node.js API",
        "content": `
          To build a Node.js API, we typically use **Express**, a fast and minimal web framework for Node.js. Express simplifies handling HTTP requests, routing, and middleware.
  
          **Setting up an Express server:**
          1. Install Node.js and Express:
             \`\`\`
             npm init -y  // Initialize a Node.js project
             npm install express  // Install Express
             \`\`\`
          2. Create a basic Express server:
          \`\`\`javascript
          const express = require('express');
          const app = express();
  
          // Basic route
          app.get('/', (req, res) => {
            res.send('Hello World!');
          });
  
          // Start the server
          app.listen(3000, () => {
            console.log('Server is running on port 3000');
          });
          \`\`\`
  
          In this example:
          - We create a basic Express app and define a route (`/`) that responds with "Hello World!" when accessed.
          - The server listens on port 3000, and when it's running, it logs a message to the console.
  
          From here, you can start building more complex APIs by adding routes, handling different HTTP methods, and integrating databases.
        `
      },
      {
        "title": "Creating RESTful Routes",
        "content": `
          In RESTful APIs, routes are created to map specific URLs (endpoints) to various actions. These actions correspond to CRUD (Create, Read, Update, Delete) operations. Express makes it easy to define routes for each operation.
  
          **RESTful Route Structure:**
          - **POST /users:** Create a new user.
          - **GET /users/:id:** Retrieve user details by ID.
          - **PUT /users/:id:** Update user information by ID.
          - **DELETE /users/:id:** Delete a user by ID.
  
          Here's an example of how to implement these routes in Express:
          \`\`\`javascript
          const express = require('express');
          const app = express();
  
          // Middleware to parse JSON request bodies
          app.use(express.json());
  
          // POST - Create a new user
          app.post('/users', (req, res) => {
            const newUser = req.body; // Assume the user data is sent in the request body
            // Logic to save user in the database
            res.status(201).json({ message: 'User created', user: newUser });
          });
  
          // GET - Retrieve a user by ID
          app.get('/users/:id', (req, res) => {
            const userId = req.params.id;
            // Logic to retrieve the user from the database
            res.json({ message: 'User retrieved', userId });
          });
  
          // PUT - Update a user by ID
          app.put('/users/:id', (req, res) => {
            const userId = req.params.id;
            const updatedUser = req.body; // Assume updated data is sent in the request body
            // Logic to update the user in the database
            res.json({ message: 'User updated', userId, updatedUser });
          });
  
          // DELETE - Remove a user by ID
          app.delete('/users/:id', (req, res) => {
            const userId = req.params.id;
            // Logic to delete the user from the database
            res.status(204).send(); // No content response
          });
  
          // Start the server
          app.listen(3000, () => {
            console.log('Server running on port 3000');
          });
          \`\`\`
  
          In this example:
          - **POST /users:** We use the req.body to access the new user's data and create a new user.
          - **GET /users/:id:** The :id in the route is a dynamic parameter that represents the user's ID. We can retrieve this value using req.params.id.
          - **PUT /users/:id:** This route updates an existing user with the data provided in req.body.
          - **DELETE /users/:id:** Deletes a user by ID, sending a 204 (No Content) response when successful.
  
          These routes follow REST conventions, making it easy for other developers or services to understand and interact with the API.
        `
      },
      {
        "title": "Connecting to a Database",
        "content": `
          To store and manage data in your API, you'll likely want to connect to a database. In Node.js, popular databases include MongoDB (NoSQL) and PostgreSQL/MySQL (SQL). You can use various libraries to interact with these databases, such as Mongoose for MongoDB or Sequelize for SQL databases.
  
          **Example: Connecting to MongoDB using Mongoose:**
          1. Install Mongoose:
             \`\`\`
             npm install mongoose
             \`\`\`
          2. Connect to MongoDB in your Node.js app:
          \`\`\`javascript
          const mongoose = require('mongoose');
  
          // Connect to MongoDB
          mongoose.connect('mongodb://localhost:27017/mydatabase', {
            useNewUrlParser: true,
            useUnifiedTopology: true,
          })
          .then(() => {
            console.log('Connected to MongoDB');
          })
          .catch((error) => {
            console.error('Error connecting to MongoDB:', error);
          });
          \`\`\`
  
          Once connected, you can define models and perform CRUD operations on your database using Mongoose.
  
          **Example of a Mongoose model:**
          \`\`\`javascript
          const UserSchema = new mongoose.Schema({
            name: String,
            email: String,
            password: String,
          });
  
          const User = mongoose.model('User', UserSchema);
  
          // Creating a new user
          const newUser = new User({
            name: 'John Doe',
            email: 'john@example.com',
            password: 'securepassword',
          });
  
          newUser.save().then(() => console.log('User created'));
          \`\`\`
  
          By integrating a database, you can persist user data and other resources, making your API more dynamic and capable of handling real-world applications.
        `
    }
]
}
];


const GuideDetail = () => {
    const { id } = useParams();
    const guide = guidesData.find((g) => g.id === parseInt(id));
    const [activeChapter, setActiveChapter] = useState(0);
    const [showBackToTop, setShowBackToTop] = useState(false);
  
    const chapterRefs = useRef([]);
  
    const scrollToChapter = (index) => {
      setActiveChapter(index);
      chapterRefs.current[index].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    };
  
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300);
    };
  
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  
    useEffect(() => {
      const observerOptions = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };
  
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = chapterRefs.current.indexOf(entry.target);
            if (index !== -1) {
              setActiveChapter(index);
            }
          }
        });
      }, observerOptions);
  
      chapterRefs.current.forEach((section) => {
        if (section) {
          observer.observe(section);
        }
      });
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        chapterRefs.current.forEach((section) => {
          if (section) {
            observer.unobserve(section);
          }
        });
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    return (
        <div className="gd-detail-page">
        <div className="gd-hero-section">
          <div className="gd-hero-content">
            <h1>{guide.title}</h1>
            <p>{guide.intro}</p>
            <img src={guide.heroImage} alt={guide.title} className="gd-hero-image" />
          </div>
        </div>
       
  
  
        <div className="gd-main-content">
          <aside className="gd-sidebar">
            <h2>Chapters</h2>
            <ul>
              {guide.chapters.map((chapter, index) => (
                <li
                  key={index}
                  className={index === activeChapter ? 'active' : ''}
                  onClick={() => scrollToChapter(index)}
                >
                  {chapter.title}
                  <div className="gd-progress-bar" style={{ width: `${((index + 1) / guide.chapters.length) * 100}%` }} />
                </li>
              ))}
            </ul>
          </aside>
  
          <div className="gd-content">
            {guide.chapters.map((chapter, index) => (
              <div
                key={index}
                ref={(el) => (chapterRefs.current[index] = el)}
                className="gd-content-section"
              >
                <h2>{`${String(index + 1).padStart(2, '0')}. ${chapter.title}`}</h2>
                <div dangerouslySetInnerHTML={{ __html: chapter.content }} />
              </div>
            ))}
          </div>
        </div>
  
        {showBackToTop && (
          <button className="gd-back-to-top" onClick={scrollToTop}>
            <ArrowUp size={24} />
          </button>
        )}
      </div>
    );
  };
  
  export default GuideDetail;