// src/components/Preparation/SolutionModal.js

import React from 'react';
import PropTypes from 'prop-types';
import { X } from 'lucide-react';

export default function SolutionModal({ isOpen, onClose, question }) {
  if (!isOpen || !question) return null;

  // Determine the solution content based on available fields
  const getSolutionContent = () => {
    // Check for solution for DSA/System Design/Machine Coding type questions
    if (question.solution) {
      return (
        <div>
          <h3 className="text-xl font-semibold mb-2">Solution:</h3>
          <p className="text-gray-700 whitespace-pre-wrap">{question.solution}</p>
        </div>
      );
    }

    // Check for answer for Core CS/Aptitude questions
    if (question.answer) {
      return (
        <div>
          <h3 className="text-xl font-semibold mb-2">Answer:</h3>
          <p className="text-gray-700 whitespace-pre-wrap">{question.answer}</p>
        </div>
      );
    }

    // Check for tips for HR questions
    if (question.tips) {
      return (
        <div>
          <h3 className="text-xl font-semibold mb-2">Tips:</h3>
          <p className="text-gray-700 whitespace-pre-wrap">{question.tips}</p>
        </div>
      );
    }

    // Default message if no solution, answer, or tips are found
    return <p className="text-gray-600">No solution available for this question.</p>;
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg w-11/12 md:w-3/4 lg:w-1/2 p-6 relative">
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          aria-label="Close Solution Modal"
        >
          <X className="h-6 w-6" />
        </button>

        {/* Modal Title */}
        <h2 className="text-2xl font-semibold mb-4">{question.title} - Solution</h2>

        {/* Solution Content */}
        <div className="space-y-4">{getSolutionContent()}</div>
      </div>
    </div>
  );
}

// PropTypes for type checking
SolutionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  question: PropTypes.shape({
    title: PropTypes.string.isRequired,
    solution: PropTypes.string,
    answer: PropTypes.string,
    tips: PropTypes.string,  // Added tips for HR questions
  }),
};
